import React from "react";

export const NotificationsHeader = (props) => {
  return (
    <h1 id="notification-title" count={props.count}>
      {props.count === 0 ? (
        <React.Fragment>Notifications</React.Fragment>
      ) : (
        <React.Fragment>New Notifications ({props.count}) </React.Fragment>
      )}
    </h1>
  );
};

export const NotificationsHeaderMain = (props) => {
  return (
    <h1 id="notifications-title" count={props.count}>
      {props.count === 0 ? (
        <React.Fragment>Notifications</React.Fragment>
      ) : (
        <React.Fragment>New Notifications ({props.count}) </React.Fragment>
      )}
    </h1>
  );
};
