import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../../Redux/actions/securityActions";
import classnames from "classnames";
import logo from "../../assets/dpa_logo.png";
import demoLogo from "../../assets/mTune_logo.png";
import ErrorModal from "../../components/Modals/ErrorModal";
import { isDemo } from "../../demo_utils";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",

      valUsername: "",
      valPassword: "",
      show: false,
      loading: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate = async prevProps => {
    // Typical usage (don't forget to compare props):
    if (this.props !== prevProps) {
      if (this.props.errors !== null) {
        if (this.props.errors.code === "401 UNAUTHORIZED") {
          await this.setState({ show: true });
        }
      }
      this.setState({ loading: false });
    }
  };

  errorPopUp = () => {
    if (this.props.errors !== null) {
    return ( 
        <ErrorModal
          onHide={this.handleClose}
          handleClose={this.handleClose}
          show={this.state.show}
          status={this.props.errors.code}
          message={this.props.errors.message}
        /> 
    )}
  }

  // Check input fields for errors
  validate = () => {
    let isError = false;

    if (this.state.username.length < 3 || this.state.username.length > 100) {
      isError = true;
      this.setState({
        valUsername: "Username must be between 3 and 100 characters"
      });
    }

    if (this.state.password.length < 3 || this.state.password.length > 100) {
      isError = true;
      this.setState({
        valPassword: "Password  must be between 3 and 100 characters"
      });
    }

    return isError;
  };

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true,
      valUsername: "",
      valPassword: ""
    });

    const err = this.validate();

    if (!err) {
      const LoginRequest = {
        username: this.state.username,
        password: this.state.password
      };

      this.props.login(LoginRequest);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  componentWillUnmount() {
    this.setState({ show: false });
  }

  render() {
    const st = this.state;
    let form = (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <label className="login-label" htmlFor="username">
            Username
          </label>
          <input
            type="text"
            className={classnames("form-control form-control-lg", {
              "is-invalid": st.valUsername
            })}
            id="login-username"
            placeholder="Username"
            name="username"
            value={this.state.username}
            onChange={this.onChange}
          />
          {st.valUsername && (
            <div className="invalid-feedback">{st.valUsername}</div>
          )}
        </div>
        <div className="form-group">
          <label className="login-label" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className={classnames("form-control form-control-lg", {
              "is-invalid": st.valPassword
            })}
            id="login-password"
            placeholder="Password"
            name="password"
            value={this.state.password}
            onChange={this.onChange}
          />
          {st.valPassword && (
            <div className="invalid-feedback">{st.valPassword}</div>
          )}
        </div>
        <input
          type="submit"
          className="btn btn-info btn-block mt-4"
          id="login-button"
          value="Login"
        />
        <div>
          {this.errorPopUp()}
        </div>
      </form>
    );

    return (
      <div className="container-fluid " id="login-container">
        <div className="row" id="login-row">
          <div className="col-6" id="login-logo-col">
            <div className="row h-100 justify-content-center">
              <div className="col-7 my-auto">
                <img
                  className="img-fluid"
                  src={!isDemo() ? logo : demoLogo}
                  id="login-logo"
                  alt="dpa_logo"
                />
              </div>
            </div>
          </div>
          <div className="col-6 " id="login-form">
            <div className="row justify-content-center h-100 ">
              <div className="col-7 my-auto">
                <span className="text-center " id="login-title">
                  Login to your account
                </span>
                <br />
                <br />
                {form}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  security: state.security,
  errors: state.security.errors
});

export default connect(mapStateToProps, { login })(Login);
