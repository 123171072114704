import React, { Component } from "react";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import moment from "moment";
import { getCompositeAnalytics } from "../../../Redux/actions/Analytics/analyticsActions";
import Spinner from "../../../components/Spinner/Spinner";

class CompositeAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      years: [],
      valueYear: "2019",
      selectedLegalName: [],
      multiClientNameArrayOptions: [],
      legalNameChoiseArray: [
        { name: "Secondary Data", value: "" },
        { name: "Εκτιμώμενος χρόνος παραγωγής", value: "estHours" },
        { name: "Ποσότητα ετικετών", value: "qtyProduced" },
        { name: "Ποσοστό διαφοράς (B)-(A)", value: "dmPercentage" },
        { name: "Πραγματικά τετρ. μέτρα εκτύπωσης", value: "m2" },
        { name: "Συνολική Αξία υλικών ", value: "materialCost" },
        { name: "Τζίρος", value: "turnover" },
        { name: "%ΜΠΚ", value: "mPercentage" },
        { name: "Φύρα", value: "loss" },
      ],
      secondaryValue: "",
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "0",
            backgroundColor: [
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
            ],
            hoverBackgroundColor: [
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
            ],
          },
          {
            data: [],
            label: "1",
            backgroundColor: [
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
            ],
            hoverBackgroundColor: [
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
            ],
          },
          {
            data: [],
            label: "2",
            backgroundColor: [
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
            ],
            hoverBackgroundColor: [
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
            ],
          },
        ],
      },
    };
  }

  componentDidMount = () => {
    this.chooseLegalName();
    this.yearsArray();
  };
  loadChart = (secondaryValue) => {
    let incomingLabels = [];

    var stateCopy = Object.assign({}, this.state);
    var stateCopyTwo = Object.assign({}, this.state);
    var stateCopyThree = Object.assign({}, this.state);

    let January;
    let February;
    let March;
    let April;
    let May;
    let June;
    let July;
    let August;
    let September;
    let Octomber;
    let November;
    let December;
    let incomingJanuary = [];
    let incomingFebruary = [];
    let incomingMarch = [];
    let incomingApril = [];
    let incomingMay = [];
    let incomingJune = [];
    let incomingJuly = [];
    let incomingAugust = [];
    let incomingSeptember = [];
    let incomingOctomber = [];
    let incomingNovember = [];
    let incomingDecember = [];
    let incomingData = [];
    let countJanuary = 0;
    let countFebruary = 0;
    let countMarch = 0;
    let countApril = 0;
    let countMay = 0;
    let countJune = 0;
    let countJuly = 0;
    let countAugust = 0;
    let countSeptember = 0;
    let countOctomber = 0;
    let countNovember = 0;
    let countDecember = 0;
    let January1;
    let February1;
    let March1;
    let April1;
    let May1;
    let June1;
    let July1;
    let August1;
    let September1;
    let Octomber1;
    let November1;
    let December1;
    let incomingJanuary1 = [];
    let incomingFebruary1 = [];
    let incomingMarch1 = [];
    let incomingApril1 = [];
    let incomingMay1 = [];
    let incomingJune1 = [];
    let incomingJuly1 = [];
    let incomingAugust1 = [];
    let incomingSeptember1 = [];
    let incomingOctomber1 = [];
    let incomingNovember1 = [];
    let incomingDecember1 = [];
    let incomingData1 = [];
    let countJanuary1 = 0;
    let countFebruary1 = 0;
    let countMarch1 = 0;
    let countApril1 = 0;
    let countMay1 = 0;
    let countJune1 = 0;
    let countJuly1 = 0;
    let countAugust1 = 0;
    let countSeptember1 = 0;
    let countOctomber1 = 0;
    let countNovember1 = 0;
    let countDecember1 = 0;
    let January2;
    let February2;
    let March2;
    let April2;
    let May2;
    let June2;
    let July2;
    let August2;
    let September2;
    let Octomber2;
    let November2;
    let December2;
    let incomingJanuary2 = [];
    let incomingFebruary2 = [];
    let incomingMarch2 = [];
    let incomingApril2 = [];
    let incomingMay2 = [];
    let incomingJune2 = [];
    let incomingJuly2 = [];
    let incomingAugust2 = [];
    let incomingSeptember2 = [];
    let incomingOctomber2 = [];
    let incomingNovember2 = [];
    let incomingDecember2 = [];
    let incomingData2 = [];
    let countJanuary2 = 0;
    let countFebruary2 = 0;
    let countMarch2 = 0;
    let countApril2 = 0;
    let countMay2 = 0;
    let countJune2 = 0;
    let countJuly2 = 0;
    let countAugust2 = 0;
    let countSeptember2 = 0;
    let countOctomber2 = 0;
    let countNovember2 = 0;
    let countDecember2 = 0;
    let monthLabels = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "Octomber",
      "November",
      "December",
    ];
    if (
      this.state.selectedLegalName.length !== 0 &&
      this.state.selectedLegalName !== null
    ) {
      try {
        switch (secondaryValue) {
          case "estHours":
            switch (this.state.selectedLegalName.length) {
              case 1:
                this.props.compositeAnalytics.forEach((element) => {
                  if (
                    moment(element.prodDate).format("MM") === "01" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJanuary.push(element.estHours);
                    countJanuary++;
                  } else if (
                    moment(element.prodDate).format("MM") === "02" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingFebruary.push(element.estHours);
                    countFebruary++;
                  } else if (
                    moment(element.prodDate).format("MM") === "03" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMarch.push(element.estHours);
                    countMarch++;
                  } else if (
                    moment(element.prodDate).format("MM") === "04" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingApril.push(element.estHours);
                    countApril++;
                  } else if (
                    moment(element.prodDate).format("MM") === "05" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMay.push(element.estHours);
                    countMay++;
                  } else if (
                    moment(element.prodDate).format("MM") === "06" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJune.push(element.estHours);
                    countJune++;
                  } else if (
                    moment(element.prodDate).format("MM") === "07" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJuly.push(element.estHours);
                    countJuly++;
                  } else if (
                    moment(element.prodDate).format("MM") === "08" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingAugust.push(element.estHours);
                    countAugust++;
                  } else if (
                    moment(element.prodDate).format("MM") === "09" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingSeptember.push(element.estHours);
                    countSeptember++;
                  } else if (
                    moment(element.prodDate).format("MM") === "10" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingOctomber.push(element.estHours);
                    countOctomber++;
                  } else if (
                    moment(element.prodDate).format("MM") === "11" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingNovember.push(element.estHours);
                    countNovember++;
                  } else if (
                    moment(element.prodDate).format("MM") === "12" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingDecember.push(element.estHours);
                    countDecember++;
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  (January / countJanuary).toFixed(2),
                  (February / countFebruary).toFixed(2),
                  (March / countMarch).toFixed(2),
                  (April / countApril).toFixed(2),
                  (May / countMay).toFixed(2),
                  (June / countJune).toFixed(2),
                  (July / countJuly).toFixed(2),
                  (August / countAugust).toFixed(2),
                  (September / countSeptember).toFixed(2),
                  (Octomber / countOctomber).toFixed(2),
                  (November / countNovember).toFixed(2),
                  (December / countDecember).toFixed(2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 2:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.estHours);
                      countJanuary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.estHours);
                      countJanuary1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.estHours);
                      countFebruary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.estHours);
                      countFebruary1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.estHours);
                      countMarch++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.estHours);
                      countMarch1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.estHours);
                      countApril++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.estHours);
                      countApril1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.estHours);
                      countMay++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.estHours);
                      countMay1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.estHours);
                      countJune++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.estHours);
                      countJune1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.estHours);
                      countJuly++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.estHours);
                      countJuly1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.estHours);
                      countAugust++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.estHours);
                      countAugust1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.estHours);
                      countSeptember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.estHours);
                      countSeptember1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.estHours);
                      countOctomber++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.estHours);
                      countOctomber1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.estHours);
                      countNovember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.estHours);
                      countNovember1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.estHours);
                      countDecember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.estHours);
                      countDecember1++;
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  (January / countJanuary).toFixed(2),
                  (February / countFebruary).toFixed(2),
                  (March / countMarch).toFixed(2),
                  (April / countApril).toFixed(2),
                  (May / countMay).toFixed(2),
                  (June / countJune).toFixed(2),
                  (July / countJuly).toFixed(2),
                  (August / countAugust).toFixed(2),
                  (September / countSeptember).toFixed(2),
                  (Octomber / countOctomber).toFixed(2),
                  (November / countNovember).toFixed(2),
                  (December / countDecember).toFixed(2)
                );
                incomingData1.push(
                  (January1 / countJanuary1).toFixed(2),
                  (February1 / countFebruary1).toFixed(2),
                  (March1 / countMarch1).toFixed(2),
                  (April1 / countApril1).toFixed(2),
                  (May1 / countMay1).toFixed(2),
                  (June1 / countJune1).toFixed(2),
                  (July1 / countJuly1).toFixed(2),
                  (August1 / countAugust1).toFixed(2),
                  (September1 / countSeptember1).toFixed(2),
                  (Octomber1 / countOctomber1).toFixed(2),
                  (November1 / countNovember1).toFixed(2),
                  (December1 / countDecember1).toFixed(2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 3:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.estHours);
                      countJanuary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.estHours);
                      countJanuary1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJanuary2.push(element.estHours);
                      countJanuary2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.estHours);
                      countFebruary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.estHours);
                      countFebruary1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingFebruary2.push(element.estHours);
                      countFebruary2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.estHours);
                      countMarch++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.estHours);
                      countMarch1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMarch2.push(element.estHours);
                      countMarch2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.estHours);
                      countApril++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.estHours);
                      countApril1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingApril2.push(element.estHours);
                      countApril2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.estHours);
                      countMay++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.estHours);
                      countMay1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMay2.push(element.estHours);
                      countMay2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.estHours);
                      countJune++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.estHours);
                      countJune1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJune2.push(element.estHours);
                      countJune2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.estHours);
                      countJuly++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.estHours);
                      countJuly1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJuly2.push(element.estHours);
                      countJuly2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.estHours);
                      countAugust++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.estHours);
                      countAugust1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingAugust2.push(element.estHours);
                      countAugust2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.estHours);
                      countSeptember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.estHours);
                      countSeptember1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingSeptember2.push(element.estHours);
                      countSeptember2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.estHours);
                      countOctomber++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.estHours);
                      countOctomber1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingOctomber2.push(element.estHours);
                      countOctomber2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.estHours);
                      countNovember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.estHours);
                      countNovember1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingNovember2.push(element.estHours);
                      countNovember2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.estHours);
                      countDecember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.estHours);
                      countDecember1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingDecember2.push(element.estHours);
                      countDecember2++;
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                if (incomingJanuary2.length > 0) {
                  incomingJanuary2.reduce(function (accumulator, currentValue) {
                    return (January2 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary2.length > 0) {
                  incomingFebruary2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February2 = accumulator + currentValue);
                  });
                }
                if (incomingMarch2.length > 0) {
                  incomingMarch2.reduce(function (accumulator, currentValue) {
                    return (March2 = accumulator + currentValue);
                  });
                }
                if (incomingApril2.length > 0) {
                  incomingApril2.reduce(function (accumulator, currentValue) {
                    return (April2 = accumulator + currentValue);
                  });
                }
                if (incomingMay2.length > 0) {
                  incomingMay2.reduce(function (accumulator, currentValue) {
                    return (May2 = accumulator + currentValue);
                  });
                }
                if (incomingJune2.length > 0) {
                  incomingJune2.reduce(function (accumulator, currentValue) {
                    return (June2 = accumulator + currentValue);
                  });
                }
                if (incomingJuly2.length > 0) {
                  incomingJuly2.reduce(function (accumulator, currentValue) {
                    return (July2 = accumulator + currentValue);
                  });
                }
                if (incomingAugust2.length > 0) {
                  incomingAugust2.reduce(function (accumulator, currentValue) {
                    return (August2 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember2.length > 0) {
                  incomingSeptember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September2 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber2.length > 0) {
                  incomingOctomber2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber2 = accumulator + currentValue);
                  });
                }
                if (incomingNovember2.length > 0) {
                  incomingNovember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November2 = accumulator + currentValue);
                  });
                }
                if (incomingDecember2.length > 0) {
                  incomingDecember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December2 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  (January / countJanuary).toFixed(2),
                  (February / countFebruary).toFixed(2),
                  (March / countMarch).toFixed(2),
                  (April / countApril).toFixed(2),
                  (May / countMay).toFixed(2),
                  (June / countJune).toFixed(2),
                  (July / countJuly).toFixed(2),
                  (August / countAugust).toFixed(2),
                  (September / countSeptember).toFixed(2),
                  (Octomber / countOctomber).toFixed(2),
                  (November / countNovember).toFixed(2),
                  (December / countDecember).toFixed(2)
                );
                incomingData1.push(
                  (January1 / countJanuary1).toFixed(2),
                  (February1 / countFebruary1).toFixed(2),
                  (March1 / countMarch1).toFixed(2),
                  (April1 / countApril1).toFixed(2),
                  (May1 / countMay1).toFixed(2),
                  (June1 / countJune1).toFixed(2),
                  (July1 / countJuly1).toFixed(2),
                  (August1 / countAugust1).toFixed(2),
                  (September1 / countSeptember1).toFixed(2),
                  (Octomber1 / countOctomber1).toFixed(2),
                  (November1 / countNovember1).toFixed(2),
                  (December1 / countDecember1).toFixed(2)
                );
                incomingData2.push(
                  (January2 / countJanuary2).toFixed(2),
                  (February2 / countFebruary2).toFixed(2),
                  (March2 / countMarch2).toFixed(2),
                  (April2 / countApril2).toFixed(2),
                  (May2 / countMay2).toFixed(2),
                  (June2 / countJune2).toFixed(2),
                  (July2 / countJuly2).toFixed(2),
                  (August2 / countAugust2).toFixed(2),
                  (September2 / countSeptember2).toFixed(2),
                  (Octomber2 / countOctomber2).toFixed(2),
                  (November2 / countNovember2).toFixed(2),
                  (December2 / countDecember2).toFixed(2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);

                stateCopyThree.data.datasets = stateCopyThree.data.datasets.slice();
                stateCopyThree.data.datasets[2] = Object.assign(
                  {},
                  stateCopyThree.data.datasets[2]
                );
                stateCopyThree.data.datasets[2].data = incomingData2;
                stateCopyThree.data.datasets[2].label = this.state.selectedLegalName[2].label;
                this.setState(stateCopyThree);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              default:
                break;
            }
            break;
          case "dmPercentage":
            switch (this.state.selectedLegalName.length) {
              case 1:
                this.props.compositeAnalytics.forEach((element) => {
                  if (
                    moment(element.prodDate).format("MM") === "01" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJanuary.push(element.dmPercentage);
                    countJanuary++;
                  } else if (
                    moment(element.prodDate).format("MM") === "02" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingFebruary.push(element.dmPercentage);
                    countFebruary++;
                  } else if (
                    moment(element.prodDate).format("MM") === "03" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMarch.push(element.dmPercentage);
                    countMarch++;
                  } else if (
                    moment(element.prodDate).format("MM") === "04" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingApril.push(element.dmPercentage);
                    countApril++;
                  } else if (
                    moment(element.prodDate).format("MM") === "05" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMay.push(element.dmPercentage);
                    countMay++;
                  } else if (
                    moment(element.prodDate).format("MM") === "06" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJune.push(element.dmPercentage);
                    countJune++;
                  } else if (
                    moment(element.prodDate).format("MM") === "07" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJuly.push(element.dmPercentage);
                    countJuly++;
                  } else if (
                    moment(element.prodDate).format("MM") === "08" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingAugust.push(element.dmPercentage);
                    countAugust++;
                  } else if (
                    moment(element.prodDate).format("MM") === "09" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingSeptember.push(element.dmPercentage);
                    countSeptember++;
                  } else if (
                    moment(element.prodDate).format("MM") === "10" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingOctomber.push(element.dmPercentage);
                    countOctomber++;
                  } else if (
                    moment(element.prodDate).format("MM") === "11" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingNovember.push(element.dmPercentage);
                    countNovember++;
                  } else if (
                    moment(element.prodDate).format("MM") === "12" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingDecember.push(element.dmPercentage);
                    countDecember++;
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  (January / countJanuary).toFixed(2),
                  (February / countFebruary).toFixed(2),
                  (March / countMarch).toFixed(2),
                  (April / countApril).toFixed(2),
                  (May / countMay).toFixed(2),
                  (June / countJune).toFixed(2),
                  (July / countJuly).toFixed(2),
                  (August / countAugust).toFixed(2),
                  (September / countSeptember).toFixed(2),
                  (Octomber / countOctomber).toFixed(2),
                  (November / countNovember).toFixed(2),
                  (December / countDecember).toFixed(2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 2:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.dmPercentage);
                      countJanuary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.dmPercentage);
                      countJanuary1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.dmPercentage);
                      countFebruary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.dmPercentage);
                      countFebruary1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.dmPercentage);
                      countMarch++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.dmPercentage);
                      countMarch1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.dmPercentage);
                      countApril++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.dmPercentage);
                      countApril1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.dmPercentage);
                      countMay++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.dmPercentage);
                      countMay1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.dmPercentage);
                      countJune++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.dmPercentage);
                      countJune1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.dmPercentage);
                      countJuly++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.dmPercentage);
                      countJuly1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.dmPercentage);
                      countAugust++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.dmPercentage);
                      countAugust1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.dmPercentage);
                      countSeptember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.dmPercentage);
                      countSeptember1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.dmPercentage);
                      countOctomber++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.dmPercentage);
                      countOctomber1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.dmPercentage);
                      countNovember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.dmPercentage);
                      countNovember1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.dmPercentage);
                      countDecember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.dmPercentage);
                      countDecember1++;
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  (January / countJanuary).toFixed(2),
                  (February / countFebruary).toFixed(2),
                  (March / countMarch).toFixed(2),
                  (April / countApril).toFixed(2),
                  (May / countMay).toFixed(2),
                  (June / countJune).toFixed(2),
                  (July / countJuly).toFixed(2),
                  (August / countAugust).toFixed(2),
                  (September / countSeptember).toFixed(2),
                  (Octomber / countOctomber).toFixed(2),
                  (November / countNovember).toFixed(2),
                  (December / countDecember).toFixed(2)
                );
                incomingData1.push(
                  (January1 / countJanuary1).toFixed(2),
                  (February1 / countFebruary1).toFixed(2),
                  (March1 / countMarch1).toFixed(2),
                  (April1 / countApril1).toFixed(2),
                  (May1 / countMay1).toFixed(2),
                  (June1 / countJune1).toFixed(2),
                  (July1 / countJuly1).toFixed(2),
                  (August1 / countAugust1).toFixed(2),
                  (September1 / countSeptember1).toFixed(2),
                  (Octomber1 / countOctomber1).toFixed(2),
                  (November1 / countNovember1).toFixed(2),
                  (December1 / countDecember1).toFixed(2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 3:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.dmPercentage);
                      countJanuary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.dmPercentage);
                      countJanuary1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJanuary2.push(element.dmPercentage);
                      countJanuary2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.dmPercentage);
                      countFebruary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.dmPercentage);
                      countFebruary1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingFebruary2.push(element.dmPercentage);
                      countFebruary2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.dmPercentage);
                      countMarch++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.dmPercentage);
                      countMarch1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMarch2.push(element.dmPercentage);
                      countMarch2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.dmPercentage);
                      countApril++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.dmPercentage);
                      countApril1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingApril2.push(element.dmPercentage);
                      countApril2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.dmPercentage);
                      countMay++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.dmPercentage);
                      countMay1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMay2.push(element.dmPercentage);
                      countMay2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.dmPercentage);
                      countJune++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.dmPercentage);
                      countJune1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJune2.push(element.dmPercentage);
                      countJune2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.dmPercentage);
                      countJuly++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.dmPercentage);
                      countJuly1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJuly2.push(element.dmPercentage);
                      countJuly2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.dmPercentage);
                      countAugust++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.dmPercentage);
                      countAugust1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingAugust2.push(element.dmPercentage);
                      countAugust2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.dmPercentage);
                      countSeptember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.dmPercentage);
                      countSeptember1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingSeptember2.push(element.dmPercentage);
                      countSeptember2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.dmPercentage);
                      countOctomber++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.dmPercentage);
                      countOctomber1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingOctomber2.push(element.dmPercentage);
                      countOctomber2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.dmPercentage);
                      countNovember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.dmPercentage);
                      countNovember1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingNovember2.push(element.dmPercentage);
                      countNovember2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.dmPercentage);
                      countDecember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.dmPercentage);
                      countDecember1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingDecember2.push(element.dmPercentage);
                      countDecember2++;
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                if (incomingJanuary2.length > 0) {
                  incomingJanuary2.reduce(function (accumulator, currentValue) {
                    return (January2 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary2.length > 0) {
                  incomingFebruary2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February2 = accumulator + currentValue);
                  });
                }
                if (incomingMarch2.length > 0) {
                  incomingMarch2.reduce(function (accumulator, currentValue) {
                    return (March2 = accumulator + currentValue);
                  });
                }
                if (incomingApril2.length > 0) {
                  incomingApril2.reduce(function (accumulator, currentValue) {
                    return (April2 = accumulator + currentValue);
                  });
                }
                if (incomingMay2.length > 0) {
                  incomingMay2.reduce(function (accumulator, currentValue) {
                    return (May2 = accumulator + currentValue);
                  });
                }
                if (incomingJune2.length > 0) {
                  incomingJune2.reduce(function (accumulator, currentValue) {
                    return (June2 = accumulator + currentValue);
                  });
                }
                if (incomingJuly2.length > 0) {
                  incomingJuly2.reduce(function (accumulator, currentValue) {
                    return (July2 = accumulator + currentValue);
                  });
                }
                if (incomingAugust2.length > 0) {
                  incomingAugust2.reduce(function (accumulator, currentValue) {
                    return (August2 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember2.length > 0) {
                  incomingSeptember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September2 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber2.length > 0) {
                  incomingOctomber2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber2 = accumulator + currentValue);
                  });
                }
                if (incomingNovember2.length > 0) {
                  incomingNovember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November2 = accumulator + currentValue);
                  });
                }
                if (incomingDecember2.length > 0) {
                  incomingDecember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December2 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  (January / countJanuary).toFixed(2),
                  (February / countFebruary).toFixed(2),
                  (March / countMarch).toFixed(2),
                  (April / countApril).toFixed(2),
                  (May / countMay).toFixed(2),
                  (June / countJune).toFixed(2),
                  (July / countJuly).toFixed(2),
                  (August / countAugust).toFixed(2),
                  (September / countSeptember).toFixed(2),
                  (Octomber / countOctomber).toFixed(2),
                  (November / countNovember).toFixed(2),
                  (December / countDecember).toFixed(2)
                );
                incomingData1.push(
                  (January1 / countJanuary1).toFixed(2),
                  (February1 / countFebruary1).toFixed(2),
                  (March1 / countMarch1).toFixed(2),
                  (April1 / countApril1).toFixed(2),
                  (May1 / countMay1).toFixed(2),
                  (June1 / countJune1).toFixed(2),
                  (July1 / countJuly1).toFixed(2),
                  (August1 / countAugust1).toFixed(2),
                  (September1 / countSeptember1).toFixed(2),
                  (Octomber1 / countOctomber1).toFixed(2),
                  (November1 / countNovember1).toFixed(2),
                  (December1 / countDecember1).toFixed(2)
                );
                incomingData2.push(
                  (January2 / countJanuary2).toFixed(2),
                  (February2 / countFebruary2).toFixed(2),
                  (March2 / countMarch2).toFixed(2),
                  (April2 / countApril2).toFixed(2),
                  (May2 / countMay2).toFixed(2),
                  (June2 / countJune2).toFixed(2),
                  (July2 / countJuly2).toFixed(2),
                  (August2 / countAugust2).toFixed(2),
                  (September2 / countSeptember2).toFixed(2),
                  (Octomber2 / countOctomber2).toFixed(2),
                  (November2 / countNovember2).toFixed(2),
                  (December2 / countDecember2).toFixed(2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);

                stateCopyThree.data.datasets = stateCopyThree.data.datasets.slice();
                stateCopyThree.data.datasets[2] = Object.assign(
                  {},
                  stateCopyThree.data.datasets[2]
                );
                stateCopyThree.data.datasets[2].data = incomingData2;
                stateCopyThree.data.datasets[2].label = this.state.selectedLegalName[2].label;
                this.setState(stateCopyThree);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              default:
                break;
            }
            break;
          case "mPercentage":
            switch (this.state.selectedLegalName.length) {
              case 1:
                this.props.compositeAnalytics.forEach((element) => {
                  if (
                    moment(element.prodDate).format("MM") === "01" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJanuary.push(element.mPercentage);
                    countJanuary++;
                  } else if (
                    moment(element.prodDate).format("MM") === "02" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingFebruary.push(element.mPercentage);
                    countFebruary++;
                  } else if (
                    moment(element.prodDate).format("MM") === "03" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMarch.push(element.mPercentage);
                    countMarch++;
                  } else if (
                    moment(element.prodDate).format("MM") === "04" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingApril.push(element.mPercentage);
                    countApril++;
                  } else if (
                    moment(element.prodDate).format("MM") === "05" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMay.push(element.mPercentage);
                    countMay++;
                  } else if (
                    moment(element.prodDate).format("MM") === "06" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJune.push(element.mPercentage);
                    countJune++;
                  } else if (
                    moment(element.prodDate).format("MM") === "07" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJuly.push(element.mPercentage);
                    countJuly++;
                  } else if (
                    moment(element.prodDate).format("MM") === "08" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingAugust.push(element.mPercentage);
                    countAugust++;
                  } else if (
                    moment(element.prodDate).format("MM") === "09" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingSeptember.push(element.mPercentage);
                    countSeptember++;
                  } else if (
                    moment(element.prodDate).format("MM") === "10" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingOctomber.push(element.mPercentage);
                    countOctomber++;
                  } else if (
                    moment(element.prodDate).format("MM") === "11" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingNovember.push(element.mPercentage);
                    countNovember++;
                  } else if (
                    moment(element.prodDate).format("MM") === "12" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingDecember.push(element.mPercentage);
                    countDecember++;
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  (January / countJanuary).toFixed(2),
                  (February / countFebruary).toFixed(2),
                  (March / countMarch).toFixed(2),
                  (April / countApril).toFixed(2),
                  (May / countMay).toFixed(2),
                  (June / countJune).toFixed(2),
                  (July / countJuly).toFixed(2),
                  (August / countAugust).toFixed(2),
                  (September / countSeptember).toFixed(2),
                  (Octomber / countOctomber).toFixed(2),
                  (November / countNovember).toFixed(2),
                  (December / countDecember).toFixed(2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 2:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.mPercentage);
                      countJanuary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.mPercentage);
                      countJanuary1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.mPercentage);
                      countFebruary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.mPercentage);
                      countFebruary1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.mPercentage);
                      countMarch++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.mPercentage);
                      countMarch1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.mPercentage);
                      countApril++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.mPercentage);
                      countApril1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.mPercentage);
                      countMay++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.mPercentage);
                      countMay1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.mPercentage);
                      countJune++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.mPercentage);
                      countJune1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.mPercentage);
                      countJuly++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.mPercentage);
                      countJuly1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.mPercentage);
                      countAugust++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.mPercentage);
                      countAugust1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.mPercentage);
                      countSeptember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.mPercentage);
                      countSeptember1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.mPercentage);
                      countOctomber++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.mPercentage);
                      countOctomber1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.mPercentage);
                      countNovember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.mPercentage);
                      countNovember1++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.mPercentage);
                      countDecember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.mPercentage);
                      countDecember1++;
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  (January / countJanuary).toFixed(2),
                  (February / countFebruary).toFixed(2),
                  (March / countMarch).toFixed(2),
                  (April / countApril).toFixed(2),
                  (May / countMay).toFixed(2),
                  (June / countJune).toFixed(2),
                  (July / countJuly).toFixed(2),
                  (August / countAugust).toFixed(2),
                  (September / countSeptember).toFixed(2),
                  (Octomber / countOctomber).toFixed(2),
                  (November / countNovember).toFixed(2),
                  (December / countDecember).toFixed(2)
                );
                incomingData1.push(
                  (January1 / countJanuary1).toFixed(2),
                  (February1 / countFebruary1).toFixed(2),
                  (March1 / countMarch1).toFixed(2),
                  (April1 / countApril1).toFixed(2),
                  (May1 / countMay1).toFixed(2),
                  (June1 / countJune1).toFixed(2),
                  (July1 / countJuly1).toFixed(2),
                  (August1 / countAugust1).toFixed(2),
                  (September1 / countSeptember1).toFixed(2),
                  (Octomber1 / countOctomber1).toFixed(2),
                  (November1 / countNovember1).toFixed(2),
                  (December1 / countDecember1).toFixed(2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 3:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.mPercentage);
                      countJanuary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.mPercentage);
                      countJanuary1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJanuary2.push(element.mPercentage);
                      countJanuary2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.mPercentage);
                      countFebruary++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.mPercentage);
                      countFebruary1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingFebruary2.push(element.mPercentage);
                      countFebruary2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.mPercentage);
                      countMarch++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.mPercentage);
                      countMarch1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMarch2.push(element.mPercentage);
                      countMarch2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.mPercentage);
                      countApril++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.mPercentage);
                      countApril1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingApril2.push(element.mPercentage);
                      countApril2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.mPercentage);
                      countMay++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.mPercentage);
                      countMay1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMay2.push(element.mPercentage);
                      countMay2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.mPercentage);
                      countJune++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.mPercentage);
                      countJune1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJune2.push(element.mPercentage);
                      countJune2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.mPercentage);
                      countJuly++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.mPercentage);
                      countJuly1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJuly2.push(element.mPercentage);
                      countJuly2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.mPercentage);
                      countAugust++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.mPercentage);
                      countAugust1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingAugust2.push(element.mPercentage);
                      countAugust2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.mPercentage);
                      countSeptember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.mPercentage);
                      countSeptember1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingSeptember2.push(element.mPercentage);
                      countSeptember2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.mPercentage);
                      countOctomber++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.mPercentage);
                      countOctomber1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingOctomber2.push(element.mPercentage);
                      countOctomber2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.mPercentage);
                      countNovember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.mPercentage);
                      countNovember1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingNovember2.push(element.mPercentage);
                      countNovember2++;
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.mPercentage);
                      countDecember++;
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.mPercentage);
                      countDecember1++;
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingDecember2.push(element.mPercentage);
                      countDecember2++;
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                if (incomingJanuary2.length > 0) {
                  incomingJanuary2.reduce(function (accumulator, currentValue) {
                    return (January2 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary2.length > 0) {
                  incomingFebruary2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February2 = accumulator + currentValue);
                  });
                }
                if (incomingMarch2.length > 0) {
                  incomingMarch2.reduce(function (accumulator, currentValue) {
                    return (March2 = accumulator + currentValue);
                  });
                }
                if (incomingApril2.length > 0) {
                  incomingApril2.reduce(function (accumulator, currentValue) {
                    return (April2 = accumulator + currentValue);
                  });
                }
                if (incomingMay2.length > 0) {
                  incomingMay2.reduce(function (accumulator, currentValue) {
                    return (May2 = accumulator + currentValue);
                  });
                }
                if (incomingJune2.length > 0) {
                  incomingJune2.reduce(function (accumulator, currentValue) {
                    return (June2 = accumulator + currentValue);
                  });
                }
                if (incomingJuly2.length > 0) {
                  incomingJuly2.reduce(function (accumulator, currentValue) {
                    return (July2 = accumulator + currentValue);
                  });
                }
                if (incomingAugust2.length > 0) {
                  incomingAugust2.reduce(function (accumulator, currentValue) {
                    return (August2 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember2.length > 0) {
                  incomingSeptember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September2 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber2.length > 0) {
                  incomingOctomber2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber2 = accumulator + currentValue);
                  });
                }
                if (incomingNovember2.length > 0) {
                  incomingNovember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November2 = accumulator + currentValue);
                  });
                }
                if (incomingDecember2.length > 0) {
                  incomingDecember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December2 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  (January / countJanuary).toFixed(2),
                  (February / countFebruary).toFixed(2),
                  (March / countMarch).toFixed(2),
                  (April / countApril).toFixed(2),
                  (May / countMay).toFixed(2),
                  (June / countJune).toFixed(2),
                  (July / countJuly).toFixed(2),
                  (August / countAugust).toFixed(2),
                  (September / countSeptember).toFixed(2),
                  (Octomber / countOctomber).toFixed(2),
                  (November / countNovember).toFixed(2),
                  (December / countDecember).toFixed(2)
                );
                incomingData1.push(
                  (January1 / countJanuary1).toFixed(2),
                  (February1 / countFebruary1).toFixed(2),
                  (March1 / countMarch1).toFixed(2),
                  (April1 / countApril1).toFixed(2),
                  (May1 / countMay1).toFixed(2),
                  (June1 / countJune1).toFixed(2),
                  (July1 / countJuly1).toFixed(2),
                  (August1 / countAugust1).toFixed(2),
                  (September1 / countSeptember1).toFixed(2),
                  (Octomber1 / countOctomber1).toFixed(2),
                  (November1 / countNovember1).toFixed(2),
                  (December1 / countDecember1).toFixed(2)
                );
                incomingData2.push(
                  (January2 / countJanuary2).toFixed(2),
                  (February2 / countFebruary2).toFixed(2),
                  (March2 / countMarch2).toFixed(2),
                  (April2 / countApril2).toFixed(2),
                  (May2 / countMay2).toFixed(2),
                  (June2 / countJune2).toFixed(2),
                  (July2 / countJuly2).toFixed(2),
                  (August2 / countAugust2).toFixed(2),
                  (September2 / countSeptember2).toFixed(2),
                  (Octomber2 / countOctomber2).toFixed(2),
                  (November2 / countNovember2).toFixed(2),
                  (December2 / countDecember2).toFixed(2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);

                stateCopyThree.data.datasets = stateCopyThree.data.datasets.slice();
                stateCopyThree.data.datasets[2] = Object.assign(
                  {},
                  stateCopyThree.data.datasets[2]
                );
                stateCopyThree.data.datasets[2].data = incomingData2;
                stateCopyThree.data.datasets[2].label = this.state.selectedLegalName[2].label;
                this.setState(stateCopyThree);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              default:
                break;
            }
            break;
          case "qtyProduced":
            switch (this.state.selectedLegalName.length) {
              case 1:
                this.props.compositeAnalytics.forEach((element) => {
                  if (
                    moment(element.prodDate).format("MM") === "01" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJanuary.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "02" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingFebruary.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "03" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMarch.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "04" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingApril.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "05" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMay.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "06" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJune.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "07" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJuly.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "08" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingAugust.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "09" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingSeptember.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "10" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingOctomber.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "11" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingNovember.push(element.qtyProduced);
                  } else if (
                    moment(element.prodDate).format("MM") === "12" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingDecember.push(element.qtyProduced);
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  January,
                  February,
                  March,
                  April,
                  May,
                  June,
                  July,
                  August,
                  September,
                  Octomber,
                  November,
                  December
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 2:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.qtyProduced);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  January,
                  February,
                  March,
                  April,
                  May,
                  June,
                  July,
                  August,
                  September,
                  Octomber,
                  November,
                  December
                );
                incomingData1.push(
                  January1,
                  February1,
                  March1,
                  April1,
                  May1,
                  June1,
                  July1,
                  August1,
                  September1,
                  Octomber1,
                  November1,
                  December1
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 3:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJanuary2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingFebruary2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMarch2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingApril2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMay2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJune2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJuly2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingAugust2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingSeptember2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingOctomber2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingNovember2.push(element.qtyProduced);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.qtyProduced);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingDecember2.push(element.qtyProduced);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                if (incomingJanuary2.length > 0) {
                  incomingJanuary2.reduce(function (accumulator, currentValue) {
                    return (January2 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary2.length > 0) {
                  incomingFebruary2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February2 = accumulator + currentValue);
                  });
                }
                if (incomingMarch2.length > 0) {
                  incomingMarch2.reduce(function (accumulator, currentValue) {
                    return (March2 = accumulator + currentValue);
                  });
                }
                if (incomingApril2.length > 0) {
                  incomingApril2.reduce(function (accumulator, currentValue) {
                    return (April2 = accumulator + currentValue);
                  });
                }
                if (incomingMay2.length > 0) {
                  incomingMay2.reduce(function (accumulator, currentValue) {
                    return (May2 = accumulator + currentValue);
                  });
                }
                if (incomingJune2.length > 0) {
                  incomingJune2.reduce(function (accumulator, currentValue) {
                    return (June2 = accumulator + currentValue);
                  });
                }
                if (incomingJuly2.length > 0) {
                  incomingJuly2.reduce(function (accumulator, currentValue) {
                    return (July2 = accumulator + currentValue);
                  });
                }
                if (incomingAugust2.length > 0) {
                  incomingAugust2.reduce(function (accumulator, currentValue) {
                    return (August2 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember2.length > 0) {
                  incomingSeptember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September2 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber2.length > 0) {
                  incomingOctomber2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber2 = accumulator + currentValue);
                  });
                }
                if (incomingNovember2.length > 0) {
                  incomingNovember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November2 = accumulator + currentValue);
                  });
                }
                if (incomingDecember2.length > 0) {
                  incomingDecember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December2 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  January,
                  February,
                  March,
                  April,
                  May,
                  June,
                  July,
                  August,
                  September,
                  Octomber,
                  November,
                  December
                );
                incomingData1.push(
                  January1,
                  February1,
                  March1,
                  April1,
                  May1,
                  June1,
                  July1,
                  August1,
                  September1,
                  Octomber1,
                  November1,
                  December1
                );
                incomingData2.push(
                  January2,
                  February2,
                  March2,
                  April2,
                  May2,
                  June2,
                  July2,
                  August2,
                  September2,
                  Octomber2,
                  November2,
                  December2
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);

                stateCopyThree.data.datasets = stateCopyThree.data.datasets.slice();
                stateCopyThree.data.datasets[2] = Object.assign(
                  {},
                  stateCopyThree.data.datasets[2]
                );
                stateCopyThree.data.datasets[2].data = incomingData2;
                stateCopyThree.data.datasets[2].label = this.state.selectedLegalName[2].label;
                this.setState(stateCopyThree);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              default:
                break;
            }
            break;
          case "m2":
            switch (this.state.selectedLegalName.length) {
              case 1:
                this.props.compositeAnalytics.forEach((element) => {
                  if (
                    moment(element.prodDate).format("MM") === "01" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJanuary.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "02" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingFebruary.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "03" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMarch.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "04" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingApril.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "05" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMay.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "06" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJune.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "07" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJuly.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "08" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingAugust.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "09" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingSeptember.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "10" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingOctomber.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "11" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingNovember.push(element.m2);
                  } else if (
                    moment(element.prodDate).format("MM") === "12" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingDecember.push(element.m2);
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  January,
                  February,
                  March,
                  April,
                  May,
                  June,
                  July,
                  August,
                  September,
                  Octomber,
                  November,
                  December
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 2:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.m2);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  January,
                  February,
                  March,
                  April,
                  May,
                  June,
                  July,
                  August,
                  September,
                  Octomber,
                  November,
                  December
                );
                incomingData1.push(
                  January1,
                  February1,
                  March1,
                  April1,
                  May1,
                  June1,
                  July1,
                  August1,
                  September1,
                  Octomber1,
                  November1,
                  December1
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 3:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJanuary2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingFebruary2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMarch2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingApril2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMay2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJune2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJuly2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingAugust2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingSeptember2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingOctomber2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingNovember2.push(element.m2);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.m2);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingDecember2.push(element.m2);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                if (incomingJanuary2.length > 0) {
                  incomingJanuary2.reduce(function (accumulator, currentValue) {
                    return (January2 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary2.length > 0) {
                  incomingFebruary2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February2 = accumulator + currentValue);
                  });
                }
                if (incomingMarch2.length > 0) {
                  incomingMarch2.reduce(function (accumulator, currentValue) {
                    return (March2 = accumulator + currentValue);
                  });
                }
                if (incomingApril2.length > 0) {
                  incomingApril2.reduce(function (accumulator, currentValue) {
                    return (April2 = accumulator + currentValue);
                  });
                }
                if (incomingMay2.length > 0) {
                  incomingMay2.reduce(function (accumulator, currentValue) {
                    return (May2 = accumulator + currentValue);
                  });
                }
                if (incomingJune2.length > 0) {
                  incomingJune2.reduce(function (accumulator, currentValue) {
                    return (June2 = accumulator + currentValue);
                  });
                }
                if (incomingJuly2.length > 0) {
                  incomingJuly2.reduce(function (accumulator, currentValue) {
                    return (July2 = accumulator + currentValue);
                  });
                }
                if (incomingAugust2.length > 0) {
                  incomingAugust2.reduce(function (accumulator, currentValue) {
                    return (August2 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember2.length > 0) {
                  incomingSeptember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September2 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber2.length > 0) {
                  incomingOctomber2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber2 = accumulator + currentValue);
                  });
                }
                if (incomingNovember2.length > 0) {
                  incomingNovember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November2 = accumulator + currentValue);
                  });
                }
                if (incomingDecember2.length > 0) {
                  incomingDecember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December2 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  January,
                  February,
                  March,
                  April,
                  May,
                  June,
                  July,
                  August,
                  September,
                  Octomber,
                  November,
                  December
                );
                incomingData1.push(
                  January1,
                  February1,
                  March1,
                  April1,
                  May1,
                  June1,
                  July1,
                  August1,
                  September1,
                  Octomber1,
                  November1,
                  December1
                );
                incomingData2.push(
                  January2,
                  February2,
                  March2,
                  April2,
                  May2,
                  June2,
                  July2,
                  August2,
                  September2,
                  Octomber2,
                  November2,
                  December2
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);

                stateCopyThree.data.datasets = stateCopyThree.data.datasets.slice();
                stateCopyThree.data.datasets[2] = Object.assign(
                  {},
                  stateCopyThree.data.datasets[2]
                );
                stateCopyThree.data.datasets[2].data = incomingData2;
                stateCopyThree.data.datasets[2].label = this.state.selectedLegalName[2].label;
                this.setState(stateCopyThree);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              default:
                break;
            }
            break;
          case "materialCost":
            switch (this.state.selectedLegalName.length) {
              case 1:
                this.props.compositeAnalytics.forEach((element) => {
                  if (
                    moment(element.prodDate).format("MM") === "01" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJanuary.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "02" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingFebruary.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "03" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMarch.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "04" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingApril.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "05" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMay.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "06" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJune.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "07" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJuly.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "08" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingAugust.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "09" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingSeptember.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "10" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingOctomber.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "11" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingNovember.push(element.materialCost);
                  } else if (
                    moment(element.prodDate).format("MM") === "12" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingDecember.push(element.materialCost);
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  Math.round(January),
                  Math.round(February),
                  Math.round(March),
                  Math.round(April),
                  Math.round(May),
                  Math.round(June),
                  Math.round(July),
                  Math.round(August),
                  Math.round(September),
                  Math.round(Octomber),
                  Math.round(November),
                  Math.round(December)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 2:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.materialCost);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  Math.round(January),
                  Math.round(February),
                  Math.round(March),
                  Math.round(April),
                  Math.round(May),
                  Math.round(June),
                  Math.round(July),
                  Math.round(August),
                  Math.round(September),
                  Math.round(Octomber),
                  Math.round(November),
                  Math.round(December)
                );
                incomingData1.push(
                  Math.round(January1),
                  Math.round(February1),
                  Math.round(March1),
                  Math.round(April1),
                  Math.round(May1),
                  Math.round(June1),
                  Math.round(July1),
                  Math.round(August1),
                  Math.round(September1),
                  Math.round(Octomber1),
                  Math.round(November1),
                  Math.round(December1)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 3:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJanuary2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingFebruary2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMarch2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingApril2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMay2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJune2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJuly2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingAugust2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingSeptember2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingOctomber2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingNovember2.push(element.materialCost);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.materialCost);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingDecember2.push(element.materialCost);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                if (incomingJanuary2.length > 0) {
                  incomingJanuary2.reduce(function (accumulator, currentValue) {
                    return (January2 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary2.length > 0) {
                  incomingFebruary2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February2 = accumulator + currentValue);
                  });
                }
                if (incomingMarch2.length > 0) {
                  incomingMarch2.reduce(function (accumulator, currentValue) {
                    return (March2 = accumulator + currentValue);
                  });
                }
                if (incomingApril2.length > 0) {
                  incomingApril2.reduce(function (accumulator, currentValue) {
                    return (April2 = accumulator + currentValue);
                  });
                }
                if (incomingMay2.length > 0) {
                  incomingMay2.reduce(function (accumulator, currentValue) {
                    return (May2 = accumulator + currentValue);
                  });
                }
                if (incomingJune2.length > 0) {
                  incomingJune2.reduce(function (accumulator, currentValue) {
                    return (June2 = accumulator + currentValue);
                  });
                }
                if (incomingJuly2.length > 0) {
                  incomingJuly2.reduce(function (accumulator, currentValue) {
                    return (July2 = accumulator + currentValue);
                  });
                }
                if (incomingAugust2.length > 0) {
                  incomingAugust2.reduce(function (accumulator, currentValue) {
                    return (August2 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember2.length > 0) {
                  incomingSeptember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September2 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber2.length > 0) {
                  incomingOctomber2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber2 = accumulator + currentValue);
                  });
                }
                if (incomingNovember2.length > 0) {
                  incomingNovember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November2 = accumulator + currentValue);
                  });
                }
                if (incomingDecember2.length > 0) {
                  incomingDecember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December2 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  Math.round(January),
                  Math.round(February),
                  Math.round(March),
                  Math.round(April),
                  Math.round(May),
                  Math.round(June),
                  Math.round(July),
                  Math.round(August),
                  Math.round(September),
                  Math.round(Octomber),
                  Math.round(November),
                  Math.round(December)
                );
                incomingData1.push(
                  Math.round(January1),
                  Math.round(February1),
                  Math.round(March1),
                  Math.round(April1),
                  Math.round(May1),
                  Math.round(June1),
                  Math.round(July1),
                  Math.round(August1),
                  Math.round(September1),
                  Math.round(Octomber1),
                  Math.round(November1),
                  Math.round(December1)
                );
                incomingData2.push(
                  Math.round(January2),
                  Math.round(February2),
                  Math.round(March2),
                  Math.round(April2),
                  Math.round(May2),
                  Math.round(June2),
                  Math.round(July2),
                  Math.round(August2),
                  Math.round(September2),
                  Math.round(Octomber2),
                  Math.round(November2),
                  Math.round(December2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);

                stateCopyThree.data.datasets = stateCopyThree.data.datasets.slice();
                stateCopyThree.data.datasets[2] = Object.assign(
                  {},
                  stateCopyThree.data.datasets[2]
                );
                stateCopyThree.data.datasets[2].data = incomingData2;
                stateCopyThree.data.datasets[2].label = this.state.selectedLegalName[2].label;
                this.setState(stateCopyThree);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              default:
                break;
            }
            break;
          case "turnover":
            switch (this.state.selectedLegalName.length) {
              case 1:
                this.props.compositeAnalytics.forEach((element) => {
                  if (
                    moment(element.prodDate).format("MM") === "01" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJanuary.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "02" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingFebruary.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "03" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMarch.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "04" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingApril.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "05" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMay.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "06" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJune.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "07" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJuly.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "08" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingAugust.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "09" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingSeptember.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "10" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingOctomber.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "11" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingNovember.push(element.turnover);
                  } else if (
                    moment(element.prodDate).format("MM") === "12" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingDecember.push(element.turnover);
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  Math.round(January),
                  Math.round(February),
                  Math.round(March),
                  Math.round(April),
                  Math.round(May),
                  Math.round(June),
                  Math.round(July),
                  Math.round(August),
                  Math.round(September),
                  Math.round(Octomber),
                  Math.round(November),
                  Math.round(December)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 2:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.turnover);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  Math.round(January),
                  Math.round(February),
                  Math.round(March),
                  Math.round(April),
                  Math.round(May),
                  Math.round(June),
                  Math.round(July),
                  Math.round(August),
                  Math.round(September),
                  Math.round(Octomber),
                  Math.round(November),
                  Math.round(December)
                );
                incomingData1.push(
                  Math.round(January1),
                  Math.round(February1),
                  Math.round(March1),
                  Math.round(April1),
                  Math.round(May1),
                  Math.round(June1),
                  Math.round(July1),
                  Math.round(August1),
                  Math.round(September1),
                  Math.round(Octomber1),
                  Math.round(November1),
                  Math.round(December1)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 3:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJanuary2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingFebruary2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMarch2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingApril2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMay2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJune2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJuly2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingAugust2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingSeptember2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingOctomber2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingNovember2.push(element.turnover);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.turnover);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingDecember2.push(element.turnover);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                if (incomingJanuary2.length > 0) {
                  incomingJanuary2.reduce(function (accumulator, currentValue) {
                    return (January2 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary2.length > 0) {
                  incomingFebruary2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February2 = accumulator + currentValue);
                  });
                }
                if (incomingMarch2.length > 0) {
                  incomingMarch2.reduce(function (accumulator, currentValue) {
                    return (March2 = accumulator + currentValue);
                  });
                }
                if (incomingApril2.length > 0) {
                  incomingApril2.reduce(function (accumulator, currentValue) {
                    return (April2 = accumulator + currentValue);
                  });
                }
                if (incomingMay2.length > 0) {
                  incomingMay2.reduce(function (accumulator, currentValue) {
                    return (May2 = accumulator + currentValue);
                  });
                }
                if (incomingJune2.length > 0) {
                  incomingJune2.reduce(function (accumulator, currentValue) {
                    return (June2 = accumulator + currentValue);
                  });
                }
                if (incomingJuly2.length > 0) {
                  incomingJuly2.reduce(function (accumulator, currentValue) {
                    return (July2 = accumulator + currentValue);
                  });
                }
                if (incomingAugust2.length > 0) {
                  incomingAugust2.reduce(function (accumulator, currentValue) {
                    return (August2 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember2.length > 0) {
                  incomingSeptember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September2 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber2.length > 0) {
                  incomingOctomber2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber2 = accumulator + currentValue);
                  });
                }
                if (incomingNovember2.length > 0) {
                  incomingNovember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November2 = accumulator + currentValue);
                  });
                }
                if (incomingDecember2.length > 0) {
                  incomingDecember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December2 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  Math.round(January),
                  Math.round(February),
                  Math.round(March),
                  Math.round(April),
                  Math.round(May),
                  Math.round(June),
                  Math.round(July),
                  Math.round(August),
                  Math.round(September),
                  Math.round(Octomber),
                  Math.round(November),
                  Math.round(December)
                );
                incomingData1.push(
                  Math.round(January1),
                  Math.round(February1),
                  Math.round(March1),
                  Math.round(April1),
                  Math.round(May1),
                  Math.round(June1),
                  Math.round(July1),
                  Math.round(August1),
                  Math.round(September1),
                  Math.round(Octomber1),
                  Math.round(November1),
                  Math.round(December1)
                );
                incomingData2.push(
                  Math.round(January2),
                  Math.round(February2),
                  Math.round(March2),
                  Math.round(April2),
                  Math.round(May2),
                  Math.round(June2),
                  Math.round(July2),
                  Math.round(August2),
                  Math.round(September2),
                  Math.round(Octomber2),
                  Math.round(November2),
                  Math.round(December2)
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);

                stateCopyThree.data.datasets = stateCopyThree.data.datasets.slice();
                stateCopyThree.data.datasets[2] = Object.assign(
                  {},
                  stateCopyThree.data.datasets[2]
                );
                stateCopyThree.data.datasets[2].data = incomingData2;
                stateCopyThree.data.datasets[2].label = this.state.selectedLegalName[2].label;
                this.setState(stateCopyThree);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              default:
                break;
            }
            break;
          case "loss":
            switch (this.state.selectedLegalName.length) {
              case 1:
                this.props.compositeAnalytics.forEach((element) => {
                  if (
                    moment(element.prodDate).format("MM") === "01" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJanuary.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "02" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingFebruary.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "03" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMarch.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "04" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingApril.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "05" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingMay.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "06" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJune.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "07" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingJuly.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "08" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingAugust.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "09" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingSeptember.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "10" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingOctomber.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "11" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingNovember.push(element.loss);
                  } else if (
                    moment(element.prodDate).format("MM") === "12" &&
                    this.state.selectedLegalName[0].value === element.legalName
                  ) {
                    incomingDecember.push(element.loss);
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  January,
                  February,
                  March,
                  April,
                  May,
                  June,
                  July,
                  August,
                  September,
                  Octomber,
                  November,
                  December
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 2:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.loss);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  January,
                  February,
                  March,
                  April,
                  May,
                  June,
                  July,
                  August,
                  September,
                  Octomber,
                  November,
                  December
                );
                incomingData1.push(
                  January1,
                  February1,
                  March1,
                  April1,
                  May1,
                  June1,
                  July1,
                  August1,
                  September1,
                  Octomber1,
                  November1,
                  December1
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              case 3:
                this.props.compositeAnalytics.forEach((element) => {
                  if (moment(element.prodDate).format("MM") === "01") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJanuary.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJanuary1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJanuary2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "02") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingFebruary.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingFebruary1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingFebruary2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "03") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMarch.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMarch1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMarch2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "04") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingApril.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingApril1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingApril2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "05") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingMay.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingMay1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingMay2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "06") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJune.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJune1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJune2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "07") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingJuly.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingJuly1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingJuly2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "08") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingAugust.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingAugust1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingAugust2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "09") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingSeptember.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingSeptember1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingSeptember2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "10") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingOctomber.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingOctomber1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingOctomber2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "11") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingNovember.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingNovember1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingNovember2.push(element.loss);
                    }
                  } else if (moment(element.prodDate).format("MM") === "12") {
                    if (
                      this.state.selectedLegalName[0].value ===
                      element.legalName
                    ) {
                      incomingDecember.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[1].value ===
                      element.legalName
                    ) {
                      incomingDecember1.push(element.loss);
                    } else if (
                      this.state.selectedLegalName[2].value ===
                      element.legalName
                    ) {
                      incomingDecember2.push(element.loss);
                    }
                  }
                });
                incomingLabels = monthLabels;
                if (incomingJanuary.length > 0) {
                  incomingJanuary.reduce(function (accumulator, currentValue) {
                    return (January = accumulator + currentValue);
                  });
                }
                if (incomingFebruary.length > 0) {
                  incomingFebruary.reduce(function (accumulator, currentValue) {
                    return (February = accumulator + currentValue);
                  });
                }
                if (incomingMarch.length > 0) {
                  incomingMarch.reduce(function (accumulator, currentValue) {
                    return (March = accumulator + currentValue);
                  });
                }
                if (incomingApril.length > 0) {
                  incomingApril.reduce(function (accumulator, currentValue) {
                    return (April = accumulator + currentValue);
                  });
                }
                if (incomingMay.length > 0) {
                  incomingMay.reduce(function (accumulator, currentValue) {
                    return (May = accumulator + currentValue);
                  });
                }
                if (incomingJune.length > 0) {
                  incomingJune.reduce(function (accumulator, currentValue) {
                    return (June = accumulator + currentValue);
                  });
                }
                if (incomingJuly.length > 0) {
                  incomingJuly.reduce(function (accumulator, currentValue) {
                    return (July = accumulator + currentValue);
                  });
                }
                if (incomingAugust.length > 0) {
                  incomingAugust.reduce(function (accumulator, currentValue) {
                    return (August = accumulator + currentValue);
                  });
                }
                if (incomingSeptember.length > 0) {
                  incomingSeptember.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September = accumulator + currentValue);
                  });
                }
                if (incomingOctomber.length > 0) {
                  incomingOctomber.reduce(function (accumulator, currentValue) {
                    return (Octomber = accumulator + currentValue);
                  });
                }
                if (incomingNovember.length > 0) {
                  incomingNovember.reduce(function (accumulator, currentValue) {
                    return (November = accumulator + currentValue);
                  });
                }
                if (incomingDecember.length > 0) {
                  incomingDecember.reduce(function (accumulator, currentValue) {
                    return (December = accumulator + currentValue);
                  });
                }
                if (incomingJanuary1.length > 0) {
                  incomingJanuary1.reduce(function (accumulator, currentValue) {
                    return (January1 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary1.length > 0) {
                  incomingFebruary1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February1 = accumulator + currentValue);
                  });
                }
                if (incomingMarch1.length > 0) {
                  incomingMarch1.reduce(function (accumulator, currentValue) {
                    return (March1 = accumulator + currentValue);
                  });
                }
                if (incomingApril1.length > 0) {
                  incomingApril1.reduce(function (accumulator, currentValue) {
                    return (April1 = accumulator + currentValue);
                  });
                }
                if (incomingMay1.length > 0) {
                  incomingMay1.reduce(function (accumulator, currentValue) {
                    return (May1 = accumulator + currentValue);
                  });
                }
                if (incomingJune1.length > 0) {
                  incomingJune1.reduce(function (accumulator, currentValue) {
                    return (June1 = accumulator + currentValue);
                  });
                }
                if (incomingJuly1.length > 0) {
                  incomingJuly1.reduce(function (accumulator, currentValue) {
                    return (July1 = accumulator + currentValue);
                  });
                }
                if (incomingAugust1.length > 0) {
                  incomingAugust1.reduce(function (accumulator, currentValue) {
                    return (August1 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember1.length > 0) {
                  incomingSeptember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September1 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber1.length > 0) {
                  incomingOctomber1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber1 = accumulator + currentValue);
                  });
                }
                if (incomingNovember1.length > 0) {
                  incomingNovember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November1 = accumulator + currentValue);
                  });
                }
                if (incomingDecember1.length > 0) {
                  incomingDecember1.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December1 = accumulator + currentValue);
                  });
                }
                if (incomingJanuary2.length > 0) {
                  incomingJanuary2.reduce(function (accumulator, currentValue) {
                    return (January2 = accumulator + currentValue);
                  });
                }
                if (incomingFebruary2.length > 0) {
                  incomingFebruary2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (February2 = accumulator + currentValue);
                  });
                }
                if (incomingMarch2.length > 0) {
                  incomingMarch2.reduce(function (accumulator, currentValue) {
                    return (March2 = accumulator + currentValue);
                  });
                }
                if (incomingApril2.length > 0) {
                  incomingApril2.reduce(function (accumulator, currentValue) {
                    return (April2 = accumulator + currentValue);
                  });
                }
                if (incomingMay2.length > 0) {
                  incomingMay2.reduce(function (accumulator, currentValue) {
                    return (May2 = accumulator + currentValue);
                  });
                }
                if (incomingJune2.length > 0) {
                  incomingJune2.reduce(function (accumulator, currentValue) {
                    return (June2 = accumulator + currentValue);
                  });
                }
                if (incomingJuly2.length > 0) {
                  incomingJuly2.reduce(function (accumulator, currentValue) {
                    return (July2 = accumulator + currentValue);
                  });
                }
                if (incomingAugust2.length > 0) {
                  incomingAugust2.reduce(function (accumulator, currentValue) {
                    return (August2 = accumulator + currentValue);
                  });
                }
                if (incomingSeptember2.length > 0) {
                  incomingSeptember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (September2 = accumulator + currentValue);
                  });
                }
                if (incomingOctomber2.length > 0) {
                  incomingOctomber2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (Octomber2 = accumulator + currentValue);
                  });
                }
                if (incomingNovember2.length > 0) {
                  incomingNovember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (November2 = accumulator + currentValue);
                  });
                }
                if (incomingDecember2.length > 0) {
                  incomingDecember2.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (December2 = accumulator + currentValue);
                  });
                }
                incomingData.push(
                  January,
                  February,
                  March,
                  April,
                  May,
                  June,
                  July,
                  August,
                  September,
                  Octomber,
                  November,
                  December
                );
                incomingData1.push(
                  January1,
                  February1,
                  March1,
                  April1,
                  May1,
                  June1,
                  July1,
                  August1,
                  September1,
                  Octomber1,
                  November1,
                  December1
                );
                incomingData2.push(
                  January2,
                  February2,
                  March2,
                  April2,
                  May2,
                  June2,
                  July2,
                  August2,
                  September2,
                  Octomber2,
                  November2,
                  December2
                );
                stateCopy.data.datasets = stateCopy.data.datasets.slice();
                stateCopy.data.datasets[0] = Object.assign(
                  {},
                  stateCopy.data.datasets[0]
                );
                stateCopy.data.datasets[0].data = incomingData;
                stateCopy.data.datasets[0].label = this.state.selectedLegalName[0].label;
                this.setState(stateCopy);

                stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
                stateCopyTwo.data.datasets[1] = Object.assign(
                  {},
                  stateCopyTwo.data.datasets[1]
                );
                stateCopyTwo.data.datasets[1].data = incomingData1;
                stateCopyTwo.data.datasets[1].label = this.state.selectedLegalName[1].label;
                this.setState(stateCopyTwo);

                stateCopyThree.data.datasets = stateCopyThree.data.datasets.slice();
                stateCopyThree.data.datasets[2] = Object.assign(
                  {},
                  stateCopyThree.data.datasets[2]
                );
                stateCopyThree.data.datasets[2].data = incomingData2;
                stateCopyThree.data.datasets[2].label = this.state.selectedLegalName[2].label;
                this.setState(stateCopyThree);
                this.setState((prevState) => ({
                  data: {
                    ...prevState.data,
                    labels: incomingLabels,
                  },
                }));
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      } catch (error) {
        console.log("ERROR", error);
      }
    }
  };
  handleChange = async (selectedLegalName) => {
    let value = selectedLegalName;
    // await this.setChart();

    if (value === null) {
      this.setState({ selectedLegalName: "" }, this.setChart);
    } else {
      this.setState({ selectedLegalName: value }, this.setChart);
    }
  };
  handleChangeYear = async (event) => {
    this.setState({ valueYear: event.target.value, loading: true });
    await this.props.getCompositeAnalytics(this.state.valueYear);
    this.setState({ loading: false });
  };

  handleChangeSecondary = async (event) => {
    await this.setState({ secondaryValue: event.target.value });
    this.loadChart(this.state.secondaryValue);
  };

  setChart = (event) => {
    var stateClear = Object.assign({}, this.state);
    // event.preventDefault();
    if (
      this.state.selectedLegalName.length !== 0 &&
      this.state.selectedLegalName != null
    ) {
      stateClear.data.datasets = stateClear.data.datasets.slice();
      for (let i = 0; i < stateClear.data.datasets.length; i++) {
        stateClear.data.datasets[i] = Object.assign(
          {},
          stateClear.data.datasets[i]
        );
        stateClear.data.datasets[i].data = [];
        stateClear.data.datasets[i].label = `${i}`;
      }
      this.setState(stateClear);
    }
    this.loadChart(this.state.secondaryValue);
  };
  yearsArray = () => {
    let years = [];
    let startYear = "2001";

    let currentYear = moment().format("YYYY");

    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    this.setState({ years: years });
  };
  chooseLegalName = () => {
    let clientNameArray = [];
    let multiClientNameArrayOptions = [];
    this.props.compositeAnalytics.forEach((element) => {
      if (clientNameArray.includes(element.legalName) === false) {
        clientNameArray.push(element.legalName);
      }
    });
    clientNameArray.forEach((item) => {
      multiClientNameArrayOptions.push({
        label: item,
        value: item,
      });
    });
    this.setState({ multiClientNameArrayOptions: multiClientNameArrayOptions });
  };

  render() {
    const {
      legalNameChoiseArray,
      valueYear,
      secondaryValue,
      multiClientNameArrayOptions,
      selectedLegalName,
      years,
      loading,
    } = this.state;

    if (this.props.compositeAnalytics !== null && loading === false) {
      return (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <div>Διάλεξε Χρονιά</div>
              </div>
              <div className="col-8">
                <select
                  onChange={this.handleChangeYear}
                  value={valueYear}
                  data-test="org-reg-select"
                  className="custom-select"
                  id="composite-analytics-select"
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-4">Διάλεξε έως 3 πελάτες</div>
              <div className="col-8">
                <Select
                  options={
                    this.state.selectedLegalName.length >= 3
                      ? this.state.selectedLegalName
                      : multiClientNameArrayOptions
                  }
                  isMulti
                  onChange={this.handleChange}
                  value={selectedLegalName}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">Διάλεξε Στοιχείο Σύγκρισης</div>
              <div className="col-8">
                <select
                  onChange={this.handleChangeSecondary}
                  value={secondaryValue}
                  data-test="org-reg-select"
                  className="custom-select custom-select-sm"
                  id="composite-analytics-select"
                >
                  {legalNameChoiseArray.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              {/* <button
                type="button"
                className="btn btn-primary"
                id="analytics-button"
                onClick={this.setChart}
              >
                Set Chart
              </button> */}
            </div>
            {this.state.secondaryValue === "estHours" ? (
              <Bar
                data={this.state.data}
                options={{
                  title: {
                    display: true,
                    text: "Εκτιμώμενος Χρόνος Παραγωγής",
                    fontSize: "30",
                    fontFamily: "Nunito Sans, SemiBold",
                    fontColor: "#585A5A",
                  },
                  legend: { display: false, position: "bottom" },
                }}
              />
            ) : null}
            {this.state.secondaryValue === "qtyProduced" ? (
              <Bar
                data={this.state.data}
                options={{
                  title: {
                    display: true,
                    text: "Ποσότητα Ετικετών",
                    fontSize: "30",
                    fontFamily: "Nunito Sans, SemiBold",
                    fontColor: "#585A5A",
                  },
                  legend: { display: false, position: "bottom" },
                }}
              />
            ) : null}
            {this.state.secondaryValue === "dmPercentage" ? (
              <Bar
                data={this.state.data}
                options={{
                  title: {
                    display: true,
                    text: "Ποσοστό Διαφοράς (Β)-(Α)",
                    fontSize: "30",
                    fontFamily: "Nunito Sans, SemiBold",
                    fontColor: "#585A5A",
                  },
                  legend: { display: false, position: "bottom" },
                }}
              />
            ) : null}
            {this.state.secondaryValue === "m2" ? (
              <Bar
                data={this.state.data}
                options={{
                  title: {
                    display: true,
                    text: "Πραγματικά Τετραγωνικά Μέτρα Εκτύπωσης",
                    fontSize: "30",
                    fontFamily: "Nunito Sans, SemiBold",
                    fontColor: "#585A5A",
                  },
                  legend: { display: false, position: "bottom" },
                }}
              />
            ) : null}
            {this.state.secondaryValue === "materialCost" ? (
              <Bar
                data={this.state.data}
                options={{
                  title: {
                    display: true,
                    text: "Συνολική Αξία Υλικών",
                    fontSize: "30",
                    fontFamily: "Nunito Sans, SemiBold",
                    fontColor: "#585A5A",
                  },
                  legend: { display: false, position: "bottom" },
                }}
              />
            ) : null}
            {this.state.secondaryValue === "turnover" ? (
              <Bar
                data={this.state.data}
                options={{
                  title: {
                    display: true,
                    text: "Τζίρος",
                    fontSize: "30",
                    fontFamily: "Nunito Sans, SemiBold",
                    fontColor: "#585A5A",
                  },
                  legend: { display: false, position: "bottom" },
                }}
              />
            ) : null}
            {this.state.secondaryValue === "mPercentage" ? (
              <Bar
                data={this.state.data}
                options={{
                  title: {
                    display: true,
                    text: "%ΜΠΚ",
                    fontSize: "30",
                    fontFamily: "Nunito Sans, SemiBold",
                    fontColor: "#585A5A",
                  },
                  legend: { display: false, position: "bottom" },
                }}
              />
            ) : null}
            {this.state.secondaryValue === "loss" ? (
              <Bar
                data={this.state.data}
                options={{
                  title: {
                    display: true,
                    text: "Φύρα",
                    fontSize: "30",
                    fontFamily: "Nunito Sans, SemiBold",
                    fontColor: "#585A5A",
                  },
                  legend: { display: false, position: "bottom" },
                }}
              />
            ) : null}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div id="spinner-div">
          <Spinner />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  compositeAnalytics: state.analytics.compositeAnalytics,
  errors: state.errors,
});
export default connect(mapStateToProps, { getCompositeAnalytics })(
  CompositeAnalytics
);
