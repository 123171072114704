import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import { getContactTypes } from "../../../Redux/actions/Analytics/analyticsActions";

class ContactTypesChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#0F7BB4", " #435791"],
            hoverBackgroundColor: ["#0F7BB4", " #435791"],
            label: "Main Contact",
          },
          {
            data: [],
            backgroundColor: ["#EE8512", "#F14127"],
            hoverBackgroundColor: ["#EE8512", "#F14127"],
            label: "Not Main Contact",
          },
        ],
      },
    };
  }

  componentDidMount = () => {
    let incomingFandT = 0;
    let incomingBandT = 0;
    let incomingFandF = 0;
    let incomingBandF = 0;
    if (this.props.contactTypes !== null) {
      this.props.contactTypes.forEach((element) => {
        if (
          element.contactTypeId === "Friendly Relations" &&
          element.isMainContact === true
        ) {
          incomingFandT++;
        } else if (
          element.contactTypeId === "Friendly Relations" &&
          element.isMainContact === false
        ) {
          incomingFandF++;
        } else if (
          element.contactTypeId === "Business Relations" &&
          element.isMainContact === true
        ) {
          incomingBandT++;
        } else if (
          element.contactTypeId === "Business Relations" &&
          element.isMainContact === false
        ) {
          incomingBandF++;
        }
      });

      let dataTestOne;
      let dataTestTwo;

      dataTestOne = [incomingFandT, incomingBandT];
      dataTestTwo = [incomingFandF, incomingBandF];

      var stateCopy = Object.assign({}, this.state);
      stateCopy.data.datasets = stateCopy.data.datasets.slice();
      stateCopy.data.datasets[0] = Object.assign(
        {},
        stateCopy.data.datasets[0]
      );
      stateCopy.data.datasets[0].data = dataTestOne;
      this.setState(stateCopy);

      var stateCopyTwo = Object.assign({}, this.state);
      stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
      stateCopyTwo.data.datasets[1] = Object.assign(
        {},
        stateCopyTwo.data.datasets[1]
      );
      stateCopyTwo.data.datasets[1].data = dataTestTwo;
      this.setState(stateCopyTwo);
      // console.log(stateCopyTwo)

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          labels: ["Friendly Relations", "Business Relations"],
        },
      }));
    }
  };

  render() {
    if (this.props.contactTypes !== null) {
      return (
        <React.Fragment>
          <Bar
            data={this.state.data}
            options={{
              title: {
                display: true,
                text: "Contact Types",
                fontSize: "30",
                fontFamily: "Nunito Sans, SemiBold",
                fontColor: "#585A5A",
              },
              legend: {
                display: false,
                position: "bottom",
              },
            }}
          />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  contactTypes: state.analytics.contactTypes,
  errors: state.errors,
});
export default connect(mapStateToProps, { getContactTypes })(ContactTypesChart);
