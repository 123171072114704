import { backInstance } from "../../../config";
import {
  GET_MATERIAL_COSTS_PER_COLOR,
  GET_ERRORS,
  GET_COLORS_PER_ORDER,
  GET_EMPLOYEES,
  GET_CONTACT_TYPES,
  GET_ORDERS_PER_MONTH,
  GET_PRINTED_LABELS_PER_COLOR,
  GET_PRINTED_LABELS_PER_COLOR_FROM_YEAR,
  GET_PRINTED_LABELS_PER_COLOR_EXISTING_YEARS,
  GET_ORDERS_PER_MONTH_EXISTING_YEARS,
  GET_MATERIAL_WASTAGE_PER_YEAR,
  GET_MATERIAL_WASTAGE_PER_YEAR_EXISTING,
  GET_TURNOVER_PER_QUARTER_EXISTING_YEARS,
  GET_TURNOVER_PER_QUARTER_FROM_YEAR,
  GET_COMPOSITE_ANALYTICS,
} from "../types";

export const getMaterialCosts = () => async (dispatch) => {
  try {
    const res = await backInstance.get("schedule/material-costs-per-color");
    dispatch({
      type: GET_MATERIAL_COSTS_PER_COLOR,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getColorsPerOrder = () => async (dispatch) => {
  try {
    const res = await backInstance.get("schedule/colors-per-order");
    dispatch({
      type: GET_COLORS_PER_ORDER,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getEmployees = () => async (dispatch) => {
  try {
    const res = await backInstance.get("schedule/employees");
    dispatch({
      type: GET_EMPLOYEES,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getContactTypes = () => async (dispatch) => {
  try {
    const res = await backInstance.get("schedule/contact-types");
    dispatch({
      type: GET_CONTACT_TYPES,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOrdersPerMonth = (year) => async (dispatch) => {
  try {
    const res = await backInstance.get(`schedule/orders/${year}`);
    dispatch({
      type: GET_ORDERS_PER_MONTH,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getPrintedLabelsPerColor = () => async (dispatch) => {
  try {
    const res = await backInstance.get("schedule/printed-labels-per-color");
    dispatch({
      type: GET_PRINTED_LABELS_PER_COLOR,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
export const getPrintedLabelsPerColorExistingYears = () => async (dispatch) => {
  try {
    const res = await backInstance.get(
      "schedule/printed-labels-per-color/existing-years"
    );
    dispatch({
      type: GET_PRINTED_LABELS_PER_COLOR_EXISTING_YEARS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
export const getPrintedLabelsPerColorFromYear = (year) => async (dispatch) => {
  try {
    const res = await backInstance.get(
      `schedule/printed-labels-per-color-from-year/${year}`
    );
    dispatch({
      type: GET_PRINTED_LABELS_PER_COLOR_FROM_YEAR,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOrdersPerMonthExistingYears = () => async (dispatch) => {
  try {
    const res = await backInstance.get("schedule/orders/existing-years");
    dispatch({
      type: GET_ORDERS_PER_MONTH_EXISTING_YEARS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getMaterialWastage = (year) => async (dispatch) => {
  try {
    const res = await backInstance.get(
      `schedule/material-wastage-per-machine/${year}`
    );
    dispatch({
      type: GET_MATERIAL_WASTAGE_PER_YEAR,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getMaterialWastageYears = () => async (dispatch) => {
  try {
    const res = await backInstance.get(
      "schedule/material-wastage-per-machine/existing-years"
    );
    dispatch({
      type: GET_MATERIAL_WASTAGE_PER_YEAR_EXISTING,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getTurnoverPerQuarterFromYear = (year) => async (dispatch) => {
  try {
    const res = await backInstance.get(
      `schedule/turnover-per-quarter-from-year/${year}`
    );
    dispatch({
      type: GET_TURNOVER_PER_QUARTER_FROM_YEAR,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
export const getTurnoverPerQuarterExistingYears = () => async (dispatch) => {
  try {
    const res = await backInstance.get(
      "schedule/turnover-per-quarter/existing-years"
    );
    dispatch({
      type: GET_TURNOVER_PER_QUARTER_EXISTING_YEARS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
export const getCompositeAnalytics = (year) => async (dispatch) => {
  try {
    const res = await backInstance.get(`schedule/composite-analytics/${year}`);
    dispatch({
      type: GET_COMPOSITE_ANALYTICS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
