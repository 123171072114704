import React, { Component } from "react";
import SimulationTimetable from "./SimulationTimetable";
import { connect } from "react-redux";
import { backInstance } from "../../../config";
import {
  getCommands,
  setLoading,
} from "../../../Redux/actions/Scheduler/schedulerActions";
import Spinner from "../../../components/Spinner/Spinner";
import Xcircle from "../../../assets/x_circle.svg";

class Simulation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      resetSchedule: false,
    };
  }

  setCurrentSchedule = async () => {
    if (
      window.confirm("You are about to make the Simulation the main Schedule")
    ) {
      this.setState({ loading: true });
      let fixedOrders = { fixedPrintCommands: this.props.fixedOrdersArray };
      try {
        await backInstance.post("/schedule/fixed", fixedOrders);
      } catch (error) {
        console.log("Set current Schedule API error");
      }
      await this.props.getCommands();
      this.setState({ loading: false });
    }
  };

  resetCurrentSchedule = async () => {
    if (window.confirm("You are about to reset the Simulation")) {
      this.setState({ resetSchedule: !this.state.resetSchedule });
      try {
        await backInstance.get("/schedule/reset-fixed-dates");
      } catch (error) {
        console.log("Reset Schedule API error");
      }
    }
  };

  render() {
    return (
      <div className="container-fluid" id="simulation-container">
        <div className="row" id="simulation-row">
          {this.state.loading ? (
            <div className="col-12" id="simulation-content">
              <div id="dashboard-title">Simulation</div>
              <SimulationTimetable resetSchedule={this.state.resetSchedule} />
              <div className="row">
                <div className="col-6 simulation-button-wrapper">
                  <button
                    type="button"
                    id="button-simulate"
                    onClick={this.resetCurrentSchedule}
                  >
                    <img src={Xcircle} /> Reset Schedule
                  </button>
                </div>
                <div className="col-6 simulation-button-wrapper float-right">
                  <button
                    type="button"
                    id="button-set-current"
                    onClick={this.setCurrentSchedule}
                  >
                    Set Current Schedule
                  </button>
                </div>
                <div id="spinner-div-simulation">
                  <Spinner />
                </div>
              </div>
            </div>
          ) : (
            <div className="col-12" id="simulation-content">
              <div id="dashboard-title">Simulation</div>
              <SimulationTimetable resetSchedule={this.state.resetSchedule} />
              <div className="row">
                <div className="col-6 simulation-button-wrapper">
                  <button
                    type="button"
                    id="button-simulate"
                    onClick={this.resetCurrentSchedule}
                  >
                    <img src={Xcircle} />
                    Reset Schedule
                  </button>
                </div>
                <div className="col-6 simulation-button-wrapper float-right">
                  <button
                    type="button"
                    id="button-set-current"
                    onClick={this.setCurrentSchedule}
                  >
                    Set Current Schedule
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fixedOrdersArray: state.scheduler.fixedOrdersArray,
  loading: state.loading.loading,
});

export default connect(mapStateToProps, { getCommands, setLoading })(
  Simulation
);
