import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import SecuredRoute from "../src/securityUtils/SecureRoute";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

import Login from "./containers/Authentication/Login";
import Logout from "./containers/Authentication/Logout";

// Admin Routes
import AdminDashboard from "./containers/Admin/Dashboard";
import Simulation from "./containers/Scheduler/Simulation/Simulation";
import Notifications from "./containers/Scheduler/Notifications/Notifications";
import NewAnalytics from "./containers/Scheduler/Analytics/NewAnalytics";

class Routes extends Component {
  render() {
    // Expose the paths that correspond to each user role
    let exposedUrls;
    if (this.props.security.validToken) {
      const token = localStorage.getItem("jwtToken");
      const decoded = jwt_decode(token);
      let role = decoded.role[0].authority;

      switch (role) {
        case "ROLE_ADMIN":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={AdminDashboard} />
              <SecuredRoute exact path="/Logout" component={Logout} />
              <SecuredRoute exact path="/Simulation" component={Simulation} />
              <SecuredRoute exact path="/Analytics" component={NewAnalytics} />
              <SecuredRoute
                exact
                path="/Notifications"
                component={Notifications}
              />
            </Switch>
          );
          break;
        case "ROLE_ADMIN_OPERATIONAL":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={AdminDashboard} />
              <SecuredRoute exact path="/Logout" component={Logout} />
              <SecuredRoute exact path="/Simulation" component={Simulation} />
              <SecuredRoute exact path="/Analytics" component={NewAnalytics} />
              <SecuredRoute
                exact
                path="/Notifications"
                component={Notifications}
              />
            </Switch>
          );
          break;
        case "ROLE_ADMIN_BUSINESS":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={AdminDashboard} />
              <SecuredRoute exact path="/Logout" component={Logout} />
              <SecuredRoute exact path="/Simulation" component={Simulation} />
              <SecuredRoute exact path="/Analytics" component={NewAnalytics} />
              <SecuredRoute
                exact
                path="/Notifications"
                component={Notifications}
              />
            </Switch>
          );
          break;
        case "ROLE_CUSTOMER_SERVICE":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={AdminDashboard} />
              <SecuredRoute exact path="/Logout" component={Logout} />
            </Switch>
          );
          break;
        case "ROLE_PRE_PRESS":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={AdminDashboard} />
              <SecuredRoute exact path="/Logout" component={Logout} />
            </Switch>
          );
          break;
        case "ROLE_QUALITY_CONTROL":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={AdminDashboard} />
              <SecuredRoute exact path="/Logout" component={Logout} />
              <SecuredRoute
                exact
                path="/Notifications"
                component={Notifications}
              />
            </Switch>
          );
          break;
        case "ROLE_PROCUREMENT":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={AdminDashboard} />
              <SecuredRoute exact path="/Logout" component={Logout} />
              <SecuredRoute exact path="/Analytics" component={NewAnalytics} />
            </Switch>
          );
          break;
        default:
          break;
      }
    } else {
      exposedUrls = <Route component={Login} />;
    }

    return (
      <div>
        <Switch>
          {
            // Public Routes
          }
          {/* <Route exact path="/Login" component={Login} /> */}
          {exposedUrls}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, null)(Routes);
