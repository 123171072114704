import React from "react";
import store from "../../Redux/store";
import moment from "moment";

//blue
let styleHasNotStarted = {
  background:
    "transparent linear-gradient(270deg, #59D7F8 0%, #0F7BB4 100%) 0% 0% no-repeat padding-box",
};

//red
let styleDeadline = {
  background:
    "transparent linear-gradient(270deg, #FB9C79 0%, #EE2912 100%) 0% 0% no-repeat padding-box",
};

//orange
let styleLargeOrder = {
  background:
    "transparent linear-gradient(270deg, #FBBE79 0%, #EE8512 100%) 0% 0% no-repeat padding-box",
};

//green
let styleNewOrder = {
  background:
    "transparent linear-gradient(270deg, #83DEAD 0%, #04AA98 100%) 0% 0% no-repeat padding-box",
};

//purple
let styleFixedOrder = {
  background: `linear-gradient(270deg, #5A78D2 0%, #4A61A4 70%, #435791 100%) 0% 0% no-repeat padding-box`,
};

export const eventPopOver = (schedulerData, eventItem, title, start, end, statusColor) => {
  let commandInfo = store
    .getState()
    .scheduler.schedulerCommands.filter((command) => command.id === eventItem.id)[0];

  let deadlineObject = moment(commandInfo.commandDeadlineDate, "YYYY-MM-DD").format("DD MMM YYYY");

  let backgroundStyle;
  let divStyle;
  let CommandDate = moment(commandInfo.start).format("YYYY-MM-DD");
  let DeadlineDate = moment(commandInfo.commandDeadlineDate).format("YYYY-MM-DD");
  if (commandInfo.fixedDate !== null) {
    backgroundStyle = `${styleFixedOrder.background}`;
    divStyle = { background: backgroundStyle };
  } else if (commandInfo.printProperties.totalRequiredMeters >= 30000 && commandInfo.resourceId === "MULLER_MARTINI") {
    backgroundStyle = `${styleLargeOrder.background}`;
    divStyle = { background: backgroundStyle };
  } else if (commandInfo.printProperties.totalRequiredMeters >= 5000 && commandInfo.resourceId !== "MULLER_MARTINI") {
    backgroundStyle = `${styleLargeOrder.background}`;
    divStyle = { background: backgroundStyle };
  } else if (CommandDate >= DeadlineDate) {
    backgroundStyle = `${styleDeadline.background}`;
    divStyle = { background: backgroundStyle };
  } else if (commandInfo.printProperties.isReprint === false) {
    backgroundStyle = `${styleNewOrder.background}`;
    divStyle = { background: backgroundStyle };
  } else {
    backgroundStyle = `${styleHasNotStarted.background}`;
    divStyle = { background: backgroundStyle };
  }

  return (
    <div className="container" id="popover-container">
      <div className="row" id="popover-header" style={divStyle}>
        <div className="col">
          {commandInfo.id} - {commandInfo.title}
        </div>
      </div>
      <div className="row popover-inner" id="popover-time">
        <div className="col">
          {start.format("HH:mm")} <span className="month-day-start">{start.format("MMM DD")}</span>-
          {end.format("HH:mm")} <span className="month-day-start">{end.format("MMM DD")}</span>
        </div>
      </div>
      <div className="row popover-inner">
        <div className="col">Total Time: {commandInfo.totalTime} hours</div>
      </div>
      <div className="row popover-inner">
        <div className="col">Quantity: {commandInfo.printProperties.quantity} pieces</div>
      </div>
      <div className="row popover-inner">
        <div className="col">Meters: {commandInfo.printProperties.totalRequiredMeters} m</div>
      </div>
      <div className="row" id="popover-deadline">
        <div className="col">Deadline: {deadlineObject}</div>
      </div>
    </div>
  );
};

export const cuttingEventPopOver = (schedulerData, eventItem, title, start, end, statusColor) => {
  let commandInfo = store
    .getState()
    .scheduler.cuttingCommands.filter((command) => command.id === eventItem.id)[0];
  let deadlineObject;
  if (commandInfo.commandDeadlineDate !== null) {
    deadlineObject = moment(commandInfo.commandDeadlineDate, "YYYY-MM-DD").format("DD MMM YYYY");
  }
  return (
    <div className="container" id="popover-container">
      <div className="row" id="popover-header">
        <div className="col">
          {commandInfo.id} - {commandInfo.title}
        </div>
      </div>
      <div className="row popover-inner" id="popover-time">
        <div className="col">
          {start.format("HH:mm")} <span className="month-day-start">{start.format("MMM DD")}</span>-
          {end.format("HH:mm")} <span className="month-day-start">{end.format("MMM DD")}</span>
        </div>
      </div>
      <div className="row" id="popover-deadline">
        <div className="col">Deadline: {deadlineObject}</div>
      </div>
    </div>
  );
};
