import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/Redux/store";

import "./Sass/main.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { saveState } from './localStorage';


import App from "./App";
import * as serviceWorker from "./serviceWorker";


store.subscribe(() => {
  saveState(store.getState());
});

const app = (
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
