import { ViewTypes, SummaryPos } from "react-big-scheduler";

const SimulationConfig = {
  schedulerWidth: "75%",
  besidesWidth: 20,
  schedulerMaxHeight: 0,
  tableHeaderHeight: 60,

  agendaResourceTableWidth: 160,
  agendaMaxEventWidth: 100,

  dayResourceTableWidth: 170,
  weekResourceTableWidth: "8%",
  monthResourceTableWidth: 170,
  quarterResourceTableWidth: 170,
  yearResourceTableWidth: 170,
  customResourceTableWidth: 170,

  dayCellWidth: 60,
  weekCellWidth: "10%",
  monthCellWidth: 60,
  quarterCellWidth: 60,
  yearCellWidth: 60,
  customCellWidth: 140,

  dayMaxEvents: 99,
  weekMaxEvents: 99,
  monthMaxEvents: 99,
  quarterMaxEvents: 99,
  yearMaxEvents: 99,
  customMaxEvents: 99,

  eventItemHeight: 25,
  eventItemLineHeight: 40,
  nonAgendaSlotMinHeight: 59,
  dayStartFrom: 0,
  dayStopTo: 23,
  // defaultEventBgColor: "#e626ff",
  selectedAreaColor: "#7EC2F3",
  nonWorkingTimeHeadColor: "#999999",
  nonWorkingTimeHeadBgColor: "#fffff",
  nonWorkingTimeBodyBgColor: "#fffff",
  summaryColor: "#666",
  summaryPos: SummaryPos.TopRight,
  groupOnlySlotColor: "#F8F8F8",

  startResizable: false,
  endResizable: false,
  movable: true,
  creatable: false,
  crossResourceMove: false,
  checkConflict: false,
  scrollToSpecialMomentEnabled: false,
  eventItemPopoverEnabled: true,
  calendarPopoverEnabled: false,
  recurringEventsEnabled: false,
  headerEnabled: true,
  displayWeekend: true,
  relativeMove: true,
  defaultExpanded: true,

  resourceName: "Machines",
  taskName: "Task Name",
  agendaViewHeader: "Agenda",
  addMorePopoverHeaderFormat: "MMM D, YYYY dddd",
  eventItemPopoverDateFormat: "MMM D",
  nonAgendaDayCellHeaderFormat: "ha",
  nonAgendaOtherCellHeaderFormat: "ddd M/D",

  minuteStep: 30,

  views: [
    // {
    //   viewName: "Add Overtimes",
    //   viewType: ViewTypes.Day,
    //   showAgenda: false,
    //   isEventPerspective: false
    // },
    // {
    //   viewName: "Week",
    //   viewType: ViewTypes.Week,
    //   showAgenda: false,
    //   isEventPerspective: false
    // },
    // {
    //   viewName: "Month",
    //   viewType: ViewTypes.Month,
    //   showAgenda: false,
    //   isEventPerspective: false
    // },
    // {
    //   viewName: "Two days",
    //   viewType: ViewTypes.Custom,
    //   showAgenda: false,
    //   isEventPerspective: false
    // },
    // {
    //   viewName: "Add Overtimes",
    //   viewType: ViewTypes.Custom2,
    //   showAgenda: false,
    //   isEventPerspective: false
    // },
    {
      viewName: "Two weeks",
      viewType: ViewTypes.Custom1,
      showAgenda: false,
      isEventPerspective: false
    }
  ]
};

export default SimulationConfig;
