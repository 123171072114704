import React, { Component } from "react";
import "react-big-scheduler/lib/css/style.css";

import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
  CellUnits,
  AddMorePopover,
} from "react-big-scheduler";
import withDragDropContext from "../withDnDContext";
import moment from "moment";
import SimulationConfig from "./SimulationConfig";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import { getOvertimes } from "../../../Redux/actions/Overtimes/overtimeActions";
import {
  getCommands,
  setMachineView,
  getSimulationCommands,
  setFixedOrdersArray,
} from "../../../Redux/actions/Scheduler/schedulerActions";
import Spinner from "../../../components/Spinner/Spinner";
import CommandModal from "../../../components/Modals/CommandModal";
import { eventPopOver } from "../EventPopOver";
import OvertimeModal from "../../../components/Modals/OvertimeModal";
import { Button, DropdownButton, Dropdown, Image } from "react-bootstrap";
import RefreshImage from "../../../assets/refresh.svg";
import ClockImage from "../../../assets/clock.svg";

let schedulerData;

let hasShortageCss = "";
let styleHasNotStarted = "";
let styleDeadline = "";
let styleLargeOrder = "";
let styleNewOrder = "";
let styleFixedOrder = "";

const getEventCssStyle = (hasShortage) => {
  if (hasShortage) {
    hasShortageCss =
      "repeating-linear-gradient(60deg,transparent 1px,transparent 8px,rgba(255, 255, 255, 0.5) 8px ,rgba(255, 255, 255,0.5) 10px),";
  } else {
    hasShortageCss = "";
  }
  styleHasNotStarted = {
    background: `${hasShortageCss} linear-gradient(270deg, #59D7F8 0%, #0F7BB4 100%) 0% 0% no-repeat padding-box`,
  };
  styleDeadline = {
    background: `${hasShortageCss} linear-gradient(270deg, #FB9C79 0%, #EE2912 100%) 0% 0% no-repeat padding-box`,
  };
  styleLargeOrder = {
    background: `${hasShortageCss} linear-gradient(270deg, #FBBE79 0%, #EE8512 100%) 0% 0% no-repeat padding-box`,
  };
  styleNewOrder = {
    background: `${hasShortageCss} linear-gradient(270deg, #83DEAD 0%, #04AA98 100%) 0% 0% no-repeat padding-box`,
  };
  styleFixedOrder = {
    background: `${hasShortageCss} linear-gradient(270deg, #5A78D2 0%, #4A61A4 70%, #435791 100%) 0% 0% no-repeat padding-box`,
  };
};

class SimulationTimetable extends Component {
  constructor(props) {
    super(props);
    schedulerData = new SchedulerData(
      moment().format(DATE_FORMAT),
      ViewTypes.Custom1,
      false,
      false,
      SimulationConfig,
      {
        getCustomDateFunc: this.getCustomDate,
        getSummaryFunc: this.getSummary,
      }
    );
    schedulerData.localeMoment.locale("en");
    schedulerData.setResources([
      {
        id: this.props.machineViewId,
        name: this.props.machineViewName,
      },
    ]);

    this.state = {
      viewModel: schedulerData,
      events: [],
      loading: false,
      modalShow: false,
      modalCommand: null,
      OvertimeModalShow: false,
    };

    this.eventPopOver = eventPopOver.bind(this);
  }

  fixedOrders = [];

  // Set the color scheme of each event
  eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    let CommandDate = moment(event.start).format("YYYY-MM-DD");
    let DeadlineDate = moment(event.commandDeadlineDate).format("YYYY-MM-DD");
    let titleText;
    if (event.fixedDate !== null) {
      titleText = (
        <span
          style={{
            marginLeft: "1px",
            lineHeight: `${mustBeHeight}px`,
          }}
        >
          <FontAwesomeIcon icon={faLock} size="sm" className="fontawesome-timetable" />
          {schedulerData.behaviors.getEventTextFunc(schedulerData, event)}
        </span>
      );
    } else {
      titleText = (
        <span
          style={{
            marginLeft: "1px",
            lineHeight: `${mustBeHeight}px`,
          }}
        >
          {schedulerData.behaviors.getEventTextFunc(schedulerData, event)}
        </span>
      );
    }
    if (event.hasShortage) {
      getEventCssStyle(true);
    } else {
      getEventCssStyle(false);
    }
    switch (event.resourceId) {
      case "GALLUS_1":
        if (event.fixedDate !== null) {
          let backgroundStyle = `${styleFixedOrder.background}`;
          let divStyle = { background: backgroundStyle };

          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.totalRequiredMeters >= 5000) {
          let backgroundStyle = `${styleLargeOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (CommandDate >= DeadlineDate) {
          let backgroundStyle = `${styleDeadline.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.reprint === false) {
          let backgroundStyle = `${styleNewOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else {
          let backgroundStyle = `${styleHasNotStarted.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        }
      case "GALLUS_2":
        if (event.fixedDate !== null) {
          let backgroundStyle = `${styleFixedOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.totalRequiredMeters >= 5000) {
          let backgroundStyle = `${styleLargeOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (CommandDate >= DeadlineDate) {
          let backgroundStyle = `${styleDeadline.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.reprint === false) {
          let backgroundStyle = `${styleNewOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else {
          let backgroundStyle = `${styleHasNotStarted.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        }
      case "KOPACK":
        if (event.fixedDate !== null) {
          let backgroundStyle = `${styleFixedOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.totalRequiredMeters >= 5000) {
          let backgroundStyle = `${styleLargeOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (CommandDate >= DeadlineDate) {
          let backgroundStyle = `${styleDeadline.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.reprint === false) {
          let backgroundStyle = `${styleNewOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else {
          let backgroundStyle = `${styleHasNotStarted.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        }
      case "MULLER_MARTINI":
        if (event.fixedDate !== null) {
          let backgroundStyle = `${styleFixedOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.totalRequiredMeters >= 30000) {
          let backgroundStyle = `${styleLargeOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (CommandDate >= DeadlineDate) {
          let backgroundStyle = `${styleDeadline.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.reprint === false) {
          let backgroundStyle = `${styleNewOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else {
          let backgroundStyle = `${styleHasNotStarted.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        }
      case "DIGITAL_HP6000":
        if (event.fixedDate !== null) {
          let backgroundStyle = `${styleFixedOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.totalRequiredMeters >= 5000) {
          let backgroundStyle = `${styleLargeOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (CommandDate >= DeadlineDate) {
          let backgroundStyle = `${styleDeadline.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else if (event.reprint === false) {
          let backgroundStyle = `${styleNewOrder.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        } else {
          let backgroundStyle = `${styleHasNotStarted.background}`;
          let divStyle = { background: backgroundStyle };
          if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
          return (
            <div key={event.id} className={mustAddCssClass} style={divStyle}>
              {titleText}
            </div>
          );
        }
      default:
        break;
    }
  };

  setScheduleFromApi = async () => {
    try {
      this.setState({ loading: true });
      await this.props.getCommands();
      let updatedEvents = [];
      for (let i = 0; i < this.props.schedulerCommands.length; i++) {
        updatedEvents[i] = {
          id: this.props.schedulerCommands[i].id,
          start: this.props.schedulerCommands[i].start,
          end: this.props.schedulerCommands[i].end,
          resourceId: this.props.schedulerCommands[i].resourceId,
          title: this.props.schedulerCommands[i].id + " - " + this.props.schedulerCommands[i].title,
          commandDeadlineDate: this.props.schedulerCommands[i].commandDeadlineDate,
          totalRequiredMeters: this.props.schedulerCommands[i].printProperties.totalRequiredMeters,
          reprint: this.props.schedulerCommands[i].printProperties.isReprint,
          fixedDate: this.props.schedulerCommands[i].fixedDate,
          hasShortage: this.props.schedulerCommands[i].hasShortage,
        };
      }
      updatedEvents.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
      });
      return updatedEvents;
    } catch (error) {
      console.log("Get computed commands API error");
    }
  };

  setScheduleFromState = () => {
    let updatedEvents = [];
    for (let i = 0; i < this.props.schedulerCommands.length; i++) {
      updatedEvents[i] = {
        id: this.props.schedulerCommands[i].id,
        start: this.props.schedulerCommands[i].start,
        end: this.props.schedulerCommands[i].end,
        resourceId: this.props.schedulerCommands[i].resourceId,
        title: this.props.schedulerCommands[i].id + " - " + this.props.schedulerCommands[i].title,
        commandDeadlineDate: this.props.schedulerCommands[i].commandDeadlineDate,
        totalRequiredMeters: this.props.schedulerCommands[i].printProperties.totalRequiredMeters,
        reprint: this.props.schedulerCommands[i].printProperties.isReprint,
        fixedDate: this.props.schedulerCommands[i].fixedDate,
        hasShortage: this.props.schedulerCommands[i].hasShortage,
      };
    }
    updatedEvents.sort(function (a, b) {
      return new Date(a.start) - new Date(b.start);
    });
    return updatedEvents;
  };

  setSimulationSchedule = async () => {
    try {
      this.setState({ loading: true });
      await this.props.getSimulationCommands(this.fixedOrders);
      let updatedEvents = [];
      for (let i = 0; i < this.props.simulationCommands.length; i++) {
        updatedEvents[i] = {
          id: this.props.simulationCommands[i].id,
          start: this.props.simulationCommands[i].start,
          end: this.props.simulationCommands[i].end,
          resourceId: this.props.simulationCommands[i].resourceId,
          title:
            this.props.simulationCommands[i].id + " - " + this.props.simulationCommands[i].title,
          commandDeadlineDate: this.props.simulationCommands[i].commandDeadlineDate,
          totalRequiredMeters: this.props.simulationCommands[i].printProperties.totalRequiredMeters,
          reprint: this.props.simulationCommands[i].printProperties.isReprint,
          fixedDate: this.props.simulationCommands[i].fixedDate,
        };
      }
      updatedEvents.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
      });
      return updatedEvents;
    } catch (error) {
      console.log("Get computed commands API error");
    }
  };

  getTimetable = async (refresh = false) => {
    let updatedEvents = [];
    if (this.fixedOrders.length === 0) {
      this.props.setFixedOrdersArray(null);
      if (this.props.schedulerCommands === null || refresh === true) {
        updatedEvents = await this.setScheduleFromApi();
      } else {
        updatedEvents = await this.setScheduleFromState();
      }
    } else {
      this.props.setFixedOrdersArray(this.fixedOrders);
      updatedEvents = await this.setSimulationSchedule();
    }

    this.setState({ events: updatedEvents });

    schedulerData = new SchedulerData(
      moment().format(DATE_FORMAT),
      ViewTypes.Custom1,
      false,
      false,
      SimulationConfig,
      {
        getCustomDateFunc: this.getCustomDate,
        getSummaryFunc: this.getSummary,
      }
    );
    schedulerData.localeMoment.locale("en");
    schedulerData.setResources([
      {
        id: this.props.machineViewId,
        name: this.props.machineViewName,
      },
    ]);
    schedulerData.setEvents(this.state.events);
    this.setState({ viewModel: schedulerData, loading: false });
  };

  componentDidMount = async () => {
    this.props.getOvertimes();
    this.getTimetable();
  };

  componentDidUpdate(prevProps) {
    if (this.props.resetSchedule !== prevProps.resetSchedule) {
      this.getTimetable(true);
    }
  }

  prevClick = (schedulerData) => {};

  nextClick = (schedulerData) => {};

  onViewChange = (schedulerData, view) => {};

  onSelectDate = (schedulerData, date) => {};

  eventClicked = (schedulerData, event) => {
    this.setState({
      modalShow: true,
      modalCommand: this.props.schedulerCommands.filter((command) => command.id === event.id)[0],
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {};

  updateEventStart = (schedulerData, event, newStart) => {};

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {};

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    let startDate = start.split(" ")[0];
    if (
      window.confirm(
        `Do you want to have the order with id: ${event.title} start on ${startDate} with ${event.reprint} and ${event.id}`
      )
    ) {
      if (!this.fixedOrders.some((order) => order.commandId === event.id))
        this.fixedOrders.push({
          commandId: event.id,
          fixedDate: startDate,
        });
      this.getTimetable();
    }
    console.log("event: ", event, "Date: ", startDate, "Array: ", this.fixedOrders);
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {};

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {};

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {};

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {};

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  getCustomDate = (schedulerData, num, date = undefined) => {
    const { viewType } = schedulerData;
    let selectDate = schedulerData.startDate;
    if (date !== undefined) selectDate = date;

    let startDate =
        num === 0
          ? selectDate
          : schedulerData
              .localeMoment(selectDate)
              .add(2 * num, "days")
              .format(DATE_FORMAT),
      endDate = schedulerData.localeMoment(startDate).add(1, "days").format(DATE_FORMAT),
      cellUnit = CellUnits.Hour;

    if (viewType === ViewTypes.Custom1) {
      let today = schedulerData.localeMoment(selectDate).format(DATE_FORMAT);
      startDate =
        num === 0
          ? today
          : schedulerData
              .localeMoment(today)
              .add(2 * num, "weeks")
              .format(DATE_FORMAT);
      endDate = schedulerData
        .localeMoment(startDate)
        .add(1, "weeks")
        .endOf("week")
        .format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    }
    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  getSummary = (schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) => {
    let text = headerEvents.length.toString();
    let color = "#d9d9d9";
    if (headerEvents.length > 0) {
      color = "#0F7BB4";
    }
    return { text: text, color: color, fontSize: "1.1vw" };
  };

  onMachineSelect = (name, e) => {
    setMachineView(name);
    schedulerData.setResources([
      {
        id: e.target.id,
        name: name,
      },
    ]);
  };

  refreshTimetable = () => {
    this.getTimetable(true);
    this.props.getOvertimes();
  };
  pataTo = () => {
    this.props.simulationCommands.forEach((element) => {
      if (element.printProperties.isReprint === false && element.resourceId === "GALLUS_1") {
        console.log("first Load", element);
      }
    });
  };
  pataPali = () => {
    this.state.events.forEach((event) => {
      if (event.reprint === false && event.resourceId === "GALLUS_1") {
        console.log("second Load", event);
      }
    });
  };
  nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
    let datetime = schedulerData.localeMoment(item.time);
    let machine = this.props.machineViewId;

    if (this.props.overtimes != null) {
      Object.entries(this.props.overtimes).forEach(([key, value]) => {
        switch (key) {
          case machine:
            if (value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (moment(datetime._i.split(" ")[0]).isSame(value[i].overtimeDate)) {
                  style.backgroundColor = "white";
                  style.color = "#585a5a";
                }
              }
            }

            break;

          default:
            break;
        }
      });
    }

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {formattedDateItems.map((formattedItem, index) => (
          <span
            key={index}
            dangerouslySetInnerHTML={{
              __html: formattedItem.replace(/[0-9]/g, "<b>$&</b>"),
            }}
          />
        ))}
        {style.color === "#585a5a" ? (
          <Image className="overtime-table-headers" src={ClockImage} fluid />
        ) : null}
      </th>
    );
  };

  commandModalOnHide = (changedShortage = false) => {
    if (changedShortage) {
      this.getTimetable(true);
    }
    this.setState({ modalShow: false });
  };

  render() {
    const { viewModel } = this.state;

    let popover = <div />;
    if (this.state.headerItem !== undefined) {
      popover = (
        <AddMorePopover
          headerItem={this.state.headerItem}
          eventItemClick={this.eventClicked}
          viewEventClick={this.ops1}
          viewEventText="Ops 1"
          viewEvent2Click={this.ops2}
          viewEvent2Text="Ops 2"
          schedulerData={viewModel}
          closeAction={this.onSetAddMoreState}
          left={this.state.left}
          top={this.state.top}
          height={this.state.height}
          moveEvent={this.moveEvent}
        />
      );
    }

    let machineSelector = (
      <DropdownButton
        id="dropdown-machine-button"
        title={this.props.machineViewName}
        onSelect={(e, id) => this.onMachineSelect(e, id)}
      >
        <Dropdown.Item className="machine-selector-item" key="1" id="GALLUS_1" eventKey="Gallus 1">
          Gallus 1
        </Dropdown.Item>
        <Dropdown.Item className="machine-selector-item" key="2" id="GALLUS_2" eventKey="Gallus 2">
          Gallus 2
        </Dropdown.Item>
        <Dropdown.Item className="machine-selector-item" key="3" id="KOPACK" eventKey="Kopack">
          Kopack
        </Dropdown.Item>
        <Dropdown.Item
          className="machine-selector-item"
          key="4"
          id="MULLER_MARTINI"
          eventKey="Muller Martini"
        >
          Muller Martini
        </Dropdown.Item>
        <Dropdown.Item
          className="machine-selector-item"
          key="5"
          id="DIGITAL_HP6000"
          eventKey="HP6000"
        >
          HP6000
        </Dropdown.Item>
      </DropdownButton>
    );

    let overtimeHeader = (
      <span>
        <span id="sim-right-header-span">
          <Button
            id="refresh-scheduler-button-simulate"
            className="btn btn-primary"
            onClick={this.refreshTimetable}
          >
            <span className="refresh-text">Refresh Schedule</span>
            <Image src={RefreshImage} fluid />
          </Button>
        </span>
      </span>
    );

    let mainView = (
      <div>
        <Scheduler
          schedulerData={viewModel}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          viewEventClick={this.ops1}
          viewEventText="Ops 1"
          viewEvent2Text="Ops 2"
          viewEvent2Click={this.ops2}
          updateEventStart={this.updateEventStart}
          updateEventEnd={this.updateEventEnd}
          moveEvent={this.moveEvent}
          newEvent={this.newEvent}
          onScrollLeft={this.onScrollLeft}
          onScrollRight={this.onScrollRight}
          onScrollTop={this.onScrollTop}
          onScrollBottom={this.onScrollBottom}
          toggleExpandFunc={this.toggleExpandFunc}
          eventItemPopoverTemplateResolver={this.eventPopOver}
          eventItemTemplateResolver={this.eventItemTemplateResolver}
          leftCustomHeader={machineSelector}
          rightCustomHeader={overtimeHeader}
          nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
        />
        {popover}
      </div>
    );

    if (this.state.loading) {
      mainView = (
        <div>
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemClick={this.eventClicked}
            viewEventClick={this.ops1}
            viewEventText="Ops 1"
            viewEvent2Text="Ops 2"
            viewEvent2Click={this.ops2}
            updateEventStart={this.updateEventStart}
            updateEventEnd={this.updateEventEnd}
            moveEvent={this.moveEvent}
            newEvent={this.newEvent}
            onScrollLeft={this.onScrollLeft}
            onScrollRight={this.onScrollRight}
            onScrollTop={this.onScrollTop}
            onScrollBottom={this.onScrollBottom}
            toggleExpandFunc={this.toggleExpandFunc}
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            leftCustomHeader={machineSelector}
            rightCustomHeader={overtimeHeader}
            nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
          />
          <div id="spinner-div">
            <Spinner />
          </div>
        </div>
      );
    }

    return (
      <div className="row" id="simulation-timetable-wrapper">
        <div id="simulation-timetable">
          {mainView}
          <OvertimeModal
            show={this.state.OvertimeModalShow}
            onHide={() => this.setState({ OvertimeModalShow: false })}
            updateSchedule={this.getTimetable}
          />
          <CommandModal
            show={this.state.modalShow}
            onHide={(changedShortage) => this.commandModalOnHide(changedShortage)}
            command={this.state.modalCommand}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  schedulerCommands: state.scheduler.schedulerCommands,
  simulationCommands: state.scheduler.simulationCommands,
  errors: state.errors,
  role: state.security.user.role[0].authority,
  machineViewId: state.scheduler.machineView.id,
  machineViewName: state.scheduler.machineView.name,
  overtimes: state.overtimes.overtimes,
  loading: state.loading.loading,
});

export default connect(mapStateToProps, {
  getCommands,
  setMachineView,
  getOvertimes,
  getSimulationCommands,
  setFixedOrdersArray,
})(withDragDropContext(SimulationTimetable));
