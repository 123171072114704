import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { getNotifications } from "../../../Redux/actions/Notifications/notificationsActions";
import { machineEnumeratorToMachineName } from "./TransformMachineCreds";
import { NotificationsHeader } from "./NotificationsHeader";

class NotificationWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationArray: [],
    };
  }

  countNotifications = () => {
    let count = 0;
    let items = this.state.notificationArray;
    items.forEach((item) => {
      if (!item.isChecked) {
        count = count + 1;
      }
    });

    return count;
  };

  componentDidMount = async () => {
    this.getNotifications();
  };
  setNotificationsFromState = () => {
    this.countNotifications();
    let updatedNotifications = [];
    this.props.notifications.forEach((notification) => {
      updatedNotifications.push({
        id: notification.notificationId,
        notification: notification.text,
        time: notification.createdTime,
        day: notification.createdDate,
        isChecked: notification.checked,
        value: notification.notificationId,
        machine: notification.printingMachineName,
      });
    });
    return updatedNotifications;
  };
  setNotificationsFromApi = async () => {
    try {
      await this.props.getNotifications();
      let updatedNotifications = [];
      this.props.notifications.forEach((notification) => {
        updatedNotifications.push({
          id: notification.notificationId,
          notification: notification.text,
          time: notification.createdTime,
          day: notification.createdDate,
          isChecked: notification.checked,
          value: notification.notificationId,
          machine: notification.printingMachineName,
        });
      });
      this.countNotifications();
      return updatedNotifications;
    } catch (error) {
      console.log("notification API error");
    }
  };
  getNotifications = async () => {
    let updatedNotifications = [];
    if (this.props.notifications === null) {
      updatedNotifications = await this.setNotificationsFromApi();
    } else {
      updatedNotifications = await this.setNotificationsFromState();
    }
    this.setState({ notificationArray: updatedNotifications });
  };
  render() {
    return (
      <React.Fragment>
        <NotificationsHeader count={this.countNotifications()} />
        <ul id="dashboard-list">
          {this.state.notificationArray.length > 0
            ? this.state.notificationArray.slice(0, 6).map((item, index) => {
                return (
                  <li className="dashboard-list-item" key={item.id}>
                    <span id="dashboard-notification-list-contents">
                      <b>{machineEnumeratorToMachineName(item.machine)}</b>{" "}
                      {/* {item.notification.slice(0, 19)} */}
                      {item.notification}
                    </span>{" "}
                    {/* <span id="dashboard-notification-list-contents">
                      {item.notification.slice(0, 19)}
                    </span> */}
                    <span id="dashboard-notification-list-time">
                      {moment(item.day).format("ddd")}{" "}
                      {moment(item.time, ["HH:mm:ss"]).format("HH:mm")}
                    </span>
                  </li>
                );
              })
            : null}
        </ul>
        <span id="view-more">View More</span>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notificationsArray,
  errors: state.errors,
});
export default connect(mapStateToProps, { getNotifications })(
  NotificationWidget
);
