export const machineEnumeratorToMachineName = machine_enumerator => {
  switch (machine_enumerator) {
    case "GALLUS_1":
      return "Gallus 1";
    case "GALLUS_2":
      return "Gallus 2";
    case "KOPACK":
      return "Kopack";
    case "MULLER_MARTINI":
      return "Muller Martini";
    case "DIGITAL_HP6000":
      return "HP6000";
    default:
      break;
  }
};

export const machineNameToMachineEnumerator = machine_name => {
  switch (machine_name) {
    case "Gallus 1":
      return "GALLUS_1";
    case "Gallus 2":
      return "GALLUS_2";
    case "Kopack":
      return "KOPACK";
    case "Muller Martini":
      return "MULLER_MARTINI";
    case "HP6000":
      return "DIGITAL_HP6000";
    default:
      break;
  }
};
