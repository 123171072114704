import { combineReducers } from "redux";

import loginReducer from "./loginReducer";
import schedulerReducer from "./Scheduler/schedulerReducer";
import notificationsReducer from "./Notifications/notificationsReducer";
import overtimeReducer from "./Overtimes/overtimeReducer";
import analyticsReducer from "./Analytics/analyticsReducer";
import sideMenuClearReducer from "./sideMenuClearReducer";
import loadingReducer from "./loadingReducer";

// Combinereducers is used when multiple reducers are present

export const appReducer = combineReducers({
  security: loginReducer,
  scheduler: schedulerReducer,
  notifications: notificationsReducer,
  overtimes: overtimeReducer,
  analytics: analyticsReducer,
  sideMenuClear: sideMenuClearReducer,
  loading: loadingReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};
