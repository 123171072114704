import { backInstance } from "../../config";
import { GET_ERRORS, SET_CURRENT_USER, USER_LOGOUT } from "./types";
import jwt_decode from "jwt-decode";
import history from "../../history";

export const createNewUser = (newUser, history) => async (dispatch) => {
  try {
    await backInstance.post("/users", newUser);
    history.push("/ResourceMGT");
    dispatch({
      type: GET_ERRORS,
      payload: null,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const login = (LoginRequest) => async (dispatch) => {
  try {
    // post => Login Request
    const res = await backInstance.post("/auth/login", LoginRequest);
    // extract token from res.data
    const token = res.data.data;
    // store the token in the localStorage
    localStorage.setItem("jwtToken", token);
    // decode token on React
    const decoded = jwt_decode(token);
    // dispatch to our securityReducer
    history.push("/");

    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded,
      tokenExp: decoded.exp,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("state");
  dispatch({
    type: USER_LOGOUT,
  });
};
