import { GET_NOTIFICATIONS } from "../../actions/types";

const initialState = {
  notificationsArray: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notificationsArray: action.payload
      };

    default:
      return state;
  }
}
