import {
  GET_COMMANDS,
  SET_MACHINE_VIEW,
  GET_CUTTING_COMMANDS,
  SET_CUTTING_MACHINE_VIEW,
  GET_SIMULATION_COMMANDS,
  SET_FIXED_ORDERS_ARRAY,
} from "../../actions/types";
import { machineNameToMachineEnumerator } from "../../../containers/Scheduler/Notifications/TransformMachineCreds";

const initialState = {
  schedulerCommands: null,
  simulationCommands: null,
  fixedOrdersArray: null,
  machineView: {
    name: "Gallus 1",
    id: "GALLUS_1",
  },
  cuttingCommands: null,
  cuttingMachineView: {
    name: "OMEGA",
    id: "OMEGA",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMMANDS:
      return {
        ...state,
        schedulerCommands: action.payload,
      };
    case SET_MACHINE_VIEW:
      return {
        ...state,
        machineView: {
          name: action.payload,
          id: machineNameToMachineEnumerator(action.payload),
        },
      };
    case GET_CUTTING_COMMANDS:
      return {
        ...state,
        cuttingCommands: action.payload,
      };
    case SET_CUTTING_MACHINE_VIEW:
      return {
        ...state,
        cuttingMachineView: {
          name: action.payload,
          id: action.payload,
        },
      };
    case GET_SIMULATION_COMMANDS:
      return {
        ...state,
        simulationCommands: action.payload,
      };
    case SET_FIXED_ORDERS_ARRAY:
      return {
        ...state,
        fixedOrdersArray: action.payload,
      };
    default:
      return state;
  }
}
