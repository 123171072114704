import { overtimeInstance } from "../../../config";
import { GET_OVERTIMES, GET_ERRORS } from "../types";

const dateFormatOvertimeSlashToHyphen = overtime_object => {
  let overtime_clone_object = JSON.parse(JSON.stringify(overtime_object));
  for (let machine in overtime_clone_object) {
    for (let overtime of overtime_clone_object[machine]) {
      overtime.overtimeDate = overtime.overtimeDate.replace(/\//g, "-");
    }
  }
  return overtime_clone_object;
};

// get the overtime periods
export const getOvertimes = () => async dispatch => {
  try {
    const res = await overtimeInstance.get("/schedule/overtimes");
    dispatch({
      type: GET_OVERTIMES,
      payload: dateFormatOvertimeSlashToHyphen(res.data.data)
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};
