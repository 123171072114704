import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  getOrdersPerMonth,
  getOrdersPerMonthExistingYears,
} from "../../../Redux/actions/Analytics/analyticsActions";
import { getCommands } from "../../../Redux/actions/Scheduler/schedulerActions";
import moment from "moment";

class AnalyticsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "GALLUS_1",
      optionsMachines: [
        { name: "Gallus 1", value: "GALLUS_1" },
        { name: "Gallus 2", value: "GALLUS_2" },
        { name: "Kopack", value: "KOPACK" },
        { name: "HP 6000", value: "DIGITAL_HP6000" },
        { name: "Muller Martini", value: "MULLER_MARTINI" },
        { name: "Gallus 1 and 2", value: "GALLUS_1_2" },
      ],
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "0",
            backgroundColor: [
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
            ],
            hoverBackgroundColor: [
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
              "#264EC2",
            ],
          },
          {
            data: [],
            label: "Gallus 2",
            backgroundColor: [
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
            ],
            hoverBackgroundColor: [
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
              "#E14D97",
            ],
          },
        ],
      },
    };
  }
  componentDidMount = async () => {
    if (this.props.schedulerCommands !== null) {
      this.loadChart(this.state.selectedValue);
    } else {
      await this.props.getCommands();
      try {
        this.loadChart(this.state.selectedValue);
      } catch (error) {
        console.log(error);
      }
    }
  };
  loadChart = (machine) => {
    var stateCopy = Object.assign({}, this.state);
    var stateCopyTwo = Object.assign({}, this.state);
    let twoWeeksArray = [];
    for (let i = 0; i < 14; i++) {
      twoWeeksArray.push(moment().add(i, "days").format("D/M"));
    }
    let incomingDataGallus1 = [];
    let incomingDataGallus2 = [];
    let incomingDataKopack = [];
    let incomingDataMuller = [];
    let incomingDataHp = [];
    let count0 = 0;
    let count1 = 0;
    let count2 = 0;
    let count3 = 0;
    let count4 = 0;
    let count5 = 0;
    let count6 = 0;
    let count7 = 0;
    let count8 = 0;
    let count9 = 0;
    let count10 = 0;
    let count11 = 0;
    let count12 = 0;
    let count13 = 0;
    let countTwo0 = 0;
    let countTwo1 = 0;
    let countTwo2 = 0;
    let countTwo3 = 0;
    let countTwo4 = 0;
    let countTwo5 = 0;
    let countTwo6 = 0;
    let countTwo7 = 0;
    let countTwo8 = 0;
    let countTwo9 = 0;
    let countTwo10 = 0;
    let countTwo11 = 0;
    let countTwo12 = 0;
    let countTwo13 = 0;
    switch (machine) {
      case "GALLUS_1_2":
        this.props.schedulerCommands.forEach((element) => {
          if (
            moment(element.start).format("D/M") === twoWeeksArray[0] &&
            element.resourceId === "GALLUS_1"
          ) {
            count0++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[1] &&
            element.resourceId === "GALLUS_1"
          ) {
            count1++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[2] &&
            element.resourceId === "GALLUS_1"
          ) {
            count2++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[3] &&
            element.resourceId === "GALLUS_1"
          ) {
            count3++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[4] &&
            element.resourceId === "GALLUS_1"
          ) {
            count4++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[5] &&
            element.resourceId === "GALLUS_1"
          ) {
            count5++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[6] &&
            element.resourceId === "GALLUS_1"
          ) {
            count6++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[7] &&
            element.resourceId === "GALLUS_1"
          ) {
            count7++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[8] &&
            element.resourceId === "GALLUS_1"
          ) {
            count8++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[9] &&
            element.resourceId === "GALLUS_1"
          ) {
            count9++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[10] &&
            element.resourceId === "GALLUS_1"
          ) {
            count10++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[11] &&
            element.resourceId === "GALLUS_1"
          ) {
            count11++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[12] &&
            element.resourceId === "GALLUS_1"
          ) {
            count12++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[13] &&
            element.resourceId === "GALLUS_1"
          ) {
            count13++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[0] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo0++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[1] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo1++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[2] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo2++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[3] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo3++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[4] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo4++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[5] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo5++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[6] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo6++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[7] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo7++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[8] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo8++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[9] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo9++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[10] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo10++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[11] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo11++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[12] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo12++;
          }
          else if (
            moment(element.start).format("D/M") === twoWeeksArray[13] &&
            element.resourceId === "GALLUS_2"
          ) {
            countTwo13++;
          }
        });
        incomingDataGallus1.push(
          count0,
          count1,
          count2,
          count3,
          count4,
          count5,
          count6,
          count7,
          count8,
          count9,
          count10,
          count11,
          count12,
          count13
        );
        incomingDataGallus2.push(
          countTwo0,
          countTwo1,
          countTwo2,
          countTwo3,
          countTwo4,
          countTwo5,
          countTwo6,
          countTwo7,
          countTwo8,
          countTwo9,
          countTwo10,
          countTwo11,
          countTwo12,
          countTwo13
        );
        stateCopy.data.datasets = stateCopy.data.datasets.slice();
        stateCopy.data.datasets[0] = Object.assign(
          {},
          stateCopy.data.datasets[0]
        );
        stateCopy.data.datasets[0].data = incomingDataGallus1;
        this.setState(stateCopy);

        stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
        stateCopyTwo.data.datasets[1] = Object.assign(
          {},
          stateCopyTwo.data.datasets[1]
        );
        stateCopyTwo.data.datasets[1].data = incomingDataGallus2;
        this.setState(stateCopyTwo);
          

        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            labels: twoWeeksArray,
          },
        }));
        break;
      case "GALLUS_1":
        this.props.schedulerCommands.forEach((element) => {
          if (
            moment(element.start).format("D/M") === twoWeeksArray[0] &&
            element.resourceId === "GALLUS_1"
          ) {
            count0++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[1] &&
            element.resourceId === "GALLUS_1"
          ) {
            count1++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[2] &&
            element.resourceId === "GALLUS_1"
          ) {
            count2++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[3] &&
            element.resourceId === "GALLUS_1"
          ) {
            count3++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[4] &&
            element.resourceId === "GALLUS_1"
          ) {
            count4++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[5] &&
            element.resourceId === "GALLUS_1"
          ) {
            count5++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[6] &&
            element.resourceId === "GALLUS_1"
          ) {
            count6++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[7] &&
            element.resourceId === "GALLUS_1"
          ) {
            count7++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[8] &&
            element.resourceId === "GALLUS_1"
          ) {
            count8++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[9] &&
            element.resourceId === "GALLUS_1"
          ) {
            count9++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[10] &&
            element.resourceId === "GALLUS_1"
          ) {
            count10++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[11] &&
            element.resourceId === "GALLUS_1"
          ) {
            count11++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[12] &&
            element.resourceId === "GALLUS_1"
          ) {
            count12++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[13] &&
            element.resourceId === "GALLUS_1"
          ) {
            count13++;
          }
        });
        incomingDataGallus1.push(
          count0,
          count1,
          count2,
          count3,
          count4,
          count5,
          count6,
          count7,
          count8,
          count9,
          count10,
          count11,
          count12,
          count13
        );
        stateCopy.data.datasets = stateCopy.data.datasets.slice();
        stateCopy.data.datasets[0] = Object.assign(
          {},
          stateCopy.data.datasets[0]
        );
        stateCopy.data.datasets[0].data = incomingDataGallus1;
        this.setState(stateCopy);

        stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
        stateCopyTwo.data.datasets[1] = Object.assign(
          {},
          stateCopyTwo.data.datasets[1]
        );
        stateCopyTwo.data.datasets[1].data = incomingDataGallus2;
        this.setState(stateCopyTwo);
        console.log(stateCopyTwo, "G1");


        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            labels: twoWeeksArray,
          },
        }));
        break;
      case "GALLUS_2":
        this.props.schedulerCommands.forEach((element) => {
          if (
            moment(element.start).format("D/M") === twoWeeksArray[0] &&
            element.resourceId === "GALLUS_2"
          ) {
            count0++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[1] &&
            element.resourceId === "GALLUS_2"
          ) {
            count1++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[2] &&
            element.resourceId === "GALLUS_2"
          ) {
            count2++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[3] &&
            element.resourceId === "GALLUS_2"
          ) {
            count3++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[4] &&
            element.resourceId === "GALLUS_2"
          ) {
            count4++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[5] &&
            element.resourceId === "GALLUS_2"
          ) {
            count5++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[6] &&
            element.resourceId === "GALLUS_2"
          ) {
            count6++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[7] &&
            element.resourceId === "GALLUS_2"
          ) {
            count7++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[8] &&
            element.resourceId === "GALLUS_2"
          ) {
            count8++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[9] &&
            element.resourceId === "GALLUS_2"
          ) {
            count9++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[10] &&
            element.resourceId === "GALLUS_2"
          ) {
            count10++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[11] &&
            element.resourceId === "GALLUS_2"
          ) {
            count11++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[12] &&
            element.resourceId === "GALLUS_2"
          ) {
            count12++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[13] &&
            element.resourceId === "GALLUS_2"
          ) {
            count13++;
          }
        });
        incomingDataGallus2.push(
          count0,
          count1,
          count2,
          count3,
          count4,
          count5,
          count6,
          count7,
          count8,
          count9,
          count10,
          count11,
          count12,
          count13
        );
        stateCopy.data.datasets = stateCopy.data.datasets.slice();
        stateCopy.data.datasets[0] = Object.assign(
          {},
          stateCopy.data.datasets[0]
        );
        stateCopy.data.datasets[0].data = incomingDataGallus2;
        this.setState(stateCopy);

        stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
        stateCopyTwo.data.datasets[1] = Object.assign(
          {},
          stateCopyTwo.data.datasets[1]
        );
        stateCopyTwo.data.datasets[1].data = incomingDataGallus1;
        this.setState(stateCopyTwo);
        console.log(stateCopyTwo, "G2");

        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            labels: twoWeeksArray,
          },
        }));
        break;
      case "KOPACK":
        this.props.schedulerCommands.forEach((element) => {
          if (
            moment(element.start).format("D/M") === twoWeeksArray[0] &&
            element.resourceId === "KOPACK"
          ) {
            count0++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[1] &&
            element.resourceId === "KOPACK"
          ) {
            count1++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[2] &&
            element.resourceId === "KOPACK"
          ) {
            count2++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[3] &&
            element.resourceId === "KOPACK"
          ) {
            count3++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[4] &&
            element.resourceId === "KOPACK"
          ) {
            count4++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[5] &&
            element.resourceId === "KOPACK"
          ) {
            count5++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[6] &&
            element.resourceId === "KOPACK"
          ) {
            count6++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[7] &&
            element.resourceId === "KOPACK"
          ) {
            count7++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[8] &&
            element.resourceId === "KOPACK"
          ) {
            count8++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[9] &&
            element.resourceId === "KOPACK"
          ) {
            count9++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[10] &&
            element.resourceId === "KOPACK"
          ) {
            count10++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[11] &&
            element.resourceId === "KOPACK"
          ) {
            count11++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[12] &&
            element.resourceId === "KOPACK"
          ) {
            count12++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[13] &&
            element.resourceId === "GALLUS_2"
          ) {
            count13++;
          }
        });
        incomingDataKopack.push(
          count0,
          count1,
          count2,
          count3,
          count4,
          count5,
          count6,
          count7,
          count8,
          count9,
          count10,
          count11,
          count12,
          count13
        );
        stateCopy.data.datasets = stateCopy.data.datasets.slice();
        stateCopy.data.datasets[0] = Object.assign(
          {},
          stateCopy.data.datasets[0]
        );
        stateCopy.data.datasets[0].data = incomingDataKopack;
        this.setState(stateCopy);

        stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
        stateCopyTwo.data.datasets[1] = Object.assign(
          {},
          stateCopyTwo.data.datasets[1]
        );
        stateCopyTwo.data.datasets[1].data = incomingDataGallus2;
        this.setState(stateCopyTwo);

        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            labels: twoWeeksArray,
          },
        }));
        break;
      case "MULLER_MARTINI":
        this.props.schedulerCommands.forEach((element) => {
          if (
            moment(element.start).format("D/M") === twoWeeksArray[0] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count0++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[1] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count1++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[2] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count2++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[3] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count3++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[4] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count4++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[5] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count5++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[6] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count6++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[7] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count7++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[8] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count8++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[9] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count9++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[10] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count10++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[11] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count11++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[12] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count12++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[13] &&
            element.resourceId === "MULLER_MARTINI"
          ) {
            count13++;
          }
        });
        incomingDataMuller.push(
          count0,
          count1,
          count2,
          count3,
          count4,
          count5,
          count6,
          count7,
          count8,
          count9,
          count10,
          count11,
          count12,
          count13
        );
        stateCopy.data.datasets = stateCopy.data.datasets.slice();
        stateCopy.data.datasets[0] = Object.assign(
          {},
          stateCopy.data.datasets[0]
        );
        stateCopy.data.datasets[0].data = incomingDataMuller;
        this.setState(stateCopy);

        stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
        stateCopyTwo.data.datasets[1] = Object.assign(
          {},
          stateCopyTwo.data.datasets[1]
        );
        stateCopyTwo.data.datasets[1].data = incomingDataGallus2;
        this.setState(stateCopyTwo);

        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            labels: twoWeeksArray,
          },
        }));
        break;
      case "DIGITAL_HP6000":
        this.props.schedulerCommands.forEach((element) => {
          if (
            moment(element.start).format("D/M") === twoWeeksArray[0] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count0++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[1] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count1++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[2] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count2++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[3] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count3++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[4] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count4++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[5] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count5++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[6] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count6++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[7] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count7++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[8] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count8++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[9] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count9++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[10] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count10++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[11] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count11++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[12] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count12++;
          } else if (
            moment(element.start).format("D/M") === twoWeeksArray[13] &&
            element.resourceId === "DIGITAL_HP6000"
          ) {
            count13++;
          }
        });
        incomingDataHp.push(
          count0,
          count1,
          count2,
          count3,
          count4,
          count5,
          count6,
          count7,
          count8,
          count9,
          count10,
          count11,
          count12,
          count13
        );
        stateCopy.data.datasets = stateCopy.data.datasets.slice();
        stateCopy.data.datasets[0] = Object.assign(
          {},
          stateCopy.data.datasets[0]
        );
        stateCopy.data.datasets[0].data = incomingDataHp;
        this.setState(stateCopy);

        stateCopyTwo.data.datasets = stateCopyTwo.data.datasets.slice();
        stateCopyTwo.data.datasets[1] = Object.assign(
          {},
          stateCopyTwo.data.datasets[1]
        );
        stateCopyTwo.data.datasets[1].data = incomingDataGallus2;
        this.setState(stateCopyTwo);

        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            labels: twoWeeksArray,
          },
        }));
        break;
      default:
        break;
    }
  };
  handleChange = async (event) => {
    await this.setState({ selectedValue: event.target.value });
    this.loadChart(this.state.selectedValue);
  };
  setDashboardChartFromState = () => {};
  render() {
    const { optionsMachines, selectedValue } = this.state;

    return (
      <React.Fragment>
        <div className="analytics-dashboard-title">Daily Order Count</div>
        <select
          onChange={this.handleChange}
          value={selectedValue}
          className="custom-select custom-select-sm"
          id="analytics-select-dashboard"
        >
          {optionsMachines.map((item) => (
            <option key={item.name} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
        <Bar
          data={this.state.data}
          options={{
            title: {
              display: false,
              text: "Daily Order Count",
              fontSize: "20",
              fontFamily: "Nunito Sans, SemiBold",
              fontColor: "#585A5A",
            },
            legend: { display: false, position: "bottom" },
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  schedulerCommands: state.scheduler.schedulerCommands,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  getOrdersPerMonth,
  getOrdersPerMonthExistingYears,
  getCommands,
})(AnalyticsWidget);
