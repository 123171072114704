import { notificationInstance } from "../../../config";
import { GET_NOTIFICATIONS, GET_ERRORS } from "../types";

export const getNotifications = () => async dispatch => {
  try {
    const res = await notificationInstance.get("/notifications");
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};
