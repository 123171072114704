import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  getMaterialWastage,
  getMaterialWastageYears,
} from "../../../Redux/actions/Analytics/analyticsActions";
import Spinner from "../../../components/Spinner/Spinner";

class MaterialWastageChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      optionsYearTest: [],
      value: "2019",
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#435791",
              "#0F7BB4",
              "#2FA3D2",
              "#04AA98",
              "#EE8512",
              "#C442A6",
              "#E14D97",
              "#813652",
            ],
            hoverBackgroundColor: [
              "#435791",
              "#0F7BB4",
              "#2FA3D2",
              "#04AA98",
              "#EE8512",
              "#C442A6",
              "#E14D97",
              "#813652",
            ],
          },
        ],
      },
    };
  }

  componentDidMount = async () => {
    let incomingLabels = [];
    let incomingData = [];
    let existingYearsArray = [];
    this.setState({ loading: true });
    await this.props.getMaterialWastageYears();

    if (this.props.materialWastagePerYearExisting !== null) {
      this.props.materialWastagePerYearExisting.forEach((element) => {
        existingYearsArray.push(element.year);
      });
    }
    this.setState({
      optionsYearTest: existingYearsArray,
    });
    this.props.materialWastagePerYear.forEach((element) => {
      incomingLabels.push(element.machineName);
      incomingData.push(element.wastage);
    });

    var stateCopy = Object.assign({}, this.state);
    stateCopy.data.datasets = stateCopy.data.datasets.slice();
    stateCopy.data.datasets[0] = Object.assign({}, stateCopy.data.datasets[0]);
    stateCopy.data.datasets[0].data = incomingData;
    this.setState(stateCopy);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        labels: incomingLabels,
      },
    }));
    this.setState({ loading: false });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.value !== this.state.value) {
      this.onYearSelect(this.state.value);
    }
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value, updated: false });
  };
  onYearSelect = async (value) => {
    try {
      this.setState({ loading: true });
      await this.props.getMaterialWastage(value);
      let incomingLabels = [];
      let incomingData = [];

      this.props.materialWastagePerYear.forEach((element) => {
        incomingLabels.push(element.machineName);
        incomingData.push(element.wastage);
      });
      var stateCopy = Object.assign({}, this.state);
      stateCopy.data.datasets = stateCopy.data.datasets.slice();
      stateCopy.data.datasets[0] = Object.assign(
        {},
        stateCopy.data.datasets[0]
      );
      stateCopy.data.datasets[0].data = incomingData;
      this.setState(stateCopy);

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          labels: incomingLabels,
        },
      }));
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { optionsYearTest, value, loading } = this.state;

    if (this.props.materialWastagePerYear !== null && loading === false) {
      return (
        <React.Fragment>
          <select
            onChange={this.handleChange}
            value={value}
            data-test="org-reg-select"
            className="custom-select custom-select-sm"
            id="analytics-select"
          >
            {optionsYearTest.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <Bar
            data={this.state.data}
            options={{
              title: {
                display: true,
                text: "Material Wastage per Machine",
                fontSize: "30",
                fontFamily: "Nunito Sans, SemiBold",
                fontColor: "#585A5A",
              },
              legend: {
                display: false,
                position: "bottom",
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: function (value, index, values) {
                        return value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      },
                    },
                  },
                ],
              },
            }}
          />
        </React.Fragment>
      );
    } else {
      return (
        <div id="spinner-div">
          <Spinner />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  materialWastagePerYear: state.analytics.materialWastagePerYear,
  materialWastagePerYearExisting:
    state.analytics.materialWastagePerYearExisting,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  getMaterialWastage,
  getMaterialWastageYears,
})(MaterialWastageChart);
