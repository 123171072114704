import { SHOW_LOADING, HIDE_LOADING } from "../actions/types";

const INITIAL_STATE = {
    show: true
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_LOADING:
            return { ...state, show: true }
        case HIDE_LOADING:
            return { ...state, show: false }
        default:
            return state;
    }
};
