import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import { getMaterialCosts } from "../../../Redux/actions/Analytics/analyticsActions";

class MaterialCostsChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#0F7BB4",
              " #435791",
              "#EE8512",
              "#F14127",
              " #C442A6",
            ],
            hoverBackgroundColor: [
              "#0F7BB4",
              " #435791",
              "#EE8512",
              "#F14127",
              " #C442A6",
            ],
          },
        ],
      },
    };
  }

  componentDidMount = () => {
    let incomingLabels = [];
    let incomingData = [];
    if (this.props.materialCostsPerColor !== null) {
      for (let [key, value] of Object.entries(
        this.props.materialCostsPerColor
      )) {
        incomingLabels.push(key);
        incomingData.push(Math.round(value));
      }

      var stateCopy = Object.assign({}, this.state);
      stateCopy.data.datasets = stateCopy.data.datasets.slice();
      stateCopy.data.datasets[0] = Object.assign(
        {},
        stateCopy.data.datasets[0]
      );
      stateCopy.data.datasets[0].data = incomingData;
      this.setState(stateCopy);

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          labels: incomingLabels,
        },
      }));
    }
  };

  render() {
    if (this.props.materialCostsPerColor !== null) {
      return (
        <React.Fragment>
          <Pie
            data={this.state.data}
            options={{
              title: {
                display: true,
                text: "Material Costs per Color",
                fontSize: "30",
                fontFamily: "Nunito Sans, SemiBold",
                fontColor: "#585A5A",
              },
              legend: { position: "bottom" },
            }}
          />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  materialCostsPerColor: state.analytics.materialCostsPerColor,
  errors: state.errors,
});
export default connect(mapStateToProps, { getMaterialCosts })(
  MaterialCostsChart
);
