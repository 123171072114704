import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  getOrdersPerMonth,
  getOrdersPerMonthExistingYears,
} from "../../../Redux/actions/Analytics/analyticsActions";
import Spinner from "../../../components/Spinner/Spinner";

class ordersPerMonthChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updated: false,
      value: "2019",
      optionsYearTest: [],
      data: {
        labels: [],
        datasets: [
          {
            fill: false,
            borderColor: "#0F7BB4",
            data: [],
            backgroundColor: [
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
            ],
            hoverBackgroundColor: [
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
              " #435791",
            ],
          },
        ],
      },
    };
  }

  componentDidMount = async () => {
    let incomingLabels = [];
    let incomingData = [];
    let existingYearsArray = [];
    this.setState({ loading: true });
    await this.props.getOrdersPerMonthExistingYears();
    if (this.props.ordersPerMonthExistingYears !== null) {
      this.props.ordersPerMonthExistingYears.forEach((element) => {
        existingYearsArray.push(element.year);
      });
    }
    await this.setState({
      optionsYearTest: existingYearsArray,
    });
    this.props.ordersPerMonth.forEach((element) => {
      incomingLabels.push(element.month);
      incomingData.push(element.monthValue);
    });

    var stateCopy = Object.assign({}, this.state);
    stateCopy.data.datasets = stateCopy.data.datasets.slice();
    stateCopy.data.datasets[0] = Object.assign({}, stateCopy.data.datasets[0]);
    stateCopy.data.datasets[0].data = incomingData;
    this.setState(stateCopy);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        labels: incomingLabels,
      },
    }));
    this.setState({ loading: false });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.value !== this.state.value) {
      this.onYearSelect(this.state.value);
    }
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value, updated: false });
  };
  onYearSelect = async (value) => {
    try {
      this.setState({ loading: true });
      await this.props.getOrdersPerMonth(value);
      let incomingLabels = [];
      let incomingData = [];

      this.props.ordersPerMonth.forEach((element) => {
        incomingLabels.push(element.month);
        incomingData.push(element.monthValue);
      });

      var stateCopy = Object.assign({}, this.state);
      stateCopy.data.datasets = stateCopy.data.datasets.slice();
      stateCopy.data.datasets[0] = Object.assign(
        {},
        stateCopy.data.datasets[0]
      );
      stateCopy.data.datasets[0].data = incomingData;
      this.setState(stateCopy);

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          labels: incomingLabels,
        },
      }));
      this.setState({ loading: false });
    } catch (err) {
      console.log("Error");
    }
  };
  render() {
    const { optionsYearTest, value, loading } = this.state;

    if (this.props.ordersPerMonth !== null && loading === false) {
      return (
        <React.Fragment>
          <select
            onChange={this.handleChange}
            value={value}
            data-test="org-reg-select"
            className="custom-select custom-select-sm"
            id="analytics-select"
          >
            {optionsYearTest.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <Line
            data={this.state.data}
            options={{
              title: {
                display: true,
                text: "Orders per Month Chart",
                fontSize: "30",
                fontFamily: "Nunito Sans, SemiBold",
                fontColor: "#585A5A",
              },
              legend: { display: false, position: "bottom" },
              responsive: true,
              maintainAspectRatio: true,
            }}
          />
        </React.Fragment>
      );
    } else {
      return (
        <div id="spinner-div">
          <Spinner />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  ordersPerMonthExistingYears: state.analytics.ordersPerMonthExistingYears,
  ordersPerMonth: state.analytics.ordersPerMonth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  getOrdersPerMonth,
  getOrdersPerMonthExistingYears,
})(ordersPerMonthChart);
