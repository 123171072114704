import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cog from "../assets/settings_icon.svg";
import LogOut from "../assets/logouticon.svg";
import {
  faHome,
  faChartBar,
  faBell,
  faSignal,
} from "@fortawesome/free-solid-svg-icons";
import { isDemo } from "../demo_utils";

class Sidemenu extends Component {
  menuLinks;
  showMenuLinks = () => {
    const menuLinks = (
      <ul className="sidemenu-ul">
        <NavLink
          className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""} ${
            this.props.loading ? "no-click" : ""
          }`}
          to="/"
          exact
          activeClassName="activeMI"
        >
          <li className="sidemenu-li" id="sidemenu-home">
            <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
            Dashboard
          </li>
        </NavLink>
        {this.props.role !== "ROLE_CUSTOMER_SERVICE" && this.props.role !== "ROLE_PRE_PRESS" && this.props.role !== "ROLE_PROCUREMENT" && this.props.role !== "ROLE_CUSTOMER_SERVICE" ? (
          <NavLink
            className={`sidemenu-nav ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""} ${
              this.props.loading ? "no-click" : ""
            }`}
            to="/Simulation"
            activeClassName="activeMI"
          >
            <li className="sidemenu-li" id="sidemenu-simulation">
              <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
              Simulation
            </li>
          </NavLink>
        ) : (
          ""
        )}
        {this.props.role !== "ROLE_CUSTOMER_SERVICE" && this.props.role !== "ROLE_PRE_PRESS" && this.props.role !== "ROLE_PROCUREMENT" && this.props.role !== "ROLE_CUSTOMER_SERVICE" ? (
          <NavLink
            className={`sidemenu-nav ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""} ${
              this.props.loading ? "no-click" : ""
            }`}
            to="/Notifications"
            activeClassName="activeMI"
          >
            <li className="sidemenu-li" id="sidemenu-notifications">
              <FontAwesomeIcon icon={faBell} size="sm" className="fontawesome-sidemenu" />
              Notifications
            </li>
          </NavLink>
        ) : (
          ""
        )}
        {this.props.role !== "ROLE_CUSTOMER_SERVICE" && this.props.role !== "ROLE_PRE_PRESS" ? (
          <NavLink
            className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""} ${
              this.props.loading ? "no-click" : ""
            }`}
            to="/Analytics"
            activeClassName="activeMI"
          >
            <li className="sidemenu-li" id="sidemenu-analytics">
              <FontAwesomeIcon icon={faSignal} size="sm" className="fontawesome-sidemenu" />
              Analytics
            </li>
          </NavLink>
        ) : (
          ""
        )}
        {/* <NavLink
          className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""} ${
            this.props.loading ? "no-click" : ""
          }`}
          to="/Logout"
          activeClassName="activeMI"
        >
          <li className="sidemenu-li" id="sidemenu-Logout">
            <FontAwesomeIcon icon={faSignOutAlt} size="sm" className="fontawesome-sidemenu" />
            Logout
          </li>
        </NavLink> */}
      </ul>
    );
    return menuLinks;
  };
  render() {
    return (
      <div className="container h-100" id="sidemenu-container">
        <div className="row">
          <div className="col">
            <img src={!isDemo() ? require("../assets/dpa_logo.png") : require("../assets/mTune_logo.png")} alt="dpa-logo" id="sidemenu-logo" />
          </div>
        </div>
        <div className="row">
          <div className="col">{this.showMenuLinks()}</div>
            <div className="logout-outer-wrapper">
              <div className="logout-wrapper">
                <a href="/Logout" className="logout-button"><img className="logout-icon" src={LogOut} />Log Out</a>
              </div>
              {/* <div className="settings-wrapper">
                <img src={Cog} />
              </div> */}
            </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sideMenuClear: state.sideMenuClear,
  loading: state.loading.show,
  role: state.security.user.role[0].authority,
});

export default connect(mapStateToProps, null)(Sidemenu);
