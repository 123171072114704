import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { overtimeInstance } from "../../config";
import { getOvertimes } from "../../Redux/actions/Overtimes/overtimeActions";
import moment from "moment";

let overtimeShifts0 = [];
let overtimeShifts1 = [];
let overtimeShifts2 = [];
let overtimeShifts3 = [];
let overtimeShifts4 = [];
let overtimeShifts = [
  overtimeShifts0,
  overtimeShifts1,
  overtimeShifts2,
  overtimeShifts3,
  overtimeShifts4,
];

class OvertimeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      times: [],
      machines: ["Gallus 1", "Gallus 2", "Kopack", "Muller Martini", "HP6000"],
      machine: "",

      date0: "",
      date1: "",
      date2: "",
      date3: "",
      date4: "",

      timeStart0: "13:30",
      timeStart1: "13:30",
      timeStart2: "13:30",
      timeStart3: "13:30",
      timeStart4: "13:30",

      timeEnd0: "14:00",
      timeEnd1: "14:00",
      timeEnd2: "14:00",
      timeEnd3: "14:00",
      timeEnd4: "14:00",

      Gallus1: [],
      Gallus2: [],
      Kopack: [],
      DigitalHp: [],
      MullerMartini: [],
      overtimes: [],

      check: false,
    };
  }

  componentDidMount = async () => {
    //Creation of time selection tables

    let times = [];
    let quarters = ["00", "30"];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 2; j++) {
        if (i < 10) {
          times.push("0" + i + ":" + quarters[j]);
        } else {
          times.push(i + ":" + quarters[j]);
        }
      }
    }
    this.setState({ times: times });
    if (this.props.role !== "ROLE_CUSTOMER_SERVICE" && this.props.role !== "ROLE_PRE_PRESS") {
      try {
        await this.props.getOvertimes();

        Object.entries(this.props.overtimes).forEach(([key, value]) => {
          switch (key) {
            case "GALLUS_1":
              for (let i = 0; i < value.length; i++) {
                overtimeShifts0.push(value[i]);
              }
              this.setState({
                Gallus1: overtimeShifts0,
              });
              break;
            case "GALLUS_2":
              for (let i = 0; i < value.length; i++) {
                overtimeShifts1.push(value[i]);
              }
              this.setState({
                Gallus2: overtimeShifts1,
              });
              break;
            case "KOPACK":
              for (let i = 0; i < value.length; i++) {
                overtimeShifts2.push(value[i]);
              }
              this.setState({
                Kopack: overtimeShifts2,
              });
              break;
            case "MULLER_MARTINI":
              for (let i = 0; i < value.length; i++) {
                overtimeShifts3.push(value[i]);
              }
              this.setState({
                MullerMartini: overtimeShifts3,
              });
              break;
            case "DIGITAL_HP6000":
              for (let i = 0; i < value.length; i++) {
                overtimeShifts4.push(value[i]);
              }
              this.setState({
                DigitalHp: overtimeShifts4,
              });
              break;
            default:
              break;
          }
        });
      } catch (error) {
        console.log("overtimes API error");
      }
    }
  };

  componentWillUnmount() {
    overtimeShifts0.length = 0;
    overtimeShifts1.length = 0;
    overtimeShifts2.length = 0;
    overtimeShifts3.length = 0;
    overtimeShifts4.length = 0;
  }

  onChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  onDateChange = async (e) => {
    //date selection depending on the according machine refereced
    if (e.target.id === "date-select0") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "date-select1") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "date-select2") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "date-select3") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "date-select4") {
      await this.setState({ [e.target.name]: e.target.value });
    }
  };
  onTimeFromChange = async (e) => {
    //date selection depending on the according machine refereced
    if (e.target.id === "time-from-select0") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "time-from-select1") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "time-from-select2") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "time-from-select3") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "time-from-select4") {
      await this.setState({ [e.target.name]: e.target.value });
    }
  };
  onTimeToChange = async (e) => {
    //date selection depending on the according machine refereced
    if (e.target.id === "time-to-select0") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "time-to-select1") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "time-to-select2") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "time-to-select3") {
      await this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.id === "time-to-select4") {
      await this.setState({ [e.target.name]: e.target.value });
    }
  };

  onRemoveClick = (event) => {
    //removal of overtime selections by the user both in the final machine schedule and the css elements
    event.preventDefault();
    let indexer = event.target.id;
    let ArrayIndex = indexer.charAt(indexer.length - 2);
    let PositionIndex = indexer.charAt(indexer.length - 1);

    overtimeShifts[ArrayIndex].splice(PositionIndex, 1);
    this.setState({
      ["date" + ArrayIndex]: "",
    });

    document.getElementById("line" + ArrayIndex + PositionIndex).style.display = "hidden";
  };

  onAddClick = async (event) => {
    event.preventDefault();
    //updating machine overtimes based on the reference button for each one
    if (event.target.id === "buttons0") {
      let overlapCheckerGallus1 = false;
      for (let i = 0; i < overtimeShifts0.length; i++) {
        if (
          (this.state.date0 === overtimeShifts0[i].overtimeDate &&
            this.state.timeStart0 >= overtimeShifts0[i].overtimeFrom &&
            this.state.timeStart0 <= overtimeShifts0[i].overtimeTo) ||
          (this.state.date0 === overtimeShifts0[i].overtimeDate &&
            this.state.timeEnd0 >= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd0 <= overtimeShifts0[i].overtimeTo) ||
          (this.state.date0 === overtimeShifts0[i].overtimeDate &&
            this.state.timeStart0 <= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd0 >= overtimeShifts0[i].overtimeTo) ||
          this.state.timeStart0 === this.state.timeEnd0
        ) {
          overlapCheckerGallus1 = true;
        }
      }
      if (overlapCheckerGallus1 === true) {
        window.alert("there is an overlap on " + this.state.date0 + " at Gallus 1");
      } else if (this.state.date0 === "") {
        window.alert("Please fill in the date field first");
      } else {
        overtimeShifts0.push({
          overtimeDate: this.state.date0,
          overtimeFrom: this.state.timeStart0,
          overtimeTo: this.state.timeEnd0,
        });
        await this.setState({
          Gallus1: overtimeShifts0,
        });
      }
    } else if (event.target.id === "buttons1") {
      let overlapCheckerGallus2 = false;
      for (let i = 0; i < overtimeShifts1.length; i++) {
        if (
          (this.state.date1 === overtimeShifts1[i].overtimeDate &&
            this.state.timeStart1 >= overtimeShifts1[i].overtimeFrom &&
            this.state.timeStart1 <= overtimeShifts1[i].overtimeTo) ||
          (this.state.date1 === overtimeShifts1[i].overtimeDate &&
            this.state.timeEnd1 >= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd1 <= overtimeShifts0[i].overtimeTo) ||
          (this.state.date1 === overtimeShifts1[i].overtimeDate &&
            this.state.timeStart1 <= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd1 >= overtimeShifts0[i].overtimeTo) ||
          this.state.timeStart1 === this.state.timeEnd1
        ) {
          overlapCheckerGallus2 = true;
        }
      }
      if (overlapCheckerGallus2 === true) {
        window.alert("there is an overlap on " + this.state.date1 + " at Gallus 2");
      } else if (this.state.date1 === "") {
        window.alert("Please fill in the date field first");
      } else {
        overtimeShifts1.push({
          overtimeDate: this.state.date1,
          overtimeFrom: this.state.timeStart1,
          overtimeTo: this.state.timeEnd1,
        });
        await this.setState({
          Gallus2: overtimeShifts1,
        });
      }
    } else if (event.target.id === "buttons2") {
      let overlapCheckerKopack = false;
      for (let i = 0; i < overtimeShifts2.length; i++) {
        if (
          (this.state.date2 === overtimeShifts2[i].overtimeDate &&
            this.state.timeStart2 >= overtimeShifts2[i].overtimeFrom &&
            this.state.timeStart2 <= overtimeShifts2[i].overtimeTo) ||
          (this.state.date2 === overtimeShifts2[i].overtimeDate &&
            this.state.timeEnd2 >= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd2 <= overtimeShifts0[i].overtimeTo) ||
          (this.state.date2 === overtimeShifts2[i].overtimeDate &&
            this.state.timeStart2 <= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd2 >= overtimeShifts0[i].overtimeTo) ||
          this.state.timeStart2 === this.state.timeEnd2
        ) {
          overlapCheckerKopack = true;
        }
      }
      if (overlapCheckerKopack === true) {
        window.alert("there is an overlap on " + this.state.date2 + " at Kopack");
      } else if (this.state.date2 === "") {
        window.alert("Please fill in the date field first");
      } else {
        overtimeShifts2.push({
          overtimeDate: this.state.date2,
          overtimeFrom: this.state.timeStart2,
          overtimeTo: this.state.timeEnd2,
        });
        await this.setState({
          Kopack: overtimeShifts2,
        });
      }
    } else if (event.target.id === "buttons3") {
      let overlapCheckerMullerMartini = false;
      for (let i = 0; i < overtimeShifts3.length; i++) {
        if (
          (this.state.date3 === overtimeShifts3[i].overtimeDate &&
            this.state.timeStart3 >= overtimeShifts3[i].overtimeFrom &&
            this.state.timeStart3 <= overtimeShifts3[i].overtimeTo) ||
          (this.state.date3 === overtimeShifts3[i].overtimeDate &&
            this.state.timeEnd3 >= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd3 <= overtimeShifts0[i].overtimeTo) ||
          (this.state.date3 === overtimeShifts3[i].overtimeDate &&
            this.state.timeStart3 <= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd3 >= overtimeShifts0[i].overtimeTo) ||
          this.state.timeStart3 === this.state.timeEnd3
        ) {
          overlapCheckerMullerMartini = true;
        }
      }
      if (overlapCheckerMullerMartini === true) {
        window.alert("there is an overlap on " + this.state.date3 + " at Muller Martini");
      } else if (this.state.date3 === "") {
        window.alert("Please fill in the date field first");
      } else {
        overtimeShifts3.push({
          overtimeDate: this.state.date3,
          overtimeFrom: this.state.timeStart3,
          overtimeTo: this.state.timeEnd3,
        });
        await this.setState({
          MullerMartini: overtimeShifts3,
        });
      }
    } else if (event.target.id === "buttons4") {
      let overlapCheckerDigital = false;
      for (let i = 0; i < overtimeShifts4.length; i++) {
        if (
          (this.state.date4 === overtimeShifts4[i].overtimeDate &&
            this.state.timeStart4 >= overtimeShifts4[i].overtimeFrom &&
            this.state.timeStart4 <= overtimeShifts4[i].overtimeTo) ||
          (this.state.date4 === overtimeShifts4[i].overtimeDate &&
            this.state.timeEnd4 >= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd4 <= overtimeShifts0[i].overtimeTo) ||
          (this.state.date4 === overtimeShifts4[i].overtimeDate &&
            this.state.timeStart4 <= overtimeShifts0[i].overtimeFrom &&
            this.state.timeEnd4 >= overtimeShifts0[i].overtimeTo) ||
          this.state.timeStart4 === this.state.timeEnd4
        ) {
          overlapCheckerDigital = true;
        }
      }
      if (overlapCheckerDigital === true) {
        window.alert("there is an overlap on " + this.state.date4 + " at HP6000");
      } else if (this.state.date4 === "") {
        window.alert("Please fill in the date field first");
      } else {
        overtimeShifts4.push({
          overtimeDate: this.state.date4,
          overtimeFrom: this.state.timeStart4,
          overtimeTo: this.state.timeEnd4,
        });
        await this.setState({
          DigitalHp: overtimeShifts4,
        });
      }
    }
  };

  dateFormatOvertimeHyphenToSlash = (overtime_object) => {
    let overtime_clone_object = JSON.parse(JSON.stringify(overtime_object));
    for (let machine in overtime_clone_object) {
      for (let overtime of overtime_clone_object[machine]) {
        overtime.overtimeDate = overtime.overtimeDate.replace(/-/g, "/");
      }
    }
    return overtime_clone_object;
  };

  onSubmit = async (e) => {
    await this.setState({
      overtimes: {
        GALLUS_1: this.state.Gallus1,
        GALLUS_2: this.state.Gallus2,
        KOPACK: this.state.Kopack,
        MULLER_MARTINI: this.state.MullerMartini,
        DIGITAL_HP6000: this.state.DigitalHp,
      },
      check: true,
    });

    let overtimes_date_formatted = this.dateFormatOvertimeHyphenToSlash(this.state.overtimes);
    overtimeInstance
      .put("/schedule/overtimes", { overtimes: overtimes_date_formatted })
      .catch((error) => console.log("error during overtime updates"));
    this.props.onHide();
    this.props.updateSchedule();
    this.props.getOvertimes();
  };

  onClear = async (e) => {
    overtimeShifts0.length = 0;
    overtimeShifts1.length = 0;
    overtimeShifts2.length = 0;
    overtimeShifts3.length = 0;
    overtimeShifts4.length = 0;
    await this.setState({
      overtimes: {
        GALLUS_1: [],
        GALLUS_2: [],
        KOPACK: [],
        MULLER_MARTINI: [],
        DIGITAL_HP6000: [],
      },
      check: true,
    });
    overtimeInstance.put("/schedule/overtimes", {
      overtimes: {
        GALLUS_1: [],
        GALLUS_2: [],
        KOPACK: [],
        MULLER_MARTINI: [],
        DIGITAL_HP6000: [],
      },
    });

    this.props.onHide();
  };

  // changeColorOnOvertimes = index => {
  //   let num;
  //   num = index % 2;
  //   // console.log(index);
  //   // console.log("row overtime-modal-display-row-" + num.toString());
  //   return "row overtime-modal-display-row-" + num.toString();
  // };

  overtimeStartDependingOnDayHandler = (index) => {
    let weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    let weekends = ["Saturday", "Sunday"];
    switch ("time-from-select" + index) {
      case "time-from-select0":
        if (weekdays.includes(moment(this.state.date0).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"fromtime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date0).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"fromtime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      case "time-from-select1":
        if (weekdays.includes(moment(this.state.date1).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"fromtime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date1).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"fromtime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      case "time-from-select2":
        if (weekdays.includes(moment(this.state.date2).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"fromtime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date2).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"fromtime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      case "time-from-select3":
        if (weekdays.includes(moment(this.state.date3).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"fromtime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date3).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"fromtime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      case "time-from-select4":
        if (weekdays.includes(moment(this.state.date4).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"fromtime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date4).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"fromtime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      default:
        break;
    }
  };
  overtimeEndDependingOnDayHandler = (index) => {
    let weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    let weekends = ["Saturday", "Sunday"];
    switch ("time-to-select" + index) {
      case "time-to-select0":
        if (weekdays.includes(moment(this.state.date0).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"totime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date0).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"totime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      case "time-to-select1":
        if (weekdays.includes(moment(this.state.date1).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"totime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date1).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"totime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      case "time-to-select2":
        if (weekdays.includes(moment(this.state.date2).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"totime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date2).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"totime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      case "time-to-select3":
        if (weekdays.includes(moment(this.state.date3).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"totime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date3).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"totime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      case "time-to-select4":
        if (weekdays.includes(moment(this.state.date4).format("dddd"))) {
          return this.state.times
            .filter((time) => moment(time)._i > "13:00")
            .map((filteredTime) => {
              return (
                <option value={filteredTime} key={"totime" + filteredTime}>
                  {filteredTime}
                </option>
              );
            });
        } else if (weekends.includes(moment(this.state.date4).format("dddd"))) {
          return this.state.times.map((time) => {
            return (
              <option value={time} key={"totime" + time}>
                {time}
              </option>
            );
          });
        }
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <div>
        {this.props.show ? (
          <Modal
            show={this.props.show}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="overtime-modal"
          >
            <Modal.Header id="overtime-modal-header" closeButton>
              <Modal.Title id="overtime-modal-title">Αdd Overtime Hours/Days</Modal.Title>
            </Modal.Header>
            <Modal.Body id="overtime-modal-body">
              <div className="container">
                <form id="container-overtime-modal">
                  <div className="row" id="overtime-labels-row">
                    <div className="col-2" id="machines-title">
                      Machines
                    </div>
                    <div className="col-2" id="date-title">
                      Date
                    </div>
                    <div className="col-2" id="time-from-title">
                      Time (From)
                    </div>
                    <div className="col-2" id="time-to-title">
                      Time (To)
                    </div>
                  </div>
                  {this.state.machines.map((machine, index) => {
                    return (
                      <React.Fragment key={"fragment" + index}>
                        <div className="row" id="overtime-selectors-row">
                          <div className="col">
                            <div
                              id={"machines" + index}
                              className="machines"
                              key={index}
                              value={this.state.machine}
                              name={machine}
                            >
                              {machine}
                            </div>
                          </div>
                          <div className="col-4">
                            <input
                              type="date"
                              key={"date" + index}
                              id={"date-select" + index}
                              className="date-select"
                              value={this.state["date" + index]}
                              name={"date" + index}
                              onChange={this.onDateChange}
                            />
                          </div>
                          <div className="col-2">
                            <select
                              id={"time-from-select" + index}
                              className="time-from-select"
                              name={"timeStart" + index}
                              key={"from" + index}
                              onChange={this.onTimeFromChange}
                            >
                              {this.overtimeStartDependingOnDayHandler(index)}
                            </select>
                          </div>
                          <div className="col-2">
                            <select
                              id={"time-to-select" + index}
                              className="time-to-select"
                              name={"timeEnd" + index}
                              onChange={this.onTimeToChange}
                              key={"to" + index}
                            >
                              {this.overtimeEndDependingOnDayHandler(index)}
                            </select>
                          </div>
                          <div className="col">
                            <button
                              key={"buttons" + index}
                              id={"buttons" + index}
                              className="overtime-modal-button"
                              onClick={this.onAddClick}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                        <div id="overtime-modal-display-section" key={"content" + index}>
                          {overtimeShifts[index].map((item, indexer) => {
                            return (
                              <div
                                id={"line" + index + indexer}
                                key={"line" + index + indexer}
                                className="row"
                              >
                                <div className="col-2"></div>
                                <div
                                  id="addedDate"
                                  key={"addedDate" + index}
                                  className="col-2 text-center"
                                >
                                  {moment(item.overtimeDate).format("DD-MM-YYYY")}
                                </div>
                                <div
                                  id="addedStart"
                                  key={"addedStart" + index}
                                  className="col-2 text-center"
                                >
                                  {item.overtimeFrom}
                                </div>
                                <div
                                  id="addedEnd"
                                  key={"addedEnd" + index}
                                  className="col-2 text-center"
                                >
                                  {item.overtimeTo}
                                </div>
                                <div className="col-2 text-center">
                                  <button
                                    id={"removeButton" + index + indexer}
                                    className="removeButton"
                                    key={"removeButton" + index + indexer}
                                    onClick={this.onRemoveClick}
                                  >
                                    Remove
                                  </button>
                                </div>
                                <br key={"break" + index} />
                              </div>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer id="overtime-modal-footer">
              <Button id="overtimes-button-cancel" onClick={this.props.onHide}>
                Cancel
              </Button>
              <Button id="overtimes-button-submit" onClick={this.onSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  overtimes: state.overtimes.overtimes,
  errors: state.errors,
  role: state.security.user.role[0].authority,
});
export default connect(mapStateToProps, { getOvertimes })(OvertimeModal);
