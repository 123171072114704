import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import { getPrintedLabelsPerColor } from "../../../Redux/actions/Analytics/analyticsActions";

class PrintedLabelsPerColorChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            fill: false,
            borderColor: "#0F7BB4",
            data: [],
            backgroundColor: [
              "#435791",
              "#435791",
              "#435791",
              "#435791",
              "#435791",
            ],
            hoverBackgroundColor: [
              "#435791",
              "#435791",
              "#435791",
              "#435791",
              "#435791",
            ],
          },
        ],
      },
    };
  }

  componentDidMount = () => {
    let incomingLabels = [];
    let incomingData = [];
    if (this.props.printedLabelsPerColor !== null) {
      for (let [key, value] of Object.entries(
        this.props.printedLabelsPerColor
      )) {
        incomingLabels.push(key);
        incomingData.push(value);
      }

      var stateCopy = Object.assign({}, this.state);
      stateCopy.data.datasets = stateCopy.data.datasets.slice();
      stateCopy.data.datasets[0] = Object.assign(
        {},
        stateCopy.data.datasets[0]
      );
      stateCopy.data.datasets[0].data = incomingData;
      this.setState(stateCopy);

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          labels: incomingLabels,
        },
      }));
    }
  };

  render() {
    if (this.props.printedLabelsPerColor !== null) {
      return (
        <React.Fragment>
          <Line
            data={this.state.data}
            options={{
              title: {
                display: true,
                text: "Printed Labels per Color",
                fontSize: "30",
                fontFamily: "Nunito Sans, SemiBold",
                fontColor: "#585A5A",
              },
              legend: {
                display: false,
                position: "bottom",
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: function (value, index, values) {
                        return value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      },
                    },
                  },
                ],
              },
            }}
          />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  printedLabelsPerColor: state.analytics.printedLabelsPerColor,
  errors: state.errors,
});
export default connect(mapStateToProps, { getPrintedLabelsPerColor })(
  PrintedLabelsPerColorChart
);
