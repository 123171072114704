import React, { Component } from "react";

import NotificationList from "./NotificationList";

class Notifications extends Component {
  render() {
    return (
      <div className="container-fluid" id="notifications-container">
        <div className="row no-gutters" id="simulation-row">
          <div className="col-12" >
            <div id="notifications-title-header">Notifications</div>
            <div className="col-12" id="notifications-content">
              <NotificationList />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Notifications;
