import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import moment from "moment";
import {
  getPrintedLabelsPerColorFromYear,
  getPrintedLabelsPerColorExistingYears,
} from "../../../Redux/actions/Analytics/analyticsActions";

class PrintedLabelsPerColorFromYearChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "2015",
      optionsYearTest: [],
      data: {
        labels: [],
        datasets: [
          {
            hidden: true,
            borderColor: "#0F7BB4",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: [
              "#0F7BB4",
              "#0F7BB4",
              "#0F7BB4",
              "#0F7BB4",
              "#0F7BB4",
            ],
            hoverBackgroundColor: [
              "#0F7BB4",
              "#0F7BB4",
              "#0F7BB4",
              "#0F7BB4",
              "#0F7BB4",
            ],
          },
          {
            hidden: true,

            borderColor: "#435791",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: [
              "#435791",
              "#435791",
              "#435791",
              "#435791",
              "#435791",
            ],
            hoverBackgroundColor: [
              "#435791",
              "#435791",
              "#435791",
              "#435791",
              "#435791",
            ],
          },
          {
            hidden: true,

            borderColor: "#EE8512",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: [
              "#EE8512",
              "#EE8512",
              "#EE8512",
              "#EE8512",
              "#EE8512",
            ],
            hoverBackgroundColor: [
              "#EE8512",
              "#EE8512",
              "#EE8512",
              "#EE8512",
              "#EE8512",
            ],
          },
          {
            hidden: true,

            borderColor: "#F14127",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: [
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
            ],
            hoverBackgroundColor: [
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
              "#F14127",
            ],
          },
          {
            hidden: true,

            borderColor: "#C442A6",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: [
              "#C442A6",
              "#C442A6",
              "#C442A6",
              "#C442A6",
              "#C442A6",
            ],
            hoverBackgroundColor: [
              "#C442A6",
              "#C442A6",
              "#C442A6",
              "#C442A6",
              "#C442A6",
            ],
          },
          {
            hidden: true,

            borderColor: "#04AA98",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: [
              "#04AA98",
              "#04AA98",
              "#04AA98",
              "#04AA98",
              "#04AA98",
            ],
            hoverBackgroundColor: [
              "#04AA98",
              "#04AA98",
              "#04AA98",
              "#04AA98",
              "#04AA98",
            ],
          },
        ],
      },
    };
  }

  componentDidMount = async () => {
    let existingYearsArray = [];
    let numberOFColors = [];
    let quantity = [];
    let quantity1 = [];
    let quantity2 = [];
    let quantity3 = [];
    let quantity4 = [];
    let quantity5 = [];
    var stateCopy = Object.assign({}, this.state);
    await this.props.getPrintedLabelsPerColorExistingYears();

    if (this.props.printedLabelsPerColorExistingYears !== null) {
      this.props.printedLabelsPerColorExistingYears.forEach((element) => {
        existingYearsArray.push(element.year);
      });
    }
    await this.setState({
      optionsYearTest: existingYearsArray,
    });
    if (this.props.printedLabelsPerColorFromYear !== null) {
      this.props.printedLabelsPerColorFromYear.forEach((element) => {
        switch (element.givenYear) {
          case parseInt(moment().subtract(5, "years").format("YYYY")):
            if (numberOFColors.length < 5) {
              numberOFColors.push(element.colors);
            }
            // let parsedQuantity = element.quantity
            //   .toString()
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            quantity.push(element.quantity);

            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[0] = Object.assign(
              {},
              stateCopy.data.datasets[0]
            );
            stateCopy.data.datasets[0].data = quantity;
            stateCopy.data.datasets[0].hidden = false;
            stateCopy.data.datasets[0].label = moment()
              .subtract(5, "years")
              .format("YYYY");
            break;
          case parseInt(moment().subtract(4, "years").format("YYYY")):
            quantity1.push(element.quantity);
            if (numberOFColors.length < 5) {
              numberOFColors.push(element.colors);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[1] = Object.assign(
              {},
              stateCopy.data.datasets[1]
            );
            stateCopy.data.datasets[1].data = quantity1;
            stateCopy.data.datasets[1].hidden = false;

            stateCopy.data.datasets[1].label = moment()
              .subtract(4, "years")
              .format("YYYY");
            break;
          case parseInt(moment().subtract(3, "years").format("YYYY")):
            quantity2.push(element.quantity);
            if (numberOFColors.length < 5) {
              numberOFColors.push(element.colors);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[2] = Object.assign(
              {},
              stateCopy.data.datasets[2]
            );
            stateCopy.data.datasets[2].data = quantity2;
            stateCopy.data.datasets[2].hidden = false;

            stateCopy.data.datasets[2].label = moment()
              .subtract(3, "years")
              .format("YYYY");
            break;
          case parseInt(moment().subtract(2, "years").format("YYYY")):
            quantity3.push(element.quantity);
            if (numberOFColors.length < 5) {
              numberOFColors.push(element.colors);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[3] = Object.assign(
              {},
              stateCopy.data.datasets[3]
            );
            stateCopy.data.datasets[3].data = quantity3;
            stateCopy.data.datasets[3].hidden = false;

            stateCopy.data.datasets[3].label = moment()
              .subtract(2, "years")
              .format("YYYY");
            break;
          case parseInt(moment().subtract(1, "years").format("YYYY")):
            quantity4.push(element.quantity);
            if (numberOFColors.length < 5) {
              numberOFColors.push(element.colors);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[4] = Object.assign(
              {},
              stateCopy.data.datasets[4]
            );
            stateCopy.data.datasets[4].data = quantity4;
            stateCopy.data.datasets[4].hidden = false;

            stateCopy.data.datasets[4].label = moment()
              .subtract(1, "years")
              .format("YYYY");
            break;
          case parseInt(moment().format("YYYY")):
            quantity5.push(element.quantity);
            if (numberOFColors.length < 5) {
              numberOFColors.push(element.colors);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[5] = Object.assign(
              {},
              stateCopy.data.datasets[5]
            );
            stateCopy.data.datasets[5].data = quantity5;
            stateCopy.data.datasets[5].hidden = false;

            stateCopy.data.datasets[5].label = moment().format("YYYY");
            break;
          default:
            break;
        }
      });
    }

    this.setState(this.stateCopy);
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        labels: numberOFColors,
      },
    }));
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevState.value !== this.state.value) {
  //     this.onYearSelect(this.state.value);
  //   }
  // };
  // handleChange = (event) => {
  //   this.setState({ value: event.target.value });
  // };
  // onYearSelect = async (year) => {
  //   console.log("YEAR INPUT", parseInt(year));
  //   let numberOFColors = [];
  //   let quantity = [];
  //   let quantity1 = [];
  //   let quantity2 = [];
  //   let quantity3 = [];
  //   let quantity4 = [];
  //   let quantity5 = [];
  //   var stateCopy = Object.assign({}, this.state);

  //   await this.props.getPrintedLabelsPerColorFromYear(year);

  //   this.props.printedLabelsPerColorFromYear.forEach((element) => {
  //     console.log("ELELEMENts", element.givenYear);
  //     switch (element.givenYear) {
  //       case parseInt(moment().subtract(5, "years").format("YYYY")):
  //         console.log(
  //           "FIRST CASE :",
  //           parseInt(moment().subtract(5, "years").format("YYYY"))
  //         );
  //         if (numberOFColors.length < 5) {
  //           numberOFColors.push(element.colors);
  //         }
  //         quantity.push(element.quantity);

  //         stateCopy.data.datasets = stateCopy.data.datasets.slice();
  //         stateCopy.data.datasets[0] = Object.assign(
  //           {},
  //           stateCopy.data.datasets[0]
  //         );
  //         stateCopy.data.datasets[0].data = quantity;
  //         stateCopy.data.datasets[0].hidden = false;
  //         stateCopy.data.datasets[0].label = moment()
  //           .subtract(5, "years")
  //           .format("YYYY");
  //         break;
  //       case parseInt(moment().subtract(4, "years").format("YYYY")):
  //         quantity1.push(element.quantity);
  //         if (numberOFColors.length < 5) {
  //           numberOFColors.push(element.colors);
  //         }
  //         stateCopy.data.datasets = stateCopy.data.datasets.slice();
  //         stateCopy.data.datasets[1] = Object.assign(
  //           {},
  //           stateCopy.data.datasets[1]
  //         );
  //         stateCopy.data.datasets[1].data = quantity1;
  //         stateCopy.data.datasets[1].hidden = false;

  //         stateCopy.data.datasets[1].label = moment()
  //           .subtract(4, "years")
  //           .format("YYYY");
  //         break;
  //       case parseInt(moment().subtract(3, "years").format("YYYY")):
  //         quantity2.push(element.quantity);
  //         if (numberOFColors.length < 5) {
  //           numberOFColors.push(element.colors);
  //         }
  //         stateCopy.data.datasets = stateCopy.data.datasets.slice();
  //         stateCopy.data.datasets[2] = Object.assign(
  //           {},
  //           stateCopy.data.datasets[2]
  //         );
  //         stateCopy.data.datasets[2].data = quantity2;
  //         stateCopy.data.datasets[2].hidden = false;

  //         stateCopy.data.datasets[2].label = moment()
  //           .subtract(3, "years")
  //           .format("YYYY");
  //         break;
  //       case parseInt(moment().subtract(2, "years").format("YYYY")):
  //         quantity3.push(element.quantity);
  //         if (numberOFColors.length < 5) {
  //           numberOFColors.push(element.colors);
  //         }
  //         stateCopy.data.datasets = stateCopy.data.datasets.slice();
  //         stateCopy.data.datasets[3] = Object.assign(
  //           {},
  //           stateCopy.data.datasets[3]
  //         );
  //         stateCopy.data.datasets[3].data = quantity3;
  //         stateCopy.data.datasets[3].hidden = false;

  //         stateCopy.data.datasets[3].label = moment()
  //           .subtract(2, "years")
  //           .format("YYYY");
  //         break;
  //       case parseInt(moment().subtract(1, "years").format("YYYY")):
  //         quantity4.push(element.quantity);
  //         if (numberOFColors.length < 5) {
  //           numberOFColors.push(element.colors);
  //         }
  //         stateCopy.data.datasets = stateCopy.data.datasets.slice();
  //         stateCopy.data.datasets[4] = Object.assign(
  //           {},
  //           stateCopy.data.datasets[4]
  //         );
  //         stateCopy.data.datasets[4].data = quantity4;
  //         stateCopy.data.datasets[4].hidden = false;

  //         stateCopy.data.datasets[4].label = moment()
  //           .subtract(1, "years")
  //           .format("YYYY");
  //         break;
  //       case parseInt(moment().format("YYYY")):
  //         quantity5.push(element.quantity);
  //         if (numberOFColors.length < 5) {
  //           numberOFColors.push(element.colors);
  //         }
  //         stateCopy.data.datasets = stateCopy.data.datasets.slice();
  //         stateCopy.data.datasets[5] = Object.assign(
  //           {},
  //           stateCopy.data.datasets[5]
  //         );
  //         stateCopy.data.datasets[5].data = quantity5;
  //         stateCopy.data.datasets[5].hidden = false;

  //         stateCopy.data.datasets[5].label = moment().format("YYYY");
  //         break;
  //       default:
  //         break;
  //     }
  //   });

  //   this.setState(this.stateCopy);
  //   this.setState((prevState) => ({
  //     data: {
  //       ...prevState.data,
  //       labels: numberOFColors,
  //     },
  //   }));
  // };

  render() {
    // const { optionsYearTest, value } = this.state;

    if (this.props.printedLabelsPerColorFromYear !== null) {
      return (
        <React.Fragment>
          <Line
            data={this.state.data}
            options={{
              title: {
                display: true,
                text: "Printed Labels per Color",
                fontSize: "30",
                fontFamily: "Nunito Sans, SemiBold",
                fontColor: "#585A5A",
              },
              legend: {
                display: true,
                position: "bottom",
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: function (value, index, values) {
                        return value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      },
                    },
                  },
                ],
              },
            }}
          />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  printedLabelsPerColorFromYear: state.analytics.printedLabelsPerColorFromYear,
  printedLabelsPerColorExistingYears:
    state.analytics.printedLabelsPerColorExistingYears,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  getPrintedLabelsPerColorFromYear,
  getPrintedLabelsPerColorExistingYears,
})(PrintedLabelsPerColorFromYearChart);
