import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers";
import { loadState, saveState } from '../localStorage';


// const initialState = {};
const persistedState = loadState();

const middleware = [thunk];

let store;

if (
  window.navigator.userAgent.includes("Chrome") &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
) {
  store = createStore(
    rootReducer,
    persistedState,
    // compose is used when multiple enhancers are present
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
} else {
  store = createStore(rootReducer, persistedState, compose(applyMiddleware(...middleware)));
}
export default store;
