import axios from "axios";
import { isDemo } from "./demo_utils";

//Local IPs
// export const backInstance = axios.create({
//   baseURL: "http://192.168.2.11:8600/api/v1"
// });
// Digital ocean 167.71.60.27
//Digital ocean droplets IPs

const instanceAddress = isDemo()
  ? "https://mtune-back.konnekt-able.com"
  : "http://167.71.60.27:8600";

export const backInstance = axios.create({
  baseURL: `${instanceAddress}/api/v1`
});

export const notificationInstance = axios.create({
  baseURL: `${instanceAddress}/api/v1`
});

export const overtimeInstance = axios.create({
  baseURL: `${instanceAddress}/api/v1`
});

backInstance.interceptors.request.use(function(config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

notificationInstance.interceptors.request.use(function(config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

overtimeInstance.interceptors.request.use(function(config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
