import React, { Component } from "react";
import CheckBox from "./Checkbox";
import { NotificationsHeaderMain } from "./NotificationsHeader";
import moment from "moment";

import { connect } from "react-redux";
import { getNotifications } from "../../../Redux/actions/Notifications/notificationsActions";
import { notificationInstance } from "../../../config";

import Spinner from "../../../components/Spinner/Spinner";

class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationArray: [],
      loading: false,
    };
  }

  // handleAllChecked = async (event) => {
  //   let items = this.state.notificationArray;
  //   let notificationsIds = [];
  //   items.forEach((item) => {
  //     if (!item.isChecked) {
  //       item.isChecked = event.target.checked;
  //       notificationsIds.push(item.id);
  //     }
  //   });
  //   try {
  //     notificationInstance.post("/notifications/status", {
  //       notificationsIds: notificationsIds,
  //     });
  //   } catch (error) {
  //     console.log("status change Error");
  //   }

  //   this.setState({ notificationArray: items });
  // };
  handleCheckChildElement = async (event) => {
    this.setState({ loading: true });
    let items = this.state.notificationArray;
    let listID = [];
    let notificationsIds;
    items.forEach((item) => {
      if (item.value === event.target.id) {
        item.isChecked = true;
        listID.push(item.id);
        notificationsIds = { notificationsIds: listID };
        try {
          notificationInstance.post("/notifications/status", notificationsIds);
        } catch (error) {
          console.log("status change Error");
        }
      }
    });
    await this.props.getNotifications();

    this.setState({ notificationArray: items, loading: false });
  };

  countNotifications = () => {
    let count = 0;
    let items = this.state.notificationArray;
    items.forEach((item) => {
      if (!item.isChecked) {
        count = count + 1;
      }
    });

    return count;
  };
  componentDidMount = async () => {
    this.countNotifications();
    try {
      this.setState({ loading: true });
      let updatedNotifications = [];
      for (let i = 0; i < this.props.notifications.length; i++) {
        updatedNotifications[i] = {
          id: this.props.notifications[i].notificationId,
          notification: this.props.notifications[i].text,
          time: this.props.notifications[i].createdTime,
          day: this.props.notifications[i].createdDate,
          isChecked: this.props.notifications[i].checked,
          value: this.props.notifications[i].notificationId,
          machine: this.props.notifications[i].printingMachineName,
        };
      }
      this.setState({
        notificationArray: updatedNotifications,
        loading: false,
      });
      if (this.countNotifications() === 0) {
        document.getElementById("notification-selection-choises").style.color =
          "#C8C8C8";
      }
    } catch (error) {
      console.log("notification API error");
    }
  };

  render() {
    let nowDate = moment().format("YYYY-MM-DD");
    let yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

    let notificationView = (
      <div>
        {/* <input
          id="notification-selectAll"
          type="checkbox"
          onChange={this.handleAllChecked}
          value="checkedall"
        />{" "}
        <span id="notification-selection-choises">Mark All as Read</span> */}

        {/* <button
          id="notification-selection-choises"
          onClick={this.handleAllChecked}
        >
          Mark All as Read
        </button> */}

        <h2 id="headers">Today</h2>
        <ul id="ul">
          {this.state.notificationArray.map((item, index) => {
            if (item.day === nowDate) {
              return (
                <CheckBox
                  key={index}
                  handleCheckChildElement={this.handleCheckChildElement}
                  {...item}
                />
              );
            } else {
              return null;
            }
          })}
        </ul>
        <h2 id="headers">Yesterday</h2>
        <ul id="ul">
          {this.state.notificationArray.map((item, index) => {
            if (item.day < nowDate && item.day === yesterday) {
              return (
                <CheckBox
                  key={index}
                  handleCheckChildElement={this.handleCheckChildElement}
                  {...item}
                />
              );
            } else {
              return null;
            }
          })}
        </ul>
        <h2 id="headers">Older</h2>
        <ul id="ul">
          {this.state.notificationArray.map((item, index) => {
            if (item.day < yesterday) {
              return (
                <CheckBox
                  key={index}
                  handleCheckChildElement={this.handleCheckChildElement}
                  {...item}
                />
              );
            } else {
              return null;
            }
          })}
        </ul>
      </div>
    );

    if (this.state.loading) {
      notificationView = <Spinner />;
    }

    return (
      <div>
        <NotificationsHeaderMain count={this.countNotifications()} />
        {notificationView}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notificationsArray,
  errors: state.errors,
});
export default connect(mapStateToProps, { getNotifications })(NotificationList);
