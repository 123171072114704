// Security actions
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_ERRORS = "GET_ERRORS";

export const USER_LOGOUT = "USER_LOGOUT";

// Scheduler actions
export const GET_COMMANDS = "GET_COMMANDS";

export const GET_CUTTING_COMMANDS = "GET_CUTTING_COMMANDS";

// Simulation actions
export const GET_SIMULATION_COMMANDS = "GET_SIMULATION_COMMANDS";
export const SET_FIXED_ORDERS_ARRAY = "GET_FIXED_ORDERS_ARRAY";

// Notification actions
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

//Overtime actions
export const GET_OVERTIMES = "GET_OVERTIMES";

// Set the current machine view of the scheduler
export const SET_MACHINE_VIEW = "SET_MACHINE_VIEW";

// Set the current cutting machine view of the scheduler
export const SET_CUTTING_MACHINE_VIEW = "SET_CUTTING_MACHINE_VIEW";

//Analytics
export const GET_MATERIAL_COSTS_PER_COLOR = "GET_MATERIAL_COSTS_PER_COLOR";
export const GET_COLORS_PER_ORDER = "GET_COLORS_PER_ORDER";
export const GET_ORDERS_PER_MONTH = "GET_ORDERS_PER_MONTH";
export const GET_ORDERS_PER_MONTH_EXISTING_YEARS = "GET_ORDERS_PER_MONTH_EXISTING_YEARS";
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_CONTACT_TYPES = "GET_CONTACT_TYPES";
export const GET_PRINTED_LABELS_PER_COLOR = "GET_PRINTED_LABELS_PER_COLOR";
export const GET_PRINTED_LABELS_PER_COLOR_FROM_YEAR = "GET_PRINTED_LABELS_PER_COLOR_FROM_YEAR";
export const GET_PRINTED_LABELS_PER_COLOR_EXISTING_YEARS =
  "GET_PRINTED_LABELS_PER_COLOR_EXISTING_YEARS";
export const GET_MATERIAL_WASTAGE_PER_YEAR = "MATERIAL_WASTAGE_PER_YEAR";
export const GET_MATERIAL_WASTAGE_PER_YEAR_EXISTING = "MATERIAL_WASTAGE_PER_YEAR_EXISTING";
export const GET_TURNOVER_PER_QUARTER_FROM_YEAR = "GET_TURNOVER_PER_QUARTER_FROM_YEAR";
export const GET_TURNOVER_PER_QUARTER_EXISTING_YEARS = "GET_TURNOVER_PER_QUARTER_EXISTING_YEARS";
export const GET_COMPOSITE_ANALYTICS = "GET_COMPOSITE_ANALYTICS";

//sidemenu
export const CLEAR_CSS = "CLEAR_CSS";
export const REMOVE_CLEAR_CSS = "REMOVE_CLEAR_CSS";

// Loading
export const LOADING_STARTED = "LOADING_STARTED";
export const LOADING_FINISHED = "LOADING_FINISHED";

//global
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
