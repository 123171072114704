import React, { Component } from "react";
import { Router } from "react-router-dom";
import Routes from "../src/Routes";
import { connect } from "react-redux";
import history from "./history";
import Sidemenu from "./components/Sidemenu";

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="container-fluid col-12">
          <div className="row app-row-wrapper">
            <div className="col-2 sidemenu-col">
              <Sidemenu />
            </div>
            <div className="col-10">
              <Routes />
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  security: state.security
});

export default connect(mapStateToProps, null)(App);
