import React, { Component } from "react";
import "react-big-scheduler/lib/css/style.css";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
  CellUnits,
  AddMorePopover,
} from "react-big-scheduler";
import withDragDropContext from "./withDnDContext";
import moment from "moment";
import SchedulerConfig from "./SchedulerConfig";

import { connect } from "react-redux";
import {
  getCuttingCommands,
  setCuttingMachineView,
} from "../../Redux/actions/Scheduler/schedulerActions";

import Spinner from "../../components/Spinner/Spinner";
import { showLoading, hideLoading } from "../../Redux/actions/loadingAction";
import CommandModal from "../../components/Modals/CommandModal";
import { cuttingEventPopOver } from "./EventPopOver";
import { Button, DropdownButton, Dropdown, Image } from "react-bootstrap";
import RefreshImage from "../../assets/refresh.svg";
import ClockImage from "../../assets/clock.svg";

let schedulerData;
let styleHasNotStarted = {
  background:
    "transparent linear-gradient(270deg, #59D7F8 0%, #0F7BB4 100%) 0% 0% no-repeat padding-box",
};

class CuttingTimetable extends Component {
  constructor(props) {
    super(props);
    schedulerData = new SchedulerData(
      moment().format(DATE_FORMAT),
      ViewTypes.Custom1,
      false,
      false,
      SchedulerConfig,
      {
        getCustomDateFunc: this.getCustomDate,
        getSummaryFunc: this.getSummary,
      }
    );
    schedulerData.localeMoment.locale("en");
    schedulerData.setResources([
      {
        id: this.props.cuttingMachineViewId,
        name: this.props.cuttingMachineViewName,
      },
    ]);

    this.state = {
      viewModel: schedulerData,
      events: [],
      loading: false,
      modalShow: false,
      modalCommand: null,
    };
    this.cuttingEventPopOver = cuttingEventPopOver.bind(this);
  }

  // Set the color scheme of each event
  eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    let borderColor = `${styleHasNotStarted.background}`;
    let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
    let divStyle = { background: borderColor };
    if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span
          style={{
            marginLeft: "4px",
            lineHeight: `${mustBeHeight}px`,
          }}
        >
          {titleText}
        </span>
      </div>
    );
  };

  setScheduleFromApi = async () => {
    try {
      this.setState({ loading: true });
      await this.props.getCuttingCommands();
      let updatedEvents = [];
      for (let i = 0; i < this.props.cuttingCommands.length; i++) {
        updatedEvents[i] = {
          id: this.props.cuttingCommands[i].id,
          start: this.props.cuttingCommands[i].startDate,
          end: this.props.cuttingCommands[i].endDate,
          resourceId: this.props.cuttingCommands[i].resourceId,
          title: this.props.cuttingCommands[i].id + " - " + this.props.cuttingCommands[i].title,
          commandDeadlineDate: this.props.cuttingCommands[i].commandDeadlineDate,
        };
      }
      return updatedEvents;
    } catch (error) {
      console.log("Get computed commands API error");
    }
  };

  setScheduleFromState = () => {
    let updatedEvents = [];
    for (let i = 0; i < this.props.cuttingCommands.length; i++) {
      updatedEvents[i] = {
        id: this.props.cuttingCommands[i].id,
        start: this.props.cuttingCommands[i].startDate,
        end: this.props.cuttingCommands[i].endDate,
        resourceId: this.props.cuttingCommands[i].resourceId,
        title: this.props.cuttingCommands[i].id + " - " + this.props.cuttingCommands[i].title,
        commandDeadlineDate: this.props.cuttingCommands[i].commandDeadlineDate,
      };
    }
    return updatedEvents;
  };

  getTimetable = async (refresh = false) => {
    let updatedEvents = [];
    if (this.props.cuttingCommands === null || refresh === true) {
      updatedEvents = await this.setScheduleFromApi();
    } else {
      updatedEvents = await this.setScheduleFromState();
    }
    this.setState({ events: updatedEvents });

    schedulerData = new SchedulerData(
      moment().format(DATE_FORMAT),
      ViewTypes.Custom1,
      false,
      false,
      SchedulerConfig,
      {
        getCustomDateFunc: this.getCustomDate,
        getSummaryFunc: this.getSummary,
      }
    );
    schedulerData.localeMoment.locale("en");
    schedulerData.setResources([
      {
        id: this.props.cuttingMachineViewId,
        name: this.props.cuttingMachineViewName,
      },
    ]);
    schedulerData.setEvents(this.state.events);
    this.setState({ viewModel: schedulerData, loading: false });
    this.props.hideLoading();
  };

  componentDidMount = async () => {
    this.getTimetable();
  };

  prevClick = (schedulerData) => {};

  nextClick = (schedulerData) => {};

  onViewChange = (schedulerData, view) => {};

  onSelectDate = (schedulerData, date) => {};

  eventClicked = (schedulerData, event) => {};

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {};

  updateEventStart = (schedulerData, event, newStart) => {};

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {};

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {};

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {};

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {};

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {};

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {};

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  getCustomDate = (schedulerData, num, date = undefined) => {
    const { viewType } = schedulerData;
    let selectDate = schedulerData.startDate;
    if (date !== undefined) selectDate = date;

    let startDate =
        num === 0
          ? selectDate
          : schedulerData
              .localeMoment(selectDate)
              .add(2 * num, "days")
              .format(DATE_FORMAT),
      endDate = schedulerData.localeMoment(startDate).add(1, "days").format(DATE_FORMAT),
      cellUnit = CellUnits.Hour;

    if (viewType === ViewTypes.Custom1) {
      let today = schedulerData.localeMoment(selectDate).format(DATE_FORMAT);
      startDate =
        num === 0
          ? today
          : schedulerData
              .localeMoment(today)
              .add(2 * num, "weeks")
              .format(DATE_FORMAT);
      endDate = schedulerData
        .localeMoment(startDate)
        .add(1, "weeks")
        .endOf("week")
        .format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    }
    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  getSummary = (schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) => {
    let text = headerEvents.length.toString();
    let color = "#d9d9d9";
    if (headerEvents.length > 0) {
      color = "#0F7BB4";
    }
    return { text: text, color: color, fontSize: "1.1vw" };
  };

  onMachineSelect = (name, e) => {
    setCuttingMachineView(name);
    schedulerData.setResources([
      {
        id: e.target.id,
        name: name,
      },
    ]);
  };

  refreshTimetable = () => {
    this.getTimetable(true);
  };

  nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
    let datetime = schedulerData.localeMoment(item.time);
    let machine = this.props.machineViewId;

    if (this.props.overtimes != null) {
      Object.entries(this.props.overtimes).forEach(([key, value]) => {
        switch (key) {
          case machine:
            if (value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (moment(datetime._i.split(" ")[0]).isSame(value[i].overtimeDate)) {
                  style.backgroundColor = "white";
                  style.color = "#585a5a";
                }
              }
            }

            break;

          default:
            break;
        }
      });
    }

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {formattedDateItems.map((formattedItem, index) => (
          <span
            key={index}
            dangerouslySetInnerHTML={{
              __html: formattedItem.replace(/[0-9]/g, "<b>$&</b>"),
            }}
          />
        ))}
        {style.color === "#585a5a" ? (
          <Image className="overtime-table-headers" src={ClockImage} fluid />
        ) : null}
      </th>
    );
  };

  render() {
    const { viewModel } = this.state;

    let popover = <div />;
    if (this.state.headerItem !== undefined) {
      popover = (
        <AddMorePopover
          headerItem={this.state.headerItem}
          eventItemClick={this.eventClicked}
          viewEventClick={this.ops1}
          viewEventText="Ops 1"
          viewEvent2Click={this.ops2}
          viewEvent2Text="Ops 2"
          schedulerData={viewModel}
          closeAction={this.onSetAddMoreState}
          left={this.state.left}
          top={this.state.top}
          height={this.state.height}
          moveEvent={this.moveEvent}
        />
      );
    }

    let machineSelector = (
      <DropdownButton
        id="dropdown-machine-button"
        title={this.props.cuttingMachineViewName}
        onSelect={(e, id) => this.onMachineSelect(e, id)}
      >
        <Dropdown.Item className="machine-selector-item" key="1" id="OMEGA" eventKey="OMEGA">
          Omega
        </Dropdown.Item>
        <Dropdown.Item className="machine-selector-item" key="2" id="DIGICON" eventKey="DIGICON">
          Digicon
        </Dropdown.Item>
        <Dropdown.Item className="machine-selector-item" key="3" id="ROTOFLEX" eventKey="ROTOFLEX">
          Rotoflex
        </Dropdown.Item>
        <Dropdown.Item className="machine-selector-item" key="4" id="BGM" eventKey="BGM">
          BGM
        </Dropdown.Item>
        <Dropdown.Item className="machine-selector-item" key="5" id="ASHE" eventKey="ASHE">
          Ashe
        </Dropdown.Item>
        <Dropdown.Item className="machine-selector-item" key="6" id="SERVAL" eventKey="SERVAL">
          Serval
        </Dropdown.Item>
      </DropdownButton>
    );

    let refreshHeader = (
      <span>
        <span id="sim-right-header-span">
          <Button
            id="refresh-scheduler-button-simulate"
            className="btn btn-primary"
            onClick={this.refreshTimetable}
          >
            <span className="refresh-text">Refresh Schedule</span>
            <Image src={RefreshImage} fluid />
          </Button>
        </span>
      </span>
    );

    let mainView = (
      <div>
        <Scheduler
          schedulerData={viewModel}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          viewEventClick={this.ops1}
          viewEventText="Ops 1"
          viewEvent2Text="Ops 2"
          viewEvent2Click={this.ops2}
          updateEventStart={this.updateEventStart}
          updateEventEnd={this.updateEventEnd}
          moveEvent={this.moveEvent}
          newEvent={this.newEvent}
          onScrollLeft={this.onScrollLeft}
          onScrollRight={this.onScrollRight}
          onScrollTop={this.onScrollTop}
          onScrollBottom={this.onScrollBottom}
          toggleExpandFunc={this.toggleExpandFunc}
          eventItemPopoverTemplateResolver={this.cuttingEventPopOver}
          eventItemTemplateResolver={this.eventItemTemplateResolver}
          leftCustomHeader={machineSelector}
          rightCustomHeader={refreshHeader}
          nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
        />
        {popover}
      </div>
    );

    if (this.state.loading) {
      mainView = (
        <div>
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemClick={this.eventClicked}
            viewEventClick={this.ops1}
            viewEventText="Ops 1"
            viewEvent2Text="Ops 2"
            viewEvent2Click={this.ops2}
            updateEventStart={this.updateEventStart}
            updateEventEnd={this.updateEventEnd}
            moveEvent={this.moveEvent}
            newEvent={this.newEvent}
            onScrollLeft={this.onScrollLeft}
            onScrollRight={this.onScrollRight}
            onScrollTop={this.onScrollTop}
            onScrollBottom={this.onScrollBottom}
            toggleExpandFunc={this.toggleExpandFunc}
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            leftCustomHeader={machineSelector}
            rightCustomHeader={refreshHeader}
            nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
          />
          <div id="spinner-div">
            <Spinner />
          </div>
        </div>
      );
    }

    return (
      <div className="row timetable-wrapper">
        <div id="timetable-container">
          {mainView}
          <CommandModal
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
            command={this.state.modalCommand}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cuttingCommands: state.scheduler.cuttingCommands,
  errors: state.errors,
  role: state.security.user.role[0].authority,
  cuttingMachineViewId: state.scheduler.cuttingMachineView.id,
  cuttingMachineViewName: state.scheduler.cuttingMachineView.name,
  loading: state.loading.show,
});

export default connect(mapStateToProps, {
  showLoading,
  hideLoading,
  getCuttingCommands,
  setCuttingMachineView,
})(withDragDropContext(CuttingTimetable));
