import React from "react";
import { machineEnumeratorToMachineName } from "./TransformMachineCreds";

export const CheckBox = (props) => {
  return (
    <li
      className={!props.isChecked ? "notification-list" : "notification-read"}
      id={props.value}
      onClick={(event) => props.handleCheckChildElement(event)}
    >
      {/* <input
        key={props.id}
        onChange={props.handleCheckChildElement}
        type="checkbox"
        checked={props.isChecked}
        value={props.value}
        id={
          !props.isChecked ? "notification-checked" : "notification-unchecked"
        }
      /> */}
      <span id={props.value} className="notification-list-values">
        {machineEnumeratorToMachineName(props.machine)}
      </span>{" "}
      <span id={props.value} className="notification-list-contents">
        {props.notification}
      </span>
      <span id={props.value} className="notification-list-time">
        {props.time}
      </span>
    </li>
  );
};

export default CheckBox;
