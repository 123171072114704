import { SET_CURRENT_USER, GET_ERRORS } from "../actions/types";

const initialState = {
  validToken: false,
  user: {
    role: [{ authority: null }],
  },
  errors: null,
};

const booleanActionPayload = (payload) => {
  if (payload) {
    return true;
  } else {
    return false;
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        validToken: booleanActionPayload(action.payload),
        user: action.payload,
        tokenExp: action.tokenExp,
      };
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
