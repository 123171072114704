import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import ColorsPerOrderChart from "./ColorsPerOrderChart";
import MaterialCostsChart from "./MaterialCostsChart";
import EmployeesChart from "./EmployeesChart";
import ContactTypesChart from "./ContactTypesChart";
import OrdersPerMonthChart from "./OrdersPerMonthChart";
import PrintedLabelsPerColorChart from "./PrintedLabelsPerOrderChart";
import PrintedLabelsPerColorFromYearChart from "./PrintedLabelsPerColorFromYearChart";
import MaterialWastageChart from "./MaterialWastageChart";
import TurnoverPerQuarterChart from "./TurnoverPerQuarterChart";
import CompositeAnalytics from "./CompositeAnalytics";

class NewAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      optionsOperational: [
        {
          name: "Select Chart",
          value: null,
        },
        {
          name: "Colors per Order",
          value: "Colors per Order",
        },
        {
          name: "Material Cost per Color",
          value: "Material Cost per Color",
        },
        { name: "Printed Labels per Color", value: "Printed Labels per Color" },
        {
          name: "Printed Labels per Color from Year",
          value: "Printed Labels per Color from Year",
        },
        {
          name: "Material Wastage",
          value: "Material Wastage",
        },
      ],
      optionsBusiness: [
        {
          name: "Select Chart",
          value: null,
        },
        {
          name: "Employees Chart",
          value: "Employees Chart",
        },
        {
          name: "Contact Types Chart",
          value: "Contact Types Chart",
        },
        {
          name: "Orders per Month Chart",
          value: "Orders per Month Chart",
        },
        {
          name: "Turnover per Quarter Chart",
          value: "Turnover per Quarter Chart",
        },
      ],
    };
  }

  handleSelect = (key) => {
    this.setState({ key: key });
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  render() {
    const { optionsBusiness, optionsOperational, value } = this.state;
    let onOperationalChartSelect = () => {
      switch (value) {
        case "Colors per Order":
          return <ColorsPerOrderChart />;
        case "Material Cost per Color":
          return <MaterialCostsChart />;
        case "Printed Labels per Color":
          return <PrintedLabelsPerColorChart />;
        case "Printed Labels per Color from Year":
          return <PrintedLabelsPerColorFromYearChart />;
        case "Material Wastage":
          return <MaterialWastageChart />;

        default:
          break;
      }
    };
    let onBusinessChartSelect = () => {
      switch (value) {
        case "Employees Chart":
          return <EmployeesChart />;
        case "Contact Types Chart":
          return <ContactTypesChart />;
        case "Orders per Month Chart":
          return <OrdersPerMonthChart />;
        case "Turnover per Quarter Chart":
          return <TurnoverPerQuarterChart />;

        default:
          break;
      }
    };
    return (
      <div className="container-fluid analytics-wrapper" id="analytics-container">
        <div className="row" id="analytics-row">
          <div className="col-12" id="analytics-content">
            <div id="analytics-header">Analytics</div>
            <div id="chart-col">
              {" "}
              <Tabs activeKey={this.state.key} onSelect={this.handleSelect} id="analytics-tabs">
                {this.props.role === "ROLE_ADMIN" || this.props.role === "ROLE_ADMIN_BUSINESS" ? (
                  <Tab eventKey={1} title="Business">
                    <select
                      onChange={this.handleChange}
                      value={value}
                      data-test="org-reg-select"
                      className="custom-select custom-select-sm"
                      id="analytics-select-main"
                    >
                      {optionsBusiness.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {onBusinessChartSelect()}
                  </Tab>
                ) : (
                  <div />
                )}

                {this.props.role === "ROLE_ADMIN" ||
                this.props.role === "ROLE_ADMIN_OPERATIONAL" ||
                this.props.role === "ROLE_PROCUREMENT" ? (
                  <Tab eventKey={2} title="Operational">
                    <select
                      onChange={this.handleChange}
                      value={value}
                      data-test="org-reg-select"
                      className="custom-select custom-select-sm"
                      id="analytics-select-main"
                    >
                      {optionsOperational.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {onOperationalChartSelect()}
                  </Tab>
                ) : (
                  <div />
                )}
                {this.props.role === "ROLE_ADMIN" || this.props.role === "ROLE_ADMIN_BUSINESS" ? (
                  <Tab eventKey={3} title="Interactive">
                    <CompositeAnalytics />
                  </Tab>
                ) : (
                  <div />
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.security.user.role[0].authority,
});

export default connect(mapStateToProps, null)(NewAnalytics);
