import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import moment from "moment";
import {
  getTurnoverPerQuarterExistingYears,
  getTurnoverPerQuarterFromYear,
} from "../../../Redux/actions/Analytics/analyticsActions";

class TurnoverPerQuarterChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      value: "2015",
      optionsYearTest: [],
      data: {
        labels: [],
        datasets: [
          {
            hidden: true,
            borderColor: "#0F7BB4",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: ["#0F7BB4", "#0F7BB4", "#0F7BB4", "#0F7BB4"],
            hoverBackgroundColor: ["#0F7BB4", "#0F7BB4", "#0F7BB4", "#0F7BB4"],
          },
          {
            hidden: true,
            borderColor: "#435791",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: ["#435791", "#435791", "#435791", "#435791"],
            hoverBackgroundColor: ["#435791", "#435791", "#435791", "#435791"],
          },
          {
            hidden: true,
            borderColor: "#EE8512",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: ["#EE8512", "#EE8512", "#EE8512", "#EE8512"],
            hoverBackgroundColor: ["#EE8512", "#EE8512", "#EE8512", "#EE8512"],
          },
          {
            hidden: true,
            borderColor: "#F14127",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: ["#F14127", "#F14127", "#F14127", "#F14127"],
            hoverBackgroundColor: ["#F14127", "#F14127", "#F14127", "#F14127"],
          },
          {
            hidden: true,
            borderColor: "#C442A6",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: ["#C442A6", "#C442A6", "#C442A6", "#C442A6"],
            hoverBackgroundColor: ["#C442A6", "#C442A6", "#C442A6", "#C442A6"],
          },
          {
            hidden: true,
            borderColor: "#04AA98",
            label: "No Data",
            fill: false,
            data: [],
            backgroundColor: ["#04AA98", "#04AA98", "#04AA98", "#04AA98"],
            hoverBackgroundColor: ["#04AA98", "#04AA98", "#04AA98", "#04AA98"],
          },
        ],
      },
    };
  }

  componentDidMount = async () => {
    let existingYearsArray = [];
    let quarters = [];
    let turnover = [];
    let turnover1 = [];
    let turnover2 = [];
    let turnover3 = [];
    let turnover4 = [];
    let turnover5 = [];
    var stateCopy = Object.assign({}, this.state);
    await this.props.getTurnoverPerQuarterExistingYears();
    if (this.props.turnoverPerQuarterExistingYears !== null) {
      this.props.turnoverPerQuarterExistingYears.forEach((element) => {
        existingYearsArray.push(element.year);
      });
    }
    this.setState({
      optionsYearTest: existingYearsArray,
    });
    if (this.props.turnoverPerQuarterFromYear !== null) {
      this.props.turnoverPerQuarterFromYear.forEach((element) => {
        switch (element.givenYear) {
          case parseInt(moment().subtract(5, "years").format("YYYY")):
            if (quarters.length < 4) {
              quarters.push(element.quarter);
            }

            turnover.push(Math.round(element.turnover));

            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[0] = Object.assign(
              {},
              stateCopy.data.datasets[0]
            );
            stateCopy.data.datasets[0].data = turnover;
            stateCopy.data.datasets[0].hidden = false;
            stateCopy.data.datasets[0].label = moment()
              .subtract(5, "years")
              .format("YYYY");
            break;
          case parseInt(moment().subtract(4, "years").format("YYYY")):
            turnover1.push(Math.round(element.turnover));
            if (quarters.length < 4) {
              quarters.push(element.quarter);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[1] = Object.assign(
              {},
              stateCopy.data.datasets[1]
            );
            stateCopy.data.datasets[1].data = turnover1;
            stateCopy.data.datasets[1].hidden = false;

            stateCopy.data.datasets[1].label = moment()
              .subtract(4, "years")
              .format("YYYY");
            break;
          case parseInt(moment().subtract(3, "years").format("YYYY")):
            turnover2.push(Math.round(element.turnover));
            if (quarters.length < 4) {
              quarters.push(element.quarter);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[2] = Object.assign(
              {},
              stateCopy.data.datasets[2]
            );
            stateCopy.data.datasets[2].data = turnover2;
            stateCopy.data.datasets[2].hidden = false;

            stateCopy.data.datasets[2].label = moment()
              .subtract(3, "years")
              .format("YYYY");
            break;
          case parseInt(moment().subtract(2, "years").format("YYYY")):
            turnover3.push(Math.round(element.turnover));
            if (quarters.length < 4) {
              quarters.push(element.quarter);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[3] = Object.assign(
              {},
              stateCopy.data.datasets[3]
            );
            stateCopy.data.datasets[3].data = turnover3;
            stateCopy.data.datasets[3].hidden = false;

            stateCopy.data.datasets[3].label = moment()
              .subtract(2, "years")
              .format("YYYY");
            break;
          case parseInt(moment().subtract(1, "years").format("YYYY")):
            turnover4.push(Math.round(element.turnover));
            if (quarters.length < 4) {
              quarters.push(element.quarter);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[4] = Object.assign(
              {},
              stateCopy.data.datasets[4]
            );
            stateCopy.data.datasets[4].data = turnover4;
            stateCopy.data.datasets[4].hidden = false;

            stateCopy.data.datasets[4].label = moment()
              .subtract(1, "years")
              .format("YYYY");
            break;
          case parseInt(moment().format("YYYY")):
            turnover5.push(Math.round(element.turnover));
            if (quarters.length < 4) {
              quarters.push(element.quarter);
            }
            stateCopy.data.datasets = stateCopy.data.datasets.slice();
            stateCopy.data.datasets[5] = Object.assign(
              {},
              stateCopy.data.datasets[5]
            );
            stateCopy.data.datasets[5].data = turnover5;
            stateCopy.data.datasets[5].hidden = false;

            stateCopy.data.datasets[5].label = moment().format("YYYY");
            break;
          default:
            break;
        }
      });
    }

    this.setState(this.stateCopy);
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        labels: quarters,
      },
    }));
  };

  render() {
    const { loading } = this.state;

    if (this.props.turnoverPerQuarterFromYear !== null && loading === false) {
      return (
        <React.Fragment>
          <Line
            data={this.state.data}
            options={{
              title: {
                display: true,
                text: "Turnover Per Quarter",
                fontSize: "30",
                fontFamily: "Nunito Sans, SemiBold",
                fontColor: "#585A5A",
              },
              legend: {
                display: true,
                position: "bottom",
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: function (value, index, values) {
                        return value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      },
                    },
                  },
                ],
              },
            }}
          />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  turnoverPerQuarterFromYear: state.analytics.turnoverPerQuarterFromYear,
  turnoverPerQuarterExistingYears:
    state.analytics.turnoverPerQuarterExistingYears,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  getTurnoverPerQuarterExistingYears,
  getTurnoverPerQuarterFromYear,
})(TurnoverPerQuarterChart);
