import {
  GET_COLORS_PER_ORDER,
  GET_CONTACT_TYPES,
  GET_EMPLOYEES,
  GET_MATERIAL_COSTS_PER_COLOR,
  GET_ORDERS_PER_MONTH,
  GET_PRINTED_LABELS_PER_COLOR,
  GET_PRINTED_LABELS_PER_COLOR_FROM_YEAR,
  GET_ORDERS_PER_MONTH_EXISTING_YEARS,
  GET_MATERIAL_WASTAGE_PER_YEAR,
  GET_MATERIAL_WASTAGE_PER_YEAR_EXISTING,
  GET_PRINTED_LABELS_PER_COLOR_EXISTING_YEARS,
  GET_TURNOVER_PER_QUARTER_EXISTING_YEARS,
  GET_TURNOVER_PER_QUARTER_FROM_YEAR,
  GET_COMPOSITE_ANALYTICS,
} from "../../actions/types";

const initialState = {
  colorsPerOrder: null,
  contactTypes: null,
  employees: null,
  materialCostsPerColor: null,
  ordersPerMonth: null,
  ordersPerMonthExistingYears: null,
  printedLabelsPerColor: null,
  printedLabelsPerColorFromYear: null,
  printedLabelsPerColorExistingYears: null,
  materialWastagePerYear: null,
  materialWastagePerYearExisting: null,
  turnoverPerQuarterExistingYears: null,
  turnoverPerQuarterFromYear: null,
  compositeAnalytics: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COLORS_PER_ORDER:
      return {
        ...state,
        colorsPerOrder: action.payload,
      };
    case GET_CONTACT_TYPES:
      return {
        ...state,
        contactTypes: action.payload,
      };
    case GET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    case GET_MATERIAL_COSTS_PER_COLOR:
      return {
        ...state,
        materialCostsPerColor: action.payload,
      };
    case GET_ORDERS_PER_MONTH:
      return {
        ...state,
        ordersPerMonth: action.payload,
      };
    case GET_PRINTED_LABELS_PER_COLOR:
      return {
        ...state,
        printedLabelsPerColor: action.payload,
      };
    case GET_PRINTED_LABELS_PER_COLOR_FROM_YEAR:
      return {
        ...state,
        printedLabelsPerColorFromYear: action.payload,
      };
    case GET_PRINTED_LABELS_PER_COLOR_EXISTING_YEARS:
      return {
        ...state,
        printedLabelsPerColorExistingYears: action.payload,
      };
    case GET_ORDERS_PER_MONTH_EXISTING_YEARS:
      return {
        ...state,
        ordersPerMonthExistingYears: action.payload,
      };
    case GET_MATERIAL_WASTAGE_PER_YEAR:
      return {
        ...state,
        materialWastagePerYear: action.payload,
      };
    case GET_MATERIAL_WASTAGE_PER_YEAR_EXISTING:
      return {
        ...state,
        materialWastagePerYearExisting: action.payload,
      };
    case GET_TURNOVER_PER_QUARTER_FROM_YEAR:
      return {
        ...state,
        turnoverPerQuarterFromYear: action.payload,
      };
    case GET_TURNOVER_PER_QUARTER_EXISTING_YEARS:
      return {
        ...state,
        turnoverPerQuarterExistingYears: action.payload,
      };
    case GET_COMPOSITE_ANALYTICS:
      return {
        ...state,
        compositeAnalytics: action.payload,
      };
    default:
      return state;
  }
}
