import React, { Component } from "react";
import Timetable from "../Scheduler/Timetable";
import CuttingTimetable from "../Scheduler/CuttingTimetable";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";

import NotificationWidget from "../Scheduler/Notifications/NotificationWidget";
import AnalyticsWidget from "../Scheduler/Analytics/AnalyticsWidget";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: "Printing",
    };
  }

  handleSelect = (key) => {
    this.setState({ key: key });
  };

  render() {
    return (
      <div className="container-fluid" id="dashboard-container">
        <div className="row" id="dashboard-row">
          <div className="col-12" id="dashboard-content">
            <div id="dashboard-title">Dashboard</div>
            <Tabs activeKey={this.state.key} onSelect={this.handleSelect} id="dashboard-tabs">
              <Tab eventKey={"Printing"} title="Printing">
                <Timetable />
              </Tab>
              <Tab eventKey={"Cutting"} title="Cutting">
                <CuttingTimetable />
              </Tab>
            </Tabs>
            {this.props.role === "ROLE_ADMIN" || this.props.role === "ROLE_QUALITY_CONTROL" ? (
              <div className="row dashboard-wrapper">
                <div className="notification-widget-col">
                  <Link to="/Notifications" id="dashboard-notifications">
                    <NotificationWidget />
                  </Link>
                </div>
                <div className="analytics-wrapper" id="dashboard-analytics">
                  <AnalyticsWidget />
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.security.user.role[0].authority,
});

export default connect(mapStateToProps, null)(Dashboard);
