import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class ErrorModal extends Component {
    message = "";
    status = "";
    render() {
        let errorStatusSwitcher = () => {
            switch (this.props.status) {
                case "BAD_REQUEST":
                    return "Bad Request";
                case "401 UNAUTHORIZED":
                    this.status = "Login Failed";
                    this.message = "Username or Password incorrect. Please try again.";
                    break;
                case "403 FORBIDDEN":
                    return "No Access Allowed";
                case "404 NOT_FOUND":
                    return "The resource does not exist";
                case "408 REQUEST_TIMEOUT":
                    return "Connection Timeout";
                default:
                    return "";
            }
        };

        return (
            <div>
                {this.props.show === true ? (
                    <Modal id="errorModal" show={this.props.show} onHide={this.props.handleClose}>
                        <Modal.Header closeButton id="errorModalHeader">
                            <Modal.Title id="errorModalTitle">
                                {errorStatusSwitcher()}
                                {this.status}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body id="errorModalBody">{this.message}</Modal.Body>
                        <Modal.Footer id="errorModalFooter">
                            <Button className="btn-danger" onClick={this.props.handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                ) : (
                        <div></div>
                    )}
            </div>
        );
    }
}

export default ErrorModal;
