import { backInstance } from "../../../config";
import {
  GET_COMMANDS,
  GET_CUTTING_COMMANDS,
  GET_ERRORS,
  SET_MACHINE_VIEW,
  SET_CUTTING_MACHINE_VIEW,
  GET_SIMULATION_COMMANDS,
  SET_FIXED_ORDERS_ARRAY,
  LOADING_STARTED,
  LOADING_FINISHED,
} from "../types";
import store from "../../store";

export const getCommands = () => async (dispatch) => {
  try {
    const res = await backInstance.get("/schedule/computed");
    console.log("Computed Api: ", res.data.data);
    dispatch({
      type: GET_COMMANDS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const setMachineView = (machineName) => {
  store.dispatch({
    type: SET_MACHINE_VIEW,
    payload: machineName,
  });
};

export const getCuttingCommands = () => async (dispatch) => {
  try {
    const res = await backInstance.get("/schedule/cutting-machine");
    console.log("Cutting response: ", res.data.data);
    dispatch({
      type: GET_CUTTING_COMMANDS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const setCuttingMachineView = (machineName) => {
  store.dispatch({
    type: SET_CUTTING_MACHINE_VIEW,
    payload: machineName,
  });
};

export const setFixedOrdersArray = (fixedOrderArray) => async (dispatch) => {
  dispatch({
    type: SET_FIXED_ORDERS_ARRAY,
    payload: fixedOrderArray,
  });
};

export const getSimulationCommands = (fixedOrderArray) => async (dispatch) => {
  let fixedOrders = { fixedPrintCommands: fixedOrderArray };
  try {
    const res = await backInstance.post(
      "/schedule/computed/fixed",
      fixedOrders
    );
    dispatch({
      type: GET_SIMULATION_COMMANDS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const setLoading = (loading) => async (dispatch) => {
  switch (loading) {
    case true:
      dispatch({
        type: LOADING_STARTED,
      });
      break;
    case false:
      dispatch({
        type: LOADING_FINISHED,
      });
      break;
    default:
      break;
  }
};
