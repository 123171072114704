import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { backInstance } from "../../config";

const hasShortageToggle = async (props) => {
  if (props.role !== "ROLE_CUSTOMER_SERVICE" && props.role !== "ROLE_PRE_PRESS") {
    let shortagePayload = {
      commandId: props.command.id,
      hasShortage: !props.command.hasShortage,
    };
    await backInstance.post("/schedule/has-shortage", shortagePayload);
    props.onHide(true);
  }
};

const CommandModal = (props) => {
  const [requirementsColapse, setRequirementsColapse] = useState(false);
  const [printColapse, setPrintColapse] = useState(false);
  const [labelColapse, setLabelColapse] = useState(false);
  const [materialColapse, setMaterialColapse] = useState(false);
  const [packagingColapse, setPackagingColapse] = useState(false);

  useEffect(() => {
    setRequirementsColapse(false);
    setPrintColapse(false);
    setLabelColapse(false);
    setMaterialColapse(false);
    setPackagingColapse(false);
  }, [props.show]);

  return (
    <div>
      {props.show ? (
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          id="event-modal"
          centered
        >
          <div className="container" id="event-modal-container">
            <div className="row" id="event-modal-header">
              <div className="col">Command Details</div>{" "}
              <div className="col">Command ID: {props.command.id}</div>
            </div>
            <div className="row">
              <div className="col command-modal-section-headers" style={{ fontSize: "15px" }}>
                <b> General Details</b>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div>
                  <b>Task Date:</b>{" "}
                  {moment(props.command.printProperties.commandEntryDate).format("DD-MM-YYYY")}{" "}
                </div>
                <div>
                  <b>Client:</b> {props.command.title}
                </div>
                <div>
                  <b>Material:</b> {props.command.printProperties.materialCode}
                </div>
                <div>
                  <b>Task Category:</b>{" "}
                </div>
                <div>
                  <b>Cutting Machine:</b>{" "}
                </div>
                <div>
                  <b>Description:</b> {props.command.printProperties.description}
                </div>
              </div>
              <div className="col">
                <div>
                  <b>Deadline Date:</b>{" "}
                  {moment(props.command.commandDeadlineDate).format("DD-MM-YYYY")}
                </div>
                <div>
                  <b>Task ID Number:</b> {props.command.id}
                </div>
                <div>
                  <b>Client ID Number:</b>
                  {props.command.printProperties.customerOrder}{" "}
                </div>
                <div>
                  <b>Varnish:</b>{" "}
                </div>
                <div>
                  <b>Manager:</b> {props.command.managerId}
                </div>
              </div>
              <div className="col">
                <div>
                  <b>Status:</b>{" "}
                </div>
                <div>
                  <b>Reprint:</b> {props.command.printProperties.isReprint ? "Yes" : "No"}
                </div>
                <div>
                  <b>Colors:</b>
                  {props.command.printProperties.numberOfColors}
                </div>
                <div>
                  <b>Quantity:</b> {props.command.printProperties.quantity}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3 command-modal-section-headers">
                <div>
                  <b>Current Required Meters:</b>
                  {props.command.printProperties.totalRequiredMeters}{" "}
                </div>
              </div>
              <div className="col-3">
                <div>
                  <b>Wastage:</b> {props.command.printProperties.wastage}
                </div>
              </div>
              <div className="col-3">
                <div>
                  <b>Total Meters:</b>
                </div>
              </div>
              <div className="col-3">
                <div>
                  <b>Total Production time:</b> {props.command.totalTime}
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col command-modal-section-headers" style={{ fontSize: "15px" }}>
                <b> Requirements </b>
                <FontAwesomeIcon
                  icon={faCaretDown}
                  size="sm"
                  className={"command-modal-arrow-" + requirementsColapse}
                  onClick={() => setRequirementsColapse(!requirementsColapse)}
                />
              </div>
            </div>
            <hr />
            <Collapse in={requirementsColapse}>
              <div className="row">
                <div className="col">
                  <div>
                    <b>Step:</b>
                    {props.command.printProperties.step}
                  </div>
                  <div>
                    <b>Height:</b> {props.command.printProperties.height}
                  </div>
                  <div>
                    <b>Width:</b>
                    {props.command.printProperties.width}{" "}
                  </div>
                  <div>
                    <b>Label/Step:</b> {props.command.printProperties.labelPerStep}
                  </div>
                  <div>
                    <b>Label/Height:</b>
                    {props.command.printProperties.lablePerHeight}{" "}
                  </div>
                </div>
                <div className="col">
                  <div>
                    <b>Combination:</b>
                    {props.command.printProperties.combination}{" "}
                  </div>
                  <div>
                    <b>Offset:</b> {props.command.printProperties.offset}
                  </div>
                  <div></div>
                  <div>
                    <b>Machine Speed:</b> {props.command.machineSpeed}
                  </div>
                  <div>
                    <b>Clise Code:</b> {props.command.printProperties.cliseCode}
                  </div>
                </div>
                <div className="col">
                  <div>
                    <b>Back Print:</b> {props.command.printProperties.hasBackPrint ? "Yes" : "No"}
                  </div>
                  <div>
                    <b>Gold Print:</b>{" "}
                  </div>
                  <div>
                    <b>Lamination:</b>
                    {props.command.printProperties.isLaminated ? "Yes" : "No"}{" "}
                  </div>
                  <div>
                    <b>Embossing:</b>{" "}
                  </div>
                </div>
                <div className="col">
                  <div>
                    <b>Clise:</b>
                    {props.command.printProperties.clise}{" "}
                  </div>
                  <div>
                    <b>Y:</b>{" "}
                  </div>
                  <div>
                    <b>S:</b>{" "}
                  </div>
                  <div>
                    <b>L:</b>{" "}
                  </div>
                </div>
                <div className="col">
                  <div>
                    <b>Numbering:</b> {props.command.printProperties.hasNumbering ? "Yes" : "No"}
                  </div>
                  <div>
                    <b>Machine:</b> {props.command.resourceId}
                  </div>
                  <div>
                    <b>Cylin. Teeth:</b>
                    {props.command.printProperties.cylinderTeeth}{" "}
                  </div>
                  <div>
                    <b>Flat Teeth:</b>
                    {props.command.printProperties.flatTeeth}{" "}
                  </div>
                  <div>
                    <b>Client Order:</b>{" "}
                  </div>
                </div>
                <div className="col">
                  <div>
                    <b>Rot Teeth:</b>
                    {props.command.printProperties.rotTeeth}{" "}
                  </div>
                  <div>
                    <b>Foil Width:</b>
                    {props.command.printProperties.foilWidth}{" "}
                  </div>
                  <div>
                    <b>Foil Meters:</b> {props.command.printProperties.foilMeters}
                  </div>
                  <div>
                    <b>Label/Meters:</b>{" "}
                  </div>
                </div>
              </div>
            </Collapse>
            <br />
            <div className="row">
              <div className="col command-modal-section-headers" style={{ fontSize: "15px" }}>
                <b> Print & Edit Notes</b>{" "}
                <FontAwesomeIcon
                  icon={faCaretDown}
                  size="sm"
                  className={"command-modal-arrow-" + printColapse}
                  onClick={() => setPrintColapse(!printColapse)}
                />
              </div>
            </div>
            <hr />
            <Collapse in={printColapse}>
              <div>
                <div className="row">
                  <div className="col">
                    {" "}
                    <b> Notes</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div>
                      <b>Color 1:</b>
                      {props.command.printProperties.color1}
                    </div>
                    <div>
                      <b>Color 2:</b> {props.command.printProperties.color2}
                    </div>
                    <div>
                      <b>Color 3:</b> {props.command.printProperties.color3}
                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      <b>Color 4:</b> {props.command.printProperties.color4}
                    </div>
                    <div>
                      <b>Color 5:</b> {props.command.printProperties.color5}
                    </div>
                    <div>
                      <b>Color 6:</b> {props.command.printProperties.color6}
                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      <b>Color 7:</b>
                      {props.command.printProperties.color7}{" "}
                    </div>
                    <div>
                      <b>Color 8:</b> {props.command.printProperties.color8}
                    </div>
                    <div>
                      <b>Color 9:</b> {props.command.printProperties.color9}
                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      <b>Prepress Check:</b>{" "}
                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      <b>Atelier:</b>
                      {props.command.printProperties.hasAtelier ? "Yes" : "No"}{" "}
                    </div>
                    <div>
                      <b>Clise:</b>
                      {props.command.printProperties.hasClise ? "Yes" : "No"}{" "}
                    </div>
                    <div>
                      <b>Production:</b>
                      {props.command.printProperties.isInProduction ? "Yes" : "No"}{" "}
                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      <b>Extra L:</b> {props.command.printProperties.extraL}
                    </div>
                    <div>
                      <b>Extra S:</b> {props.command.printProperties.extraS}
                    </div>
                    <div>
                      <b>Notes:</b> {props.command.printProperties.notes}
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
            <br />
            <div className="row">
              <div className="col command-modal-section-headers" style={{ fontSize: "15px" }}>
                <b> Label Details</b>{" "}
                <FontAwesomeIcon
                  icon={faCaretDown}
                  size="sm"
                  className={"command-modal-arrow-" + labelColapse}
                  onClick={() => setLabelColapse(!labelColapse)}
                />
              </div>
            </div>
            <hr />
            <Collapse in={labelColapse}>
              <div className="row">
                <div className="col">Notes</div>
              </div>
            </Collapse>
            <br />
            <div className="row">
              <div className="col command-modal-section-headers" style={{ fontSize: "15px" }}>
                <b> Materials</b>{" "}
                <FontAwesomeIcon
                  icon={faCaretDown}
                  size="sm"
                  className={"command-modal-arrow-" + materialColapse}
                  onClick={() => setMaterialColapse(!materialColapse)}
                />
              </div>
            </div>
            <hr />
            <Collapse in={materialColapse}>
              <div className="row">
                <div className="col">
                  <div>
                    <b>Date:</b>{" "}
                  </div>
                </div>
                <div className="col">
                  <div>
                    <b>Material:</b>{" "}
                  </div>
                </div>
                <div className="col">
                  <div>
                    <b>Quantity:</b>{" "}
                  </div>
                </div>
                <div className="col">
                  <div>
                    <b>Notes:</b>{" "}
                  </div>
                </div>
              </div>
            </Collapse>
            <br />
            <div className="row">
              <div className="col command-modal-section-headers" style={{ fontSize: "15px" }}>
                <b> Packaging</b>{" "}
                <FontAwesomeIcon
                  icon={faCaretDown}
                  size="sm"
                  className={"command-modal-arrow-" + packagingColapse}
                  onClick={() => setPackagingColapse(!packagingColapse)}
                />
              </div>
            </div>
            <hr />
            <Collapse in={packagingColapse}>
              <div>
                <div className="row">
                  <div className="col">
                    <div>
                      <b>Inside Diameter:</b> {props.command.printProperties.insideDiameter}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <b>Outside Diameter:</b>
                      {props.command.printProperties.outsideDiameter}{" "}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <b>Label/Roll:</b> {props.command.printProperties.labelsPerPack}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <b>Wrapping Trend:</b> {props.command.printProperties.wrappingTrend}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div>
                      <b>Rip Date:</b>{" "}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <b>Manager:</b>{" "}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <b>Pack:</b>{" "}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <b>Roll per Pack:</b>{" "}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <b>Label/Roll:</b>
                      {props.command.printProperties.labelsPerPack}{" "}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <b>Total:</b>{" "}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <b>Delivery Site:</b>
                      {props.command.printProperties.deliverySite}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
            <br />
            <div className="row">
              <div className="col">
                <Button id="command-modal-cancel-button" onClick={() => props.onHide(false)}>
                  Cancel
                </Button>
              </div>
              <div className="col text-right">
                {props.command.hasShortage ? (
                  <Button
                    id="command-modal-shortage-button-true"
                    onClick={() => hasShortageToggle(props)}
                  >
                    Warehouse Shortage
                  </Button>
                ) : (
                  <Button
                    id="command-modal-shortage-button-false"
                    onClick={() => hasShortageToggle(props)}
                  >
                    Warehouse Shortage
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default CommandModal;
